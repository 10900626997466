import { Link } from "react-router-dom"
import { DASHBOARD_LOAN_DETAILS } from "routes"
import CommaNumber from "comma-number"
// import { StatusDiv } from "components/Dashboard/Home/RecentApplications/style"
// import { ByshelbP } from "globalStyles"

export const APPCOLUMNS = [
	{
		Header: "First Name",
		accessor: (originalRow, rowIndex) => originalRow?.applicant?.first_name + " " +  originalRow?.applicant?.surname
	},
	{
		Header: "LGA",
		accessor: (originalRow, rowIndex) => originalRow?.applicant?.lga
	},
	{
		Header: "Institution",
		accessor: (originalRow, rowIndex) => <div style={{ maxWidth: "250px" }}>{originalRow?.institution?.name}</div>
	},
	{
		Header: "Amount",
		accessor: (originalRow, rowIndex) => `₦${CommaNumber(originalRow?.amount)}`
	},
	{
		Header: "Loan Type",
		accessor: "loan_type"
	},
	{
		Header: "Reffered By",
		accessor: (originalRow, rowIndex) => originalRow?.refferedBy
	},
	// {
	// 	Header: "Status",
	// 	accessor: (originalRow, rowIndex) => (
	// 		<StatusDiv
	// 			bgColor={
	// 				originalRow?.status === "approved"
	// 					? "#5caa77"
	// 					: originalRow?.status === "pending"
	// 					? "#ffae00"
	// 					: originalRow?.status === "rejected"
	// 					? "#e23644"
	// 					: originalRow?.status === "disbursed"
	// 					? "#91f2b1"
	// 					: ""
	// 			}
	// 		>
	// 			<ByshelbP fs="13px" ml="10px" mt="22px">
	// 				{originalRow?.status === "approved"
	// 					? "Approved"
	// 					: originalRow?.status === "pending"
	// 					? "Pending"
	// 					: originalRow?.status === "rejected"
	// 					? "Rejected"
	// 					: originalRow?.status === "disbursed"
	// 					? "Disbursed"
	// 					: "Expired"}
	// 			</ByshelbP>
	// 		</StatusDiv>
	// 	)
	// },
	{
		Header: "View Details",
		accessor: (originalRow, rowIndex) => originalRow?.status && <Link to={`${DASHBOARD_LOAN_DETAILS}/${originalRow.id}`}>View</Link>
	}
]
export const ACCEPTED_COLUMNS = [
	{
		title: "First Name",
		render: (originalRow) => <p>{originalRow?.applicant?.first_name + " " +  originalRow?.applicant?.surname}</p>
	},
	{
		title: "LGA",
		render: (originalRow) => <p>{originalRow?.applicant?.lga}</p>
	},
	{
		title: "Institution",
		render: (originalRow) => <div style={{ maxWidth: "250px" }}>{originalRow?.institution?.name}</div>
	},
	{
		title: "Amount",
		render: (originalRow) => <p>₦{CommaNumber(originalRow?.amount)}</p>
	},
	{
		title: "Loan Type",
		dataIndex: "loan_type"
	},
	{
		title: "Reffered By",
		render: (originalRow) => <p>{originalRow?.refferedBy}</p>
	},
	// {
	// 	Header: "Status",
	// 	accessor: (originalRow, rowIndex) => (
	// 		<StatusDiv
	// 			bgColor={
	// 				originalRow?.status === "approved"
	// 					? "#5caa77"
	// 					: originalRow?.status === "pending"
	// 					? "#ffae00"
	// 					: originalRow?.status === "rejected"
	// 					? "#e23644"
	// 					: originalRow?.status === "disbursed"
	// 					? "#91f2b1"
	// 					: ""
	// 			}
	// 		>
	// 			<ByshelbP fs="13px" ml="10px" mt="22px">
	// 				{originalRow?.status === "approved"
	// 					? "Approved"
	// 					: originalRow?.status === "pending"
	// 					? "Pending"
	// 					: originalRow?.status === "rejected"
	// 					? "Rejected"
	// 					: originalRow?.status === "disbursed"
	// 					? "Disbursed"
	// 					: "Expired"}
	// 			</ByshelbP>
	// 		</StatusDiv>
	// 	)
	// },
]
export const DRAFTS_COLUMNS = [
	{
		title: "Applicant",
		render: (originalRow) => <p>{originalRow?.applicant?.first_name + " " +  originalRow?.applicant?.surname}</p>
	},
	{
		title: "Phone Number",
		render: (originalRow) => <p>{originalRow?.applicant?.phone}</p>
	},
	{
		title: "Email",
		render: (originalRow) => <p>{originalRow?.applicant?.email}</p>
	},
	{
		title: "Institution",
		render: (originalRow) => originalRow?.institution?.name
		?<div style={{ maxWidth: "250px" }}>{originalRow?.institution?.name}</div>
		:<div style={{ maxWidth: "250px" }}>N/A</div>
	},
	{
		title: "Amount",
		render: (originalRow) => <p>₦{CommaNumber(originalRow?.amount)}</p>
	},
	{
		title: "Loan Progress",
		render: (originalRow) => <p className="capitalize">{originalRow?.loan_step}</p>
	},
	{
		title: "Loan Type",
		dataIndex: "loan_type"
	},
]
export const ESCOLUMNS = [
	{
		Header: "First Name",
		accessor: (originalRow, rowIndex) => originalRow?.applicant?.first_name + " " +  originalRow?.applicant?.surname
	},
	{
		Header: "LGA",
		accessor: (originalRow, rowIndex) => originalRow?.applicant?.lga
	},
	{
		Header: "Institution",
		accessor: (originalRow, rowIndex) => <div style={{ maxWidth: "250px" }}>{originalRow?.institution?.name}</div>
	},
	{
		Header: "Amount",
		accessor: (originalRow, rowIndex) => `₦${CommaNumber(originalRow?.amount)}`
	},
	{
		Header: "Loan Type",
		accessor: "loan_type"
	},
	{
		Header: "Reffered By",
		accessor: (originalRow) => originalRow?.refferedBy
	},
	// {
	// 	Header: "Status",
	// 	accessor: (originalRow, rowIndex) => (
	// 		<StatusDiv
	// 			bgColor={
	// 				originalRow?.status === "approved"
	// 					? "#5caa77"
	// 					: originalRow?.status === "pending"
	// 					? "#ffae00"
	// 					: originalRow?.status === "rejected"
	// 					? "#e23644"
	// 					: originalRow?.status === "disbursed"
	// 					? "#91f2b1"
	// 					: ""
	// 			}
	// 		>
	// 			<ByshelbP fs="13px" ml="10px" mt="22px">
	// 				{originalRow?.status === "approved"
	// 					? "Approved"
	// 					: originalRow?.status === "pending"
	// 					? "Pending"
	// 					: originalRow?.status === "rejected"
	// 					? "Rejected"
	// 					: originalRow?.status === "disbursed"
	// 					? "Disbursed"
	// 					: "Expired"}
	// 			</ByshelbP>
	// 		</StatusDiv>
	// 	)
	// },
]


