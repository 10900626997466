import { Colors } from "chart.js"
import styled from "styled-components"
import media from "utils/media"

export const TextArea = styled.textarea`
	width: calc(100% - 30px);
	height: 300px;
	border: 2px solid #d9d9d9;
	border-radius: 15px;
	padding: 0px 15px;
	margin-top: 0.7rem;
	outline: none;
	:active {
		background-color: ${Colors.white};
	}
	:valid {
		background-color: ${Colors.white};
	}
	::placeholder {
		color: ${Colors.muted};
	}
	resize: none;

	${media.mobile`
  height: 200px;
  `}
`

export const TextInputContainer = styled.div`
	width: ${({ width }) => (width ? width : "100%")};
	display: flex;
	flex-direction: column;
	margin-top: ${({ mt }) => (mt ? mt : "1rem")};
	label {
		color: ${({ labelColor }) => (labelColor ? labelColor : `${Colors.black}`)};
	}
`
