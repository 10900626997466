import styled from "styled-components"
import media from "utils/media"

export const DashboardHomeContainer = styled.div`
	width: 100%;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0px 60px;

	${media.mobile`
  padding: 0px 20px;
  
  `}
`

export const DashboardTextDiv = styled.div`
	width: 70%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	${media.mobile`
  width: 100%;
  margin-top:3rem;

  `}
`

export const HomeHeaderText = styled.h4`
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 40px;
	letter-spacing: -0.02em;
	color: #1e1e1e;
	span {
		color: #d655c9;
	}

	${media.mobile`
  font-size: 22px;
  line-height: 30px;

  `}
`
export const HomeParaText = styled.p`
	margin-top: 15px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 25px;
	text-align: center;
	color: #000000;
	${media.mobile`
  font-size: 14px;
  line-height: 18px;

  `}
`

export const DashboardBtnDiv = styled.div`
	width: 95%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 4rem;

	${media.mobile`
  flex-direction: column;
width: 100%;
  `}
`

export const DashboardBtn = styled.button`
	width: 32%;
	height: 160px;
	display: flex;
	padding: 2rem 2rem;
	flex-direction: column;
	justify-content: flex-end;
	background: linear-gradient(73.6deg, rgba(57, 26, 96, 0.97) 4.94%, rgba(109, 0, 98, 0.97) 71.35%);
	border-radius: 38px;
	border-left: 6px solid #d7d7d7;
	border-top: 8px solid #d7d7d7;
	cursor:pointer;
	${media.mobile`
width: 80%;
height: 120px;
margin-bottom: 15px;
  `}
`

export const DashboardBtnText = styled.p`
	/* margin-top: 0px; */
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 25px;
	color: #ffffff;
`
