import React from "react"

import { LoadingContainer, TabErrorContainer } from "./style"
import { SectionLoaderIconPurple } from "utils/assets"
import { Img } from "globalStyles"
// SectionLoaderIcon
function SectionLoader() {
	return (
		<TabErrorContainer>
			<LoadingContainer>
				<Img width={"100%"} height={100} src={SectionLoaderIconPurple} alt="Loading" />
			</LoadingContainer>
		</TabErrorContainer>
	)
}

export default SectionLoader
