
import { getAdminRole } from "./MemoHeader"
import ForwardMemo from "./ForwardMemo"
import useAdminData from "hooks/useAdminData"


function StatusIndicator({status}){
    if(status === "approved"){
        return <button className="ml-auto rounded-md cursor-default bg-green-300 text-green-800  px-4 py-1 text-sm capitalize">{status}</button>
    }
    return (
        <button className="ml-auto cursor-default rounded-md bg-red-300 text-red-800 px-4 py-1 text-sm capitalize">{status}</button>
    )
}

export default function ThreadMessage({thread,singleMemo,isLastMessage,selectedLoans}){
    const {userProfile:admin} = useAdminData()
    const adminAvatar = thread.admin.avatar.url
    if(thread.admin.admin_role === 'es'){
        return (
            <div className="relative">
                <button className="h-3 w-3 bg-gray-400 rounded-full absolute -left-[38px] top-1/2"></button>
                <div key={thread._id} className={`bg-white w-[90%] my-8 rounded-md ${admin._id === thread.admin._id?"ml-auto":""}`}>
                <header className="flex items-start gap-1 p-3 border-b ">
                {
                    adminAvatar
                    ?<div className="w-[60px] h-[60px] rounded-full">
                        <img src={adminAvatar} alt="Admin Avatar" className="w-[60px] h-[60px] object-cover rounded-full" />
                    </div>
                    :<p><i className="bi bi-person-circle text-gray-400 text-6xl"></i></p>
                }
                <div className="ml-4">
                    <p className="text-[rgb(57,26,96)] capitalize font-semibold text-xl">{getAdminRole(thread.admin.admin_role)} Memo Response</p>
                    <p className="text-xl text-gray-700 font-medium">
                        <span>{thread.admin.first_name}</span>
                        <span className="text-base text-gray-500">({getAdminRole(thread.admin.admin_role)})</span>
                    </p>
                </div>
                 {thread.admin.admin_role !== "lph" && <StatusIndicator status={thread.status}/>}
                </header>
                <div className="p-3">
                <div className="my-2 px-2">
                    <p>{thread.note}</p>
                </div>
                {
                    (
                        admin._id !== thread.admin._id
                        && admin.admin_role === "lph"
                        && isLastMessage
                        && !singleMemo.la_approved
                    ) && (
                        <ForwardMemo selectedLoans={selectedLoans} sender={thread.admin._id} receiver={singleMemo.to_admin._id} memo_id={singleMemo.memo_id}/>
                    )
                }
                </div>
            </div>
            </div>
        )
    }
    return (
        <div className="relative">
            <button className="h-3 w-3 bg-gray-400 rounded-full absolute -left-[38px] top-1/2"></button>
            <div key={thread._id} className={`bg-white w-[90%] my-8 rounded-md ${admin._id === thread.admin._id?"ml-auto":""}`}>
            <header className="flex items-start gap-1 p-3 border-b ">
            {
                adminAvatar
                ?<div className="w-[60px] h-[60px] rounded-full">
                    <img src={adminAvatar} alt="Admin Avatar" className="w-[60px] h-[60px] object-cover rounded-full" />
                </div>
                :<p><i className="bi bi-person-circle text-gray-400 text-6xl"></i></p>
            }
            <div className="ml-4">
                <p className="text-[rgb(57,26,96)] capitalize font-semibold text-xl">{getAdminRole(thread.admin.admin_role)} Memo Response</p>
                <p className="text-xl text-gray-700 font-medium">
                    <span>{thread.admin.first_name}</span>
                    <span className="text-base text-gray-500">({getAdminRole(thread.admin.admin_role)})</span>
                </p>
            </div>
             {thread.admin.admin_role !== "lph" && <StatusIndicator status={thread.status}/>}
            </header>
            <div className="p-3">
            <div className="my-2 px-2">
                <p>{thread.note}</p>
            </div>
            {
                (
                    admin._id !== thread.admin._id
                    && isLastMessage
                    && !singleMemo.la_approved
                ) && (
                    <ForwardMemo selectedLoans={selectedLoans} sender={thread.admin._id} receiver={thread.to_admin._id} memo_id={singleMemo.memo_id}/>
                )
            }
            </div>
        </div>
        </div>
    )
    
}
