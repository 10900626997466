import DashboardHome from "components/Dashboard/Home"
import MainLayout from "layout/MainLayout"
import React from "react"


function HomePage() {
	return (
		<MainLayout>
			<DashboardHome />		
		</MainLayout>
	)
}

export default HomePage
