import { toast } from "react-hot-toast"
import { BASE_URL } from "services"
import http from "./httpService"

export async function getRecievedMemo() {
	try {
		const { data } = await http.get(`${BASE_URL}/memos/received`)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function getAllRecievedMemos() {
	try {
		const { data } = await http.get(`${BASE_URL}/admin-memos/recieved`)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function addThread() {
	try {
		const { data } = await http.post(`${BASE_URL}/admin-memos/`)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function getAllSentMemos() {
	try {
		const { data } = await http.get(`${BASE_URL}/admin-memos/sent`)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function getUnreadMemo() {
	try {
		const { data } = await http.get(`${BASE_URL}/memos/unread`)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function getReadMemo() {
	try {
		const { data } = await http.get(`${BASE_URL}/memos/read`)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function getSentMemo() {
	try {
		const { data } = await http.get(`${BASE_URL}/memos/sent`)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function getExecutiveMemo() {
	try {
		const { data } = await http.get(`${BASE_URL}/admin-memos/executive`)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function getSingleMemo(memoId, threadId) {
	const payload = { th_id: threadId }
	try {
		const { data } = await http.get(`${BASE_URL}/memos/view/${memoId}/${threadId}`, payload)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function getMemo(memoId) {
	const payload = { }
	try {
		const { data } = await http.get(`${BASE_URL}/admin-memos/${memoId}/`, payload)
		return {
			status:"success",
			...data,
		}
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
		return {
			data:null,
			status:"failed",
			error:err,
			error_message:err.response.data.message
		}
	}
}

export async function getSingleMemoSent(memoId) {
	try {
		const { data } = await http.get(`${BASE_URL}/memos/sent/single-item/${memoId}`)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function getReApplicants() {
	try {
		const { data } = await http.get(`${BASE_URL}/re-applicant/admin/re-apply/all`)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
