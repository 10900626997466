import React from "react"
import ChartsComp from "../Charts"
import CustomCard from "../customcard"
import cardData from "../customcard/cardData"
import { DCardContainer, HomeContainer, HomeLeft, HomeRight } from "./style"
import { useQuery } from "react-query"
import { loanAnalytics } from "services/LoanService"
import RecentApplications from "./RecentApplications"
import { Div } from "globalStyles"
import useAdminData from "hooks/useAdminData"
import { Link } from "react-router-dom"
import { DASHBOARD_DRAFTS } from "routes"

const DashboardHome = () => {
	const {adminRole} = useAdminData()
	const { data } = useQuery("analytics", loanAnalytics)
	console.log(data)
	return (
		<HomeContainer>
			<h3>Dashboard</h3>
			<DCardContainer>
				<CustomCard iconUrl={cardData[0].tr.icon} title={cardData[0].tr.title} amount={data?.data?.total_applications || "0"} />
				<CustomCard iconUrl={cardData[1]?.or?.icon} title={cardData[1]?.or?.title} amount={data?.data?.approved_loans || "0"} />
				<CustomCard iconUrl={cardData[2]?.ofliner?.icon} title={cardData[2]?.ofliner?.title} amount={data?.data?.pending_loans ||  "0"} />
				<CustomCard iconUrl={cardData[3]?.prides?.icon} title={cardData[3]?.prides?.title} amount={data?.data?.rejected_loans ? data?.data?.rejected_loans : "0"} />
				<CustomCard sign="yes" iconUrl={cardData[4].tp.icon} title={cardData[4].tp.title} amount={data?.data?.total_amount_disbursed ? data?.data?.total_amount_disbursed : "0"} />
				<CustomCard sign="yes" iconUrl={cardData[5]?.cant?.icon} title={cardData[5]?.cant?.title} amount={data?.data?.total_amount_repayed ? data?.data?.total_amount_repayed : "0"} />
				<CustomCard iconUrl={cardData[6]?.ct?.icon} title={cardData[6]?.ct?.title} amount={data?.data?.due_loans ? data?.data?.due_loans : "0"} />
				{
					adminRole === "es" || adminRole === "lph" || adminRole === "lo"
					?(
						<Link to={DASHBOARD_DRAFTS} className="w-full">
							<CustomCard iconUrl={cardData[7]?.st?.icon} title={cardData[7]?.st?.title} amount={data?.data?.incomplete_applications ? data?.data?.incomplete_applications : "0"} />
						</Link>
					)
					:(<CustomCard iconUrl={cardData[7]?.st?.icon} title={cardData[7]?.st?.title} amount={data?.data?.incomplete_applications ? data?.data?.incomplete_applications : "0"} />)
				}
			</DCardContainer>
			<Div width="100%" display="flex" justify="space-between">
				<HomeLeft>
					<ChartsComp />
				</HomeLeft>
				<HomeRight>
					<RecentApplications />
				</HomeRight>
			</Div>
		</HomeContainer>
	)
}

export default DashboardHome
