import { useQuery } from "react-query"
import { BASE_URL } from "services"
import http from "services/httpService"

export default function useAdminData(){
    const url = `${BASE_URL}/admin/who_i_am`
	const fetcher = async () => {
		const res = await http.get(url)
		return res?.data?.data
	}

	const { data,isLoading } = useQuery("profile", fetcher)

    return {
        userProfile:data,
        isLoading,
        adminRole:data?.admin_role
    }
}