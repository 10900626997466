import { BASE_URL } from "services";

const apiRoute = {
    loanAccount: `${BASE_URL}/admin/loan_account`,
    viewLoanAccount: `${BASE_URL}/admin/loan_account`,
    loanAccountDetails: `${BASE_URL}/admin/loan_account/details`,
    viewLoanAccountDetails: `${BASE_URL}/admin/loan_account/details`,
    deleteLoanAccountDetails: `${BASE_URL}/admin/loan_account/details`,

}

 export default apiRoute;