import { SearchInputType } from "components/Dashboard/Loan/style";
import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { addLoanAccount } from 'services/ReappLoanService';
import AddLoanInfo from "./AddLoanInfo";
import Colors from "utils/colors";

const PopupOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`;

const PopupContent = styled.div`
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 80%;
    max-height: 80%;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;

    h2 {
        margin-top: 0;
    }

    form {
        display: flex;
        flex-direction: column;

        label {
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;

            input {
                margin-top: 5px;
                padding: 8px;
                border: 1px solid #ccc;
                border-radius: 4px;
            }
        }

        button {
            margin-top: 20px;
            padding: 10px;
            border: none;
            background: ${Colors.primary};
            color: white;
            border-radius: 4px;
            cursor: pointer;

            &:hover {

            background: ${Colors.primaryLight};

            }
        }
    }
`;

const AddButton = styled.button`
  margin: 10px 10px;
  padding: 10px;
  background: ${Colors.primary};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: ${Colors.primaryLight};
  }
`;

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 35px;
    cursor: pointer;
    color: red;
`;

const ReappFilterComp = ({ filter, setFilter }) => {
    const [value, setValue] = useState(filter);
    const [showPopup, setShowPopup] = useState(false);
    const [loanInfoPopup, setLoanInfoPopup] = useState(false);
    const [userId, setUserId] = useState(null);
    const onChange = useAsyncDebounce(value => {
        setFilter(value || undefined);
    }, 1000);

    const initialValues = {
        first_name: '',
        surname: '',
        bvn: '',
        dob: '',
        phone: '',
        email: ''
    };

    const validationSchema = Yup.object({
        first_name: Yup.string().required('First name is required'),
        surname: Yup.string().required('Surname is required'),
        bvn: Yup.string().length(11, 'BVN must be 11 digits').required('BVN is required'),
        dob: Yup.date().required('Date of Birth is required'),
        phone: Yup.string().length(11, 'Phone number must be 11 digits').required('Phone number is required'),
        email: Yup.string().email('Invalid email format').required('Email is required'),
    });

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            const data = await addLoanAccount(values);
            setUserId(data.loan.id);
            setShowPopup(false); 
            setLoanInfoPopup(true);

        } catch (error) {
            console.error(error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div>
            <span>
                <SearchInputType
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder="Search..."
                    type="search"
                />
            </span>
            <AddButton onClick={() => setShowPopup(true)}>Add Loan Record</AddButton>
            {showPopup && (
                <PopupOverlay>
                    <PopupContent>
                        <CloseButton onClick={() => setShowPopup(false)}>×</CloseButton>
                        <h2>Add Loan Record</h2>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <label>
                                        First Name:
                                        <Field type="text" name="first_name" />
                                        <ErrorMessage name="first_name" component="div" style={{ color: 'red' }} />
                                    </label>
                                    <label>
                                        Surname:
                                        <Field type="text" name="surname" />
                                        <ErrorMessage name="surname" component="div" style={{ color: 'red' }} />
                                    </label>
                                    <label>
                                        BVN:
                                        <Field type="text" name="bvn" />
                                        <ErrorMessage name="bvn" component="div" style={{ color: 'red' }} />
                                    </label>
                                    <label>
                                        Date of Birth:
                                        <Field type="date" name="dob" />
                                        <ErrorMessage name="dob" component="div" style={{ color: 'red' }} />
                                    </label>
                                    <label>
                                        Phone Number:
                                        <Field type="tel" name="phone" />
                                        <ErrorMessage name="phone" component="div" style={{ color: 'red' }} />
                                    </label>
                                    <label>
                                        Email:
                                        <Field type="email" name="email" />
                                        <ErrorMessage name="email" component="div" style={{ color: 'red' }} />
                                    </label>
                                    <button type="submit" disabled={isSubmitting}>Submit</button>
                                </Form> 
                            )}
                        </Formik>
                    </PopupContent>
                </PopupOverlay>
            )}
            {loanInfoPopup && <AddLoanInfo onClose={() => setLoanInfoPopup(false)} userId={userId} />}
        </div>
    );
};

export default ReappFilterComp;
