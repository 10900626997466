import "./App.css";
import "bootstrap-icons/font/bootstrap-icons.css"
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClient, QueryClientProvider } from 'react-query'
import LoginPage from './pages/Login'
import HomePage from './pages/Home'

import {
  DASHBOARD_APPLY_POSTGRAD_ROUTE,
  DASHBOARD_APPLY_PROFESSIONAL_ROUTE,
  DASHBOARD_APPLY_UNDERGRAD_ROUTE,
  DASHBOARD_DRAFTS,
  DASHBOARD_ENQUIRY_DETAILS_ROUTE,
  DASHBOARD_ENQUIRY_ROUTE,
  DASHBOARD_LOAN_DETAILS,
  DASHBOARD_LOAN_ROUTE,
  DASHBOARD_MEMO_DETAILS_ROUTE,
  DASHBOARD_MEMO_ROUTE,
  DASHBOARD_NEWSLETTER_ROUTE,
  DASHBOARD_RE_APPLICANT_ROUTE,
  DASHBOARD_ROUTE_SUPERUSER,
  DASHBOARD_SETTINGS_ROUTE,
  DASHBOARD_STAFF_ROUTE,
  LOGIN_ROUTE,
} from './routes'
import ProfilePage from 'pages/Profile'
import LoanPage from 'pages/Loan'
import StaffPage from 'pages/Staff'
import StudentPage from 'pages/Students'
import LogoutPage from 'pages/Logout'
import LoanDetailsPage from 'pages/Loan/LoanDetailsPage'
import { Toaster } from 'react-hot-toast'
import MemoPage from 'pages/Memo'
import MemoDetailsPage from 'pages/Memo/MemoDetailsPage'
import EnquiryPage from 'pages/Enquiry'
import EnqDetailsPage from 'pages/Enquiry/EnqDetailsPage'
import CreateAccount from 'pages/CreateAccount'
import BlogPage from 'pages/Blog'
import NewsLetterPage from 'pages/NewsLetter'
import SettingsPage from 'pages/Settings'
import CreateBlogPage from 'pages/Blog/create'
import EditBlogPage from 'pages/Blog/edit'
import ErrorPage from 'pages/ErrorPage'
import MemoDetailsSinglePage from 'pages/Memo/MemoDetailsSinglePage'
import ReApplicantPage from 'pages/ReApplicant'
import PostGraduateLoan from 'components/Dashboard/Apply/PostgraduateLoan'
import UndergraduateLoan from 'components/Dashboard/Apply/UndergraduateLoan'
import ReappDashboardHome from 'components/Dashboard/Apply/Home'
import Drafts from "pages/Drafts";
import SuperUserPage from "components/superuser";
import SuperUserAddLoan from "components/superuser/add";

function App() {
  const queryClient = new QueryClient()
  return (
    <>
      <Toaster />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path={DASHBOARD_ROUTE_SUPERUSER} element={<SuperUserPage/>} />
            <Route path={`${DASHBOARD_ROUTE_SUPERUSER}/add`} element={<SuperUserAddLoan/>} />
            <Route path="/create_account/:token" element={<CreateAccount />} />
            <Route path="profile" element={<ProfilePage />} />
            <Route path={DASHBOARD_LOAN_ROUTE} element={<LoanPage />} />
            <Route
              path={`${DASHBOARD_LOAN_DETAILS}/:id`}
              element={<LoanDetailsPage />}
            />
            <Route path={DASHBOARD_MEMO_ROUTE} element={<MemoPage />} />
            <Route path={DASHBOARD_DRAFTS} element={<Drafts/>} />
            <Route
              path={`${DASHBOARD_MEMO_DETAILS_ROUTE}/:id`}
              element={<MemoDetailsPage />}
            />
            <Route
              path={`${DASHBOARD_MEMO_DETAILS_ROUTE}/sent/single-view/:id`}
              element={<MemoDetailsSinglePage />}
            />
            <Route
              path={`${DASHBOARD_MEMO_DETAILS_ROUTE}/:id/:threadId`}
              element={<MemoDetailsPage />}
            />
            <Route
              path={`${DASHBOARD_MEMO_DETAILS_ROUTE}/:id`}
              element={<MemoDetailsPage />}
            />
            <Route path={DASHBOARD_STAFF_ROUTE} element={<StaffPage />} />
            <Route path={DASHBOARD_ENQUIRY_ROUTE} element={<EnquiryPage />} />
            <Route
              path={`${DASHBOARD_ENQUIRY_DETAILS_ROUTE}/:id`}
              element={<EnqDetailsPage />}
            />
            <Route
              path={DASHBOARD_NEWSLETTER_ROUTE}
              element={<NewsLetterPage />}
            />
            <Route path={DASHBOARD_SETTINGS_ROUTE} element={<SettingsPage />} />
            <Route
              path={DASHBOARD_RE_APPLICANT_ROUTE}
              element={<ReApplicantPage />}
            />

            <Route path="staff" element={<StaffPage />} />
            <Route path="blog" element={<BlogPage />} />
            <Route path="blog/:title/:id" element={<EditBlogPage />} />
            <Route path="/blog/create-post" element={<CreateBlogPage />} />
            <Route path="student" element={<StudentPage />} />
            <Route path="logout" element={<LogoutPage />} />

            <Route path={LOGIN_ROUTE} element={<LoginPage />} />
            <Route path="*" element={<ErrorPage />} />

            <Route path="apply" element={<ReappDashboardHome />} />
            <Route path={DASHBOARD_APPLY_UNDERGRAD_ROUTE} element={<UndergraduateLoan/>}>
              <Route path=":id" element={<UndergraduateLoan />} />
            </Route>
            <Route path={DASHBOARD_APPLY_POSTGRAD_ROUTE} element={<PostGraduateLoan />}>
              <Route path=":id" element={<PostGraduateLoan />} />
            </Route>
            <Route path={DASHBOARD_APPLY_PROFESSIONAL_ROUTE} element={<PostGraduateLoan />}>
              <Route path=":id" element={<PostGraduateLoan />} />
            </Route>
          </Routes>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  )
}

export default App
