import React from "react"
import { useLocation } from "react-router-dom"
import BigMenu from "./BigMenu"
import { menuData, lphData, loData, accData, fdoData, blogData } from "./menuData"
import { BigMenuItemsContainer } from "./style"

function MenuDas({ user }) {
	const location = useLocation()
	// console.log("User Logged In", user)
	const menuBase =
		user?.admin_role === "es"
			? menuData
			: user?.admin_role === "lph"
			? lphData
			: user?.admin_role === "lo"
			? loData
			: user?.admin_role === "acc"
			? accData
			: user?.admin_role === "fdo"
			? fdoData
			: user?.admin_role === "blog"
			? blogData
			: user?.admin_role === "la"
			? lphData
			: blogData

	return (
		<BigMenuItemsContainer>
			{menuBase?.map((menu, i) => (
				<BigMenu
					link={menu.link}
					text={menu.text}
					key={i}
					icon={location?.pathname === menu.link ? menu.activeIcon : menu.link.includes(location.pathname.split("/")[2]) ? menu.activeIcon : menu.icon}
				/>
			))}
		</BigMenuItemsContainer>
	)
}

export default MenuDas
