import React, { useMemo, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { DASHBOARD_ENQUIRY_ROUTE } from "routes"
import { CtaMemo } from "../Loan/LoanDetails/style"
import { MemoDetailsDiv, MsgBody, MsgBtn, MsgDiv, ReplyBtn, ReplyDiv } from "./style"
import { fetchSingleMessages, replyMessage } from "services/MessageService"
import * as Yup from "yup"
import { useQuery } from "react-query"
import FullScreenLoader from "../FullScreenLoader"
import EmptyData from "components/Emptydata"
import { useFormik } from "formik"
import authStore from "mobx/AuthStore"
import { observer } from "mobx-react-lite"

const EnqDetailsComp = () => {
	const navigate = useNavigate()
	const params = useParams()
	const [toggleMsg, setToggleMsg] = useState(false)
	const [replyMsg, setReplyMsg] = useState(false)

	const toggleHandler = () => {
		setToggleMsg(!toggleMsg)
	}
	const replyHandler = () => {
		setReplyMsg(!replyMsg)
	}

	const { isLoading, data: singleMessage } = useQuery(["single-message", params?.id], () => fetchSingleMessages(params?.id), { keepPreviousData: true })

	const data = useMemo(() => {
		const allAdmin = singleMessage?.data ? singleMessage?.data : {}
		return allAdmin
	}, [singleMessage?.data])

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			reply_message: data?.reply_message ? data?.reply_message : ""
		},
		onSubmit: async (data, helpers) => {
			const response = await replyMessage(data, params?.id)

			if (!response.error) {
				helpers.resetForm()
				navigate(`${DASHBOARD_ENQUIRY_ROUTE}`)
			}
		},
		validationSchema: Yup.object({
			reply_message: Yup.string().max(255).required().label("Reply Message")
		})
	})

	return (
		<>
			{isLoading && <FullScreenLoader />}
			<MemoDetailsDiv>
				<MsgDiv>
					<h4>
						Message <span>{data?.fullname} </span>with email address {data?.email}
					</h4>
					<MsgBtn onClick={() => toggleHandler()}>Open</MsgBtn>
				</MsgDiv>
				<Link to={DASHBOARD_ENQUIRY_ROUTE}>Back</Link>

				{toggleMsg && (
					<MsgBody>
						<div>
							<h1>Enquiry from {data?.fullname}</h1>
							<h2>Message:</h2>
							<p>{data?.message}</p>
							<ReplyBtn onClick={() => replyHandler()}>{replyMsg ? "Close Reply" : "Reply..."}</ReplyBtn>
						</div>
						{replyMsg && (
							<ReplyDiv onSubmit={formik.handleSubmit}>
								<CtaMemo name="reply_message" placeholder="Reply this message..." onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.reply_message}></CtaMemo>
								{formik.touched.reply_message && formik.errors.reply_message && <span style={{ color: "red" }}>{formik.errors.reply_message}</span>}
								<button disabled={authStore.replying} type="submit">
									{authStore.replying ? "Replying..." : "Submit"}
								</button>
							</ReplyDiv>
						)}
					</MsgBody>
				)}
				{!isLoading && data.length <= 0 ? <EmptyData text="No read messages yet" /> : null}
			</MemoDetailsDiv>
		</>
	)
}

export default observer(EnqDetailsComp)
