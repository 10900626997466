import styled from "styled-components"
import Colors from "utils/colors"

export const TextInputContainer = styled.div`
	width: ${({ width }) => (width ? width : "100%")};
	display: flex;
	flex-direction: column;
	margin-top: ${({ mt }) => (mt ? mt : "1rem")};
	label {
		color: ${({labelColor}) => labelColor ? labelColor : `${Colors.black}`};
	}
`
export const InputText = styled.input`
	width: 100%;
	height: 35px;
	border: 2px solid #d9d9d9;
	border-radius: 15px;
	padding: 0px 15px;
	margin-top: 0.2rem;
	outline: none;
	:active {
		background-color: ${Colors.white};
	}
	:valid {
		background-color: ${Colors.white};
	}
	::placeholder {
		color: ${Colors.muted};
	}
`
export const ErrorMsgSpan = styled.p`
	color: #dd0e3b;
	font-size: 14px;
	margin-top: ${({ mt }) => (mt ? mt : "5px")};
	margin-bottom: ${({ mt }) => (mt ? mt : "-10px")};
`
