import TextInput from "components/TextInput"
import { Img } from "globalStyles"
import authStore from "mobx/AuthStore"
import React from "react"
import { DASHBOARD_ROUTE, SIGNUP_ROUTE } from "routes"
import { AppLogo2 } from "utils/assets"
import { AuthButton, AuthHeader } from ".."
import { AuthCard, Authcontainer, AuthContent } from "../style"
import { LoginContainer, LoginDiv, LoginFormContainer } from "./style"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { loginUser } from "services/AuthService"

const LoginComponent = () => {
	const navigate = useNavigate()
	const formik = useFormik({
		enableReinitialize: false,
		initialValues: {
			email: "",
			password: ""
		},
		onSubmit: async (data, helpers) => {
			const response = await loginUser(data)
			// console.log("Resp", response)

			if (response && !response?.error) {
				helpers.resetForm()
				navigate(`${DASHBOARD_ROUTE}`)
			}
			if (response?.error && response?.message === "Your account is not verified. Check your email address.") {
				navigate(`${SIGNUP_ROUTE}`)
			}
		},
		validationSchema: Yup.object({
			email: Yup.string().max(255).email().required().label("Email"),
			password: Yup.string().min(8).required("Password is required.").label("Password")
		})
	})

	return (
		<Authcontainer>
			<AuthContent>
				<Img src={AppLogo2} alt="BSL" />
				<LoginContainer>
					<LoginDiv onSubmit={formik.handleSubmit}>
						<AuthCard>
							<LoginFormContainer>
								<AuthHeader text="Log In" />
								<TextInput
									label="Email"
									name="email"
									id="email"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.email}
									error={formik.errors.email}
									visible={formik.touched.email}
									placeholder="e.g john@domain.com"
									mt="0.5rem"
								/>
								<TextInput
									label="Password"
									type="password"
									name="password"
									id="password"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.password}
									error={formik.errors.password}
									visible={formik.touched.password}
									placeholder="Enter your password"
								/>
								<AuthButton title={authStore.isLoading ? "Loading..." : "Login"} />
							</LoginFormContainer>
						</AuthCard>
					</LoginDiv>
				</LoginContainer>
			</AuthContent>
		</Authcontainer>
	)
}

export default observer(LoginComponent)
