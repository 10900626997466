import React from "react"
import { ItemContainer, ItemInnerDivContainer } from "./style"

function StaffItem({ title = "placeholder", value = "value" }) {
	return (
		<ItemContainer>
			<p>{title}</p>
			<ItemInnerDivContainer>
				<p>{value}</p>
			</ItemInnerDivContainer>
		</ItemContainer>
	)
}

export default StaffItem
