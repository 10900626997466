import React from "react"
import MainLayout from "layout/MainLayout"
import NewsLetterForm from "components/Dashboard/NewsLetter"

function NewsLetterPage() {
	return (
		<MainLayout>
			<NewsLetterForm />
		</MainLayout>
	)
}

export default NewsLetterPage
