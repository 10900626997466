import React from 'react'
import MemoTab from './MemoTab'
import useAdminData from 'hooks/useAdminData'
import LPHMemoTab from './LPHMemoTab'
import ExecutiveMemoTab from './ExecutiveMemoTab'

const MemoComp = () => {
  const {adminRole} = useAdminData()
  console.log({adminRole})
  if(adminRole === "lph") {
    return <LPHMemoTab/>
  }
  if(adminRole === "es") {
    return <ExecutiveMemoTab/>
  }
  // if(adminRole === "ol") {
  //   return <ExecutiveMemoTab/>
  // }
  return (
    <MemoTab />
  )
}

export default MemoComp