// import { Link } from "react-router-dom";
// import { DASHBOARD_LOAN_DETAILS } from "routes";

import staffStore from "mobx/StaffStore"
import { Statuslabel, ViewAdminBtn } from "./style"

export const STAFFCOLUMNS = [
	{
		Header: "ID",
		accessor: (originalRow, rowIndex) => 1 + rowIndex
	},
	{
		Header: "First Name",
		accessor: (originalRow, rowIndex) => (originalRow?.first_name ? originalRow?.first_name : "Not Provided")
	},
	{
		Header: "Last Name",
		accessor: (originalRow, rowIndex) => (originalRow?.surname ? originalRow?.surname : "Not Provided")
	},
	{
		Header: "Email",
		accessor: "email"
	},
	{
		Header: "Role",
		accessor: (originalRow, rowIndex) =>
			originalRow?.admin_role === "es"
				? "Super Admin"
				: originalRow?.admin_role === "lo"
				? "Loan Officer"
				: originalRow?.admin_role === "lph"
				? "Loan Processing Head"
				: originalRow?.admin_role === "la"
				? "Auditor"
				: originalRow?.admin_role === "fdo"
				? "Front Desk Officer"
				: originalRow?.admin_role === "blog"
				? "Blogger"
				: originalRow?.admin_role === "acc"
				? "Accountant"
				: "NIL"
	},

	{
		Header: "Status",
		accessor: (originalRow, rowIndex) => (
			<Statuslabel status={originalRow?.status}>
				<p className="text__value">
					{originalRow?.status === "activated"
						? "Active"
						: originalRow?.status === "pending"
						? "Pending"
						: originalRow?.status === "deactivated" || originalRow?.status === "suspended"
						? "Suspended"
						: "Inactive"}
				</p>
			</Statuslabel>
		)
	},
	{
		Header: "Details",
		accessor: (originalRow, rowIndex) => (
			<ViewAdminBtn
				status={"pending"}
				onClick={() => {
					staffStore.showDetails()
					staffStore.setSelect(originalRow)
				}}
			>
				<p className="text__value">View</p>
			</ViewAdminBtn>
		)
	}
]
