import FilterComp from "components/Dashboard/Loan/FilterComp"
import { TableContainer, TableDiv } from "components/Dashboard/Loan/style"
import React, { useMemo } from "react"

import { useTable, usePagination, useGlobalFilter } from "react-table"
import { useAdminData } from "services/AdminUserService"

import { STAFFCOLUMNS } from "./columns"
import FullScreenLoader from "components/Dashboard/FullScreenLoader"
import { Div } from "globalStyles"
import { ActionBtn } from "../style"
import { observer } from "mobx-react-lite"
import { Loandetails } from "pages/Memo/LoanTable/style"
import staffStore from "mobx/StaffStore"
import Staffdetails from "components/Dashboard/Staff/StaffDetailstable"
import { FormsModal } from "components/ConfirmAction/style"
import ConfirmAction from "components/ConfirmAction/confirmAction"
import authStore from "mobx/AuthStore"
import { activateStaff, deactivateStaff } from "services/AuthService"
import { useQueryClient } from "react-query"

const AdminTable = ({ onAddStaff }) => {
	const queryClient = useQueryClient()
	const onSuccess = () => {
		// console.log('admin data fetched successfully', adminData?.data?.data)
	}
	const onError = () => {
		// console.log('There is an error here', error)
	}

	const { isLoading, data: adminData, error, isError, isFetching } = useAdminData(onSuccess, onError)

	const columns = useMemo(() => STAFFCOLUMNS, [])
	const data = useMemo(() => {
		const allAdmin = adminData?.data?.data ? adminData?.data?.data : []
		return allAdmin
	}, [adminData?.data?.data])

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		canPreviousPage,
		canNextPage,
		pageOptions,
		gotoPage,
		pageCount,
		setPageSize,
		state,
		setGlobalFilter,
		prepareRow
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0 }
		},
		useGlobalFilter,
		usePagination
	)
	const { pageIndex, pageSize, globalFilter } = state

	if (isLoading || isFetching) {
		return <FullScreenLoader show={true} />
	}
	if (isError) {
		return <h4>{error.message}</h4>
	}

	const deactivateStaffx = async () => {
		const payload = { id: staffStore?.selected?.id }
		const response = await deactivateStaff(payload)
		if (!response.error) {
			queryClient.invalidateQueries("admins")
			staffStore.showDeactivatePop()
			staffStore.showDetails()
			staffStore.setSelect({})
		}
	}

	const activateStaffx = async () => {
		const payload = { id: staffStore?.selected?.id }
		const response = await activateStaff(payload)
		if (!response.error) {
			queryClient.invalidateQueries("admins")
			staffStore.showConfirmPop()
			staffStore.showDetails()
			staffStore.setSelect({})
		}
	}
	return (
		<>
			{staffStore.showConfirm && (
				<FormsModal show={staffStore.showConfirm}>
					<ConfirmAction
						disabled={authStore.isLoading}
						label="Are you sure, you want to activated this staff?"
						loadingLabel={authStore.isLoading ? "Please wait..." : "Continue"}
						onCancel={() => staffStore.showConfirmPop()}
						onContinue={() => activateStaffx()}
					/>
				</FormsModal>
			)}
			{staffStore?.deactivate && (
				<FormsModal show={staffStore.deactivate}>
					<ConfirmAction
						disabled={authStore.isLoading}
						label="Are you sure, you want to deactivated this staff?"
						loadingLabel={authStore.isLoading ? "Please wait..." : "Continue"}
						onContinue={() => deactivateStaffx()}
						onCancel={() => staffStore.showDeactivatePop()}
					/>
				</FormsModal>
			)}
			{staffStore.show && (
				<Loandetails show={staffStore.show}>
					<Staffdetails payload={staffStore.selected} close={() => staffStore.showDetails()} />
				</Loandetails>
			)}
			<TableContainer>
				<Div display="flex" width="100%" justify="space-between">
					<FilterComp filter={globalFilter} setFilter={setGlobalFilter} />
					<ActionBtn onClick={onAddStaff}>Add staff</ActionBtn>
				</Div>
				<TableDiv {...getTableProps()}>
					<thead>
						{headerGroups?.map(headerGroup => (
							<tr {...headerGroup?.getHeaderGroupProps()}>
								{headerGroup.headers?.map(column => (
									<th {...column?.getHeaderProps()}> {column.render("Header")} </th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page?.map(row => {
							prepareRow(row)
							return (
								<tr {...row?.getRowProps()}>
									{row?.cells?.map(cell => {
										return <td {...cell?.getCellProps()}> {cell?.render("Cell")} </td>
									})}
								</tr>
							)
						})}
					</tbody>
				</TableDiv>
				<div>
					<span>
						Page{" "}
						<strong>
							{pageIndex + 1} of {pageOptions.length}
						</strong>{" "}
					</span>
					<span>
						| Go to page:{" "}
						<input
							type="number"
							defaultValue={pageIndex + 1}
							onChange={e => {
								const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
								gotoPage(pageNumber)
							}}
							style={{ width: "50px" }}
						/>
					</span>
					<select value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
						{[10, 25, 50].map(pageSize => (
							<option key={pageSize} value={pageSize}>
								Show {pageSize}
							</option>
						))}
					</select>
					<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
						{" "}
						{"<<"}{" "}
					</button>
					<button onClick={() => previousPage()} disabled={!canPreviousPage}>
						Previous
					</button>
					<button onClick={() => nextPage()} disabled={!canNextPage}>
						Next
					</button>
					<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
						{" "}
						{">>"}{" "}
					</button>
				</div>
			</TableContainer>
		</>
	)
}

export default observer(AdminTable)
