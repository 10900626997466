import { configure, makeAutoObservable, observable } from "mobx"

// import jwt_decode from "jwt-decode"
configure({
	enforceActions: "never"
})

class BlogStore {
	isLoading = false
	replying = false
	constructor() {
		makeAutoObservable(this, {
			isLoading: observable,
			replying: observable
		})
	}

	loading() {
		this.isLoading = !this.isLoading
	}
	replyingMsg() {
		this.replying = !this.replying
	}
}

const blogStore = new BlogStore()

export default blogStore
