import toast from "react-hot-toast"
import authStore from "mobx/AuthStore"

import { BASE_URL } from "services"
import http from "./httpService"

export async function updatePersonalDetails(payload) {
	authStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/auth/create_account`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}

export async function uploadAdminDp(payload) {
	try {
		const { data } = await http.put(`${BASE_URL}/admin/update_avatar`, payload)
		toast.success(data.message)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
			return err.response.data
		} else {
			toast.error("Network Error")
			return err
		}
	}
}
export async function updateProfileDetails(payload) {
	authStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/admin/update_profile`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function updateNextOfKinUser(payload) {
	authStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/users/next_of_kin`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}

export async function updateBankDetails(payload) {
	authStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/users/bank_details`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}

export async function updatePassword(payload) {
	try {
		const { data } = await http.put(`${BASE_URL}/admin/update_password`, payload)
		toast.success(data.message)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
			return err.response.data
		} else {
			toast.error("Network Error")
			return err
		}
	}
}
export async function portalStatus() {
	try {
		const { data } = await http.get(`${BASE_URL}/misc/portal-status`)
		toast.success(data.message)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
			return err.response.data
		} else {
			toast.error("Network Error")
			return err
		}
	}
}
export async function updatePortalStatus(payload) {
	try {
		const { data } = await http.put(`${BASE_URL}/admin/portal/status`, payload)
		toast.success(data.message)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
			return err.response.data
		} else {
			toast.error("Network Error")
			return err
		}
	}
}
