import { PhotoProvider, PhotoView } from "react-photo-view"
import "react-photo-view/dist/react-photo-view.css"
import { Img } from "globalStyles"
import React from "react"
import { pdfIcon } from "utils/assets"
import { FileCardCon } from "./style"

const FileCard = ({ fileTxt = "Admission Letter", imgUrl = "", imgDesc = "PDF Icon" }) => {
	return (
		<FileCardCon>
			<PhotoProvider>
				<PhotoView src={imgUrl}>
					<Img src={imgUrl ? imgUrl : pdfIcon} alt={imgDesc} />
				</PhotoView>
			</PhotoProvider>
			<h4>{fileTxt}</h4>
		</FileCardCon>
	)
}

export default FileCard
