import React from "react"
import CloseFormBtn from "./CloseButton"
import { BtnContainer, DeleteFormContainer, Text, WlButton } from "./style"
import Colors from "utils/colors"
import { Div } from "globalStyles"

function ConfirmAction({ onCancel, onContinue, label, loadingLabel, disabled = false }) {
	return (
		<DeleteFormContainer>
			<Div width="100%" display="flex" justify="flex-end">
				<CloseFormBtn onClick={onCancel} disabled={disabled} />
			</Div>

			<Div width="100%" mt="20px">
				<Text>{label ? label : "Are you sure, you want to delete"}</Text>
			</Div>
			<BtnContainer>
				<WlButton disabled={disabled} w="45%" p="10px 0px" br="3px" bc="transparent" border={Colors.red} color={Colors.red} onClick={onCancel}>
					Cancel
				</WlButton>
				<WlButton disabled={disabled} w="45%" br="3px" p="10px 0px" border={Colors.primary} bc={Colors.primary} color={Colors.white} onClick={onContinue}>
					{loadingLabel ? loadingLabel : "Continue"}
				</WlButton>
			</BtnContainer>
		</DeleteFormContainer>
	)
}

export default ConfirmAction
