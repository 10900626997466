import { Link } from "react-router-dom"
import styled from "styled-components/macro"
import Colors from "utils/colors"

export const BlogCardDiv = styled.div`
	width: calc(100% - 1rem);
	height: 24rem;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
	border-radius: 3px;
	padding: 0.5rem;
	h2 {
		padding: 0rem;
		font-size: clamp(0.7rem, 2vw, 1rem);
	}
`
export const BlogPostContainer = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: 1fr;
	grid-column-gap: 15px;
	grid-row-gap: 15px;
	margin-top: 1.5rem;
	margin-bottom: 2.5rem;
`
export const ImageCard = styled.div`
	width: 100%;
	height: 15rem;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`
export const NewsCardCont = styled.div`
	height: 100%;
	width: 100%;
	/* background: red; */
	border-radius: 3px;
	display: flex;
	flex-direction: column;
`

export const ViewsContainer = styled.div`
	display: flex;
	align-items: center;
	span {
		margin-left: 4px;
	}
`
export const ActionBtnBlog = styled.button`
	display: flex;
	border: none;
	outline: none;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 35px;
	border-radius: 3px;
	cursor: pointer;
	background-color: ${({ bgColor }) => (bgColor ? bgColor : `${Colors.red}`)};
	margin-left: ${({ ml }) => (ml ? ml : `0px`)};
	span {
		margin-left: 4px;
	}
`
export const EditLin = styled(Link)`
	display: flex;
	border: none;
	outline: none;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 35px;
	border-radius: 3px;
	cursor: pointer;
	background-color: ${({ bgColor }) => (bgColor ? bgColor : `${Colors.red}`)};
	margin-left: ${({ ml }) => (ml ? ml : `0px`)};
	span {
		margin-left: 4px;
	}
`
