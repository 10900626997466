import React from "react"
import { RecentAppContainer } from "./style"
import { Div } from "globalStyles"
import { Link } from "react-router-dom"
import { DASHBOARD_LOAN_ROUTE } from "routes"
import Colors from "utils/colors"
import RecentItemLoan from "./RecentItem"
import { useQuery } from "react-query"
import { getRecentLoans } from "services/LoanService"
import SectionLoader from "components/SectionLoader"

function RecentApplications() {
	const { data, isLoading } = useQuery("recent-loans", getRecentLoans)
	// console.log(data)
	return (
		<RecentAppContainer>
			{isLoading && <SectionLoader />}
			{!isLoading && data?.data?.length > 0 && (
				<Div width="100%" display="flex" justify="space-between" alignI="center">
					<h4>Recent Applications</h4>
					<Link style={{ textDecoration: "none", fontWeight: "bold", color: `${Colors.primaryLight}` }} to={DASHBOARD_LOAN_ROUTE}>
						View All
					</Link>
				</Div>
			)}
			{data?.data?.map(loan => (
				<RecentItemLoan key={loan?._id} loan={loan} />
			))}
		</RecentAppContainer>
	)
}

export default RecentApplications
