import MainLayout from 'layout/MainLayout'
import React from 'react'
import ReappTab from 'components/Dashboard/ReappMemo/ReappTab'

const ReApplicantPage = () => {
  return (
    <MainLayout>
      <ReappTab /> 
    </MainLayout>
  )
}

export default ReApplicantPage
