import { useState } from "react";

export default function useModal(defaultState=false,openCallBack=null){
    const [open,setOpen] = useState(defaultState)

    const openModal = (params=null)=>{
        setOpen(true)
        if(openCallBack){
            return openCallBack(params)
        }

    }
    const closeModal = ()=>{
        setOpen(false)
    }

    return [open,openModal,closeModal];
}