import { message, Spin } from "antd"
import useAdminData from "hooks/useAdminData"
import { useState } from "react"
import { addESApprovedMemoThread, addESRejectedMemoThread, addForwardMemoThread } from "services/AdminUserService"


export function ActionButton({clickHandler,hidden,disabled,loading,children,btnType,className}){
    const btnClass = (function getBtnClass(){
        if(btnType === "accept") return "bg-green-300  text-green-800"
        return "bg-indigo-300  text-indigo-800"
    })()
    if(hidden) return null
    return (
        <button onClick={clickHandler} disabled={disabled || loading} className={`px-4 ${btnClass} ${className} py-1 rounded-md disabled:opacity-60 disabled:cursor-not-allowed font-medium`}>
            { loading ?<Spin/> :<>{children}</> }
        </button>
    )
}


export default function ForwardApprovedMemo({receiver,selectedLoans,memo}){
    const [loading,setLoading] = useState(false)
    const [rejectLoading,setRejectLoading] = useState(false)
    const [forwardingMemo,setForwardingMemo] = useState(false)
    const {adminRole} = useAdminData()
    const [note,setNote] = useState("")

    const memo_id = memo.memo_id

    console.log({memo})

    async function rejectMemo(){
        if(selectedLoans.length === 0) {
            message.warning("Please you should have atleast one loan in this memo")
            return;
        }
        if (adminRole === "es") {
            // console.log(userProfile)
            const payload = { note,status:"rejected",memo_id,receiver}
            setRejectLoading(true)
            const res = await addESRejectedMemoThread(payload)
            setRejectLoading(false)
            if (!res.error) {
                setNote("")
            }
        }

    }

    async function acceptMemo(){
        console.log(adminRole)
        if(selectedLoans.length === 0) {
            message.warning("Please you should have atleast one loan in this memo")
            return;
        }
        if (adminRole === "es") {
            const payload = { note,status:"accepted",memo_id,receiver,selectedLoans}
            setLoading(true)
            const res = await addESApprovedMemoThread(payload)
            setLoading(false)

            if (!res.error) {
                setNote("")
            }
        }

    }

    async function forwardMemo(){
        if(selectedLoans.length === 0) {
            message.warning("Please you should have atleast one loan in this memo")
            return;
        }
        if (adminRole === "lph") {
            const payload = { note,status:"accepted",memo_id,receiver,selectedLoans}
            setForwardingMemo(true)
            const res = await addForwardMemoThread(payload)
            setForwardingMemo(false)

            if (!res.error) {
                setNote("")
            }
        }

    }

    const condtion = memo.la_approved && adminRole === "es"
    console.log({condtion})

    if(memo.status === "approved") return null;
    if(!memo.la_approved) return null;
    if(memo.la_approved && memo.forwarded !== true && adminRole === "lph"){
        return (
            <div >
                <textarea value={note} onChange={(e)=>setNote(e.target.value)} placeholder="Add a note to your memo" className="resize-none p-2 min-h-[70px] rounded-md bg-gray-200 w-full"/>
                <div className="flex gap-2 justify-end items-center">
                    <ActionButton loading={forwardingMemo} clickHandler={forwardMemo}>
                        <span><i className="bi bi-reply-fill text-lg"></i></span>
                        <span>Send Memo To ES</span>
                    </ActionButton>
                </div>
            </div>
        )
    }
    if(memo.la_approved && memo.forwarded && adminRole === "es") return (
        <div >
            <textarea value={note} onChange={(e)=>setNote(e.target.value)} placeholder="Add a note to your memo" className="resize-none p-2 min-h-[70px] rounded-md bg-gray-200 w-full"/>
            <div className="flex gap-2 justify-end items-center">
                <ActionButton disabled={rejectLoading} loading={loading} btnType={"accept"} clickHandler={acceptMemo}>
                    <span><i className="bi bi-check2-circle text-lg"></i></span>
                    <span>Accept Memo</span>
                </ActionButton>
                <ActionButton loading={rejectLoading} disabled={loading} clickHandler={rejectMemo}>
                    <span><i className="bi bi-reply-fill text-lg"></i></span>
                    <span>Query Memo</span>
                </ActionButton>
            </div>
        </div>
    )
    return null;
}