import useAdminData from "hooks/useAdminData";
import MainLayout from "layout/MainLayout";
import { useNavigate } from "react-router-dom";
import useAddForm, { useBankData } from "./hooks/useAddForm";
import { Select, Spin } from "antd";
import { useEffect } from "react";
import FullScreenLoader from "components/Dashboard/FullScreenLoader";
import { uniData } from "./lib/unidata";
import lgaData from "./lib/lgaData";
import { parseStringToInt } from "./lib/utils";


function Field({label,setValue,value,className,type="text",name,readOnly,placeholder}){
    return (
        <div className="my-3">
            <label
                htmlFor={`${label}-input`}
                className="block mb-1 font-medium capitalize">{label}
                </label>
            <input
                onChange={setValue}
                value={value}
                readOnly={readOnly}
                type={type}
                name={name}
                style={{outline: "none"}}
                placeholder={placeholder || `Enter Applicant ${label}`}
                id={`${label}-input`}
                className={`${className} w-[90%] border rounded-md border-solid p-2`}
                />
        </div>
    )
}
function SelectField({label,onSelect,value,className,options,loading,disabled}){
    return (
        <div className="my-3">
            <label
                htmlFor={`${label}-input`}
                className="block mb-1 font-medium capitalize">{label}
            </label>
            <Select
                value={value}
                loading={loading}
                disabled={disabled}
                options={[
                    {label:`Select ${label}`,value:"",},
                    ...options,
                ]}
                className={className}
                style={{width:"90%"}}
                onSelect={onSelect}
            />
        </div>
    )
}

export default function SuperUserAddLoan(){
    const navigate = useNavigate()
    const {adminRole,isLoading,userProfile} = useAdminData()
    const {
        applicantData,
        handleSetApplicantData,
        institutionData,
        handleSetInstitutiontData,
        bankDetails,
        handleSetBankDetails,
        firstGuarantorData,
        handleSetFirstGuarantorData,
        secondGuarantorData,
        handleSetSecondGuarantorData,
        loanAmount,
        handleSetLoanAmount,
        setBankDetails,
        refferedBy,
        setRefferedBy,
        loanTenure,
        setLoanTenure,
        loading,
        handleSubmit
    } = useAddForm()

    const {banks,loadingBanks} = useBankData()
    useEffect(()=>{
        if(!isLoading){
            if(userProfile && adminRole !== "es"){
                return navigate("/");
            }
            if(userProfile && !userProfile.isSuperuser){
                return navigate("/");
            }
        }
    },[isLoading,adminRole,navigate,userProfile])
    if(isLoading){
        return <FullScreenLoader/>
    }

    const getMonthlyRepaymentAmount = ()=>{
        const repayment = parseFloat(loanAmount)/parseStringToInt(loanTenure,12)
        if(repayment) return repayment.toLocaleString(undefined,{maximumFractionDigits:2})
        return "0.00"
    }

    return (
        <MainLayout>
             <div className="p-5 bg-gray-100">
                <button onClick={()=>navigate(-1)} className="border px-6 border-solid py-2 font-semibold">
                    <span><i className="bi bi-arrow-left mr-2"></i></span>
                    <span>Back</span>
                </button>
                <h1 className="text-4xl my-8 text-center mb-12 font-semibold text-[#333333]">Add Loan Data</h1>
                <div className="my-3">
                    <form onSubmit={handleSubmit} action="" className="min-h-[450px] w-[85%] mx-auto bg-white p-4">
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <header className="border-b border-solid py-1 mb-4">
                                    <p className="text-lg font-bold">Personal Details</p>
                                </header>
                                <Field
                                    label={"First Name"}
                                    name={"first_name"}
                                    value={applicantData.first_name}
                                    setValue={handleSetApplicantData}
                                />
                                <Field
                                    label={"Last Name"}
                                    name={"surname"}
                                    value={applicantData.surname}
                                    setValue={handleSetApplicantData}
                                />
                                <Field
                                    label={"Phone"}
                                    name={"phone"}
                                    value={applicantData.phone}
                                    setValue={handleSetApplicantData}
                                />
                                <Field
                                    label={"Age"}
                                    name={"age"}
                                    value={applicantData.age}
                                    setValue={handleSetApplicantData}
                                />
                                <SelectField
                                    label={"Sex"}
                                    options={[
                                        {label:"Male", value: "male"},
                                        {label:"Female", value: "female"},
                                    ]}
                                    value={applicantData.sex}
                                    onSelect={(value)=>handleSetApplicantData({
                                        target:{
                                            name:"sex",
                                            value
                                        }
                                    })}
                                />
                                <SelectField
                                    label={"LGA"}
                                    options={lgaData}
                                    value={applicantData.lga}
                                    onSelect={(value)=>handleSetApplicantData({
                                        target:{
                                            name:"lga",
                                            value
                                        }
                                    })}
                                />
                            </div>
                            <div>
                                <header className="border-b border-solid py-1 mb-4">
                                    <p className="text-lg font-bold">Institution Details</p>
                                </header>
                                {/* <Field
                                    label={"Institution"}
                                    name={"name"}
                                    value={institutionData.phone}
                                    setValue={handleSetInstitutiontData}
                                /> */}
                                <SelectField
                                    label={"Institution"}
                                    onSelect={(value) => handleSetInstitutiontData({
                                        target:{
                                            name:"name",
                                            value,
                                        }
                                    })}
                                    value={institutionData.name}
                                    options={uniData}
                                />
                                <Field
                                    label={"Course"}
                                    name={"course"}
                                    value={institutionData.course}
                                    setValue={handleSetInstitutiontData}
                                />
                                <Field
                                    label={"Faculty"}
                                    name={"faculty"}
                                    value={institutionData.faculty}
                                    setValue={handleSetInstitutiontData}
                                />
                                <Field
                                    label={"Level"}
                                    name={"current_study_year"}
                                    value={institutionData.current_study_year}
                                    setValue={handleSetInstitutiontData}
                                />
                            </div>
                            <div>
                                <header className="border-b border-solid py-1 mb-4">
                                    <p className="text-lg font-bold">Bank and Loan Details</p>
                                </header>
                                <Field
                                    label={"Loan Amount"}
                                    name={"loan_amount"}
                                    value={loanAmount}
                                    setValue={handleSetLoanAmount}
                                />
                                <SelectField
                                    label={"Bank"}
                                    options={banks}
                                    loading={loadingBanks}
                                    disabled={banks.length < 2 || (!banks)}
                                    value={bankDetails.bank_code}
                                    onSelect={(value,record)=>setBankDetails(prev=>({
                                        ...prev,
                                        bank: record.label,
                                        bank_name: record.label,
                                        bank_code: value,
                                    }))}
                                /> 
                                <SelectField
                                    label={"Loan Tenure"}
                                    options={[
                                        { label: "12 Months", value:12},
                                        { label: "24 Months", value:24},
                                        { label: "36 Months", value:36}
                                    ]}
                                    value={loanTenure}
                                    onSelect={(value)=>setLoanTenure(value)}
                                />
                                <label className="d-block font-medium mt-2">Monthly Repayment Amount</label>
                                <p className="py-3 rounded-md text-lg text-secondary-600 w-[75%] font-bold px-6 bg-secondary-50 border border-solid border-secondary-400">
                                ₦{getMonthlyRepaymentAmount()}
                                </p>
                                <Field
                                    label={"Account Number"}
                                    name={"account_number"}
                                    value={bankDetails.account_number}
                                    setValue={handleSetBankDetails}
                                />
                                <Field
                                    label={"Reffered By"}
                                    name={"refferedBy"}
                                    placeholder={"Enter Reffered By Details"}
                                    value={refferedBy}
                                    setValue={(e)=>setRefferedBy(e.target.value)}
                                />
                            </div>
                            <div>
                            <header className="border-b border-solid py-1 mb-4">
                                    <p className="text-lg font-bold">Guarantor's Details</p>
                                </header>
                                <Field
                                    label={"First Guarantor's Name"}
                                    name={"first_name"}
                                    value={firstGuarantorData.first_name}
                                    setValue={handleSetFirstGuarantorData}
                                />
                                <Field
                                    label={"First Guarantor's Middle Name"}
                                    name={"middle_name"}
                                    value={firstGuarantorData.middle_name}
                                    setValue={handleSetFirstGuarantorData}
                                />
                                <Field
                                    label={"First Guarantor's Lastname"}
                                    name={"surname"}
                                    value={firstGuarantorData.surname}
                                    setValue={handleSetFirstGuarantorData}
                                />
                                <Field
                                    label={"First Guarantor Phone"}
                                    name={"phone"}
                                    value={firstGuarantorData.phone}
                                    setValue={handleSetFirstGuarantorData}
                                />
                                <Field
                                    label={"First Guarantor Work Place"}
                                    name={"work_place"}
                                    value={firstGuarantorData.work_place}
                                    setValue={handleSetFirstGuarantorData}
                                />
                                <br />
                                <Field
                                    label={"Second Guarantor's Name"}
                                    name={"first_name"}
                                    value={secondGuarantorData.first_name}
                                    setValue={handleSetSecondGuarantorData}
                                />
                                <Field
                                    label={"Second Guarantor's Middle Name"}
                                    name={"middle_name"}
                                    value={secondGuarantorData.middle_name}
                                    setValue={handleSetSecondGuarantorData}
                                />
                                <Field
                                    label={"Second Guarantor's Lastname"}
                                    name={"surname"}
                                    value={secondGuarantorData.surname}
                                    setValue={handleSetSecondGuarantorData}
                                />
                                <Field
                                    label={"Second Guarantor Phone"}
                                    name={"phone"}
                                    value={secondGuarantorData.phone}
                                    setValue={handleSetSecondGuarantorData}
                                />
                                <Field
                                    label={"Second Guarantor Work Place"}
                                    name={"work_place"}
                                    value={secondGuarantorData.work_place}
                                    setValue={handleSetSecondGuarantorData}
                                />
                            </div>
                        </div>
                        <footer className="sticky bottom-0 flex justify-end p-4 bg-white">
                            <button disabled={loading || (loadingBanks || banks.length < 2)} type="submit" className="text-lg px-6 py-2 disabled:opacity-45 disabled:cursor-not-allowed rounded-full bg-secondary-50 border border-solid border-secondary-400 text-secondary-600">
                                {loading ?<Spin/>: <span>Submit</span>}
                            </button>
                        </footer>
                    </form>
                </div>
            </div>
        </MainLayout>
    )
}