import * as Yup from 'yup'
import CustomSelectMain from 'layout/SelectInput'
import { useFormik } from 'formik'
import TextInput from 'components/TextInput'
import { Div, Img } from 'globalStyles'
import React, { useState } from 'react'
import { BankImg } from 'utils/assets'
import certData, { levelData, levelDataND } from './certData'
import {
  InfoContainer,
  InfoContent,
  InfoContentLeft,
  InfoContentRight,
  InfoText,
  InputDiv,
  InputWrap,
  ProceedBtn,
  ViewDetailBtn,
} from './style'
import { observer } from 'mobx-react-lite'
// import { addInstitutionUnder } from 'services/LoanService'
import loanStore from 'mobx/LoanStore'

import { useNavigate, useSearchParams } from 'react-router-dom'
import { uniData } from './uniData'
import LoanRequirements from './Requirements'
import { BASE_URL } from 'services'
import http from 'services/httpService'
import useSWR from 'swr'
// import FullScreenLoader from 'components/FullScreenLoader'
import { addInstitutionUnder } from 'services/ReappLoanService'
import FullScreenLoader from '../FullScreenLoader'

const InstitutionInfoPage = ({ loanType, mutate, user_key }) => {
  const [show, setShow] = useState(false)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const qinfo = searchParams.get('q')
  const userId = qinfo.split(' ')[0]
  const loanId = qinfo.split(' ')[1]
  const user = { user_id: userId }
  const url = `${BASE_URL}/re-applicant/admin/user`
  const fetcher = async () => {
    const res = await http.get(url, { params: { user_key: userId } })
    res.data.data.user_id = user
    return res?.data?.data
  }

  const { data: loanAll } = useSWR(url, fetcher)
  const loans = loanAll ? loanAll : []

  const formik = useFormik({
    initialValues: {
      name: ' ',
      course: ' ',
      faculty: ' ',
      matric_number: '',
      study_year: ' ',
      graduation_year: '',
      certificate: '',
      othercertificate: '',
      othername: '',
    },

    onSubmit: async (data, helpers) => {
      if (loanType === 'undergraduate') {
        const payload = { ...data }
        payload.user_key = userId
        payload.loan_type = 'undergraduate'
        payload.request_status =
          loans.length > 1 ? 're-application' : 'first-time'
        // payload.school_identity_card = schoolId
        // if (!schoolId) return toast.error("please upload ID card")

        const underResponse = await addInstitutionUnder(payload)


        if (!underResponse.error) {
          mutate()
        }
        if (underResponse.data.id) {
        // searchParams.set(`q=${userId} ${underResponse.data.id}`)

          navigate(`/dashboard/apply/undergraduate/${userId} ${underResponse.data.id}`)
        }
      }

      if (loanType === 'postgraduate') {
        const payload = { ...data }
        // payload.user_key = loanStore.getUserKey()
        payload.user_key = userId
        payload.loan_type = 'postgraduate'
        payload.request_status =
          loans.length > 1 ? 're-application' : 'first-time'
        const postResponse = await addInstitutionUnder(payload)
        if (postResponse.data.id) {
        // searchParams.set(`q=${userId} ${postResponse.data.id}`)

          navigate(`/dashboard/apply/postgraduate/${postResponse.data.id}`)
        }
      }
      if (loanType === 'professional') {
        const payload = { ...data }
        // payload.user_key = loanStore.getUserKey()
        payload.user_key = userId
        payload.loan_type = 'professional'
        payload.request_status =
          loans.length > 1 ? 're-application' : 'first-time'
        const postResponse = await addInstitutionUnder(payload)
        if (postResponse.data.id) {
        searchParams.set(`q=${userId} ${postResponse.data.id}`)
          navigate(`/dashboard/apply/professional/${postResponse.data.id}`)

        }
      }
    },

    validationSchema: Yup.object({
      name: Yup.string().max(350).required().label('Institution Name'),
      course: Yup.string().max(50).required().label('Course'),
      faculty: Yup.string().max(50).required().label('Faculty'),
      matric_number: Yup.string().max(150).label('Matric Number'),
      study_year: Yup.string().max(100).required().label('Study Year'),
      graduation_year: Yup.string().max(255).required().label('Admission Year'),
      certificate: Yup.string()
        .max(100)
        .required()
        .label('Certificate In View'),
      othercertificate: Yup.string().when('certificate', {
        is: 'Others',
        then: Yup.string().required().label('Other Cert'),
      }),
      othername: Yup.string().when('name', {
        is: 'Others',
        then: Yup.string().required().label('Enter Institution'),
      }),
    }),
  })

  function toggleRequirements() {
    setShow(!show)
  }

  const studyLevelData =
    formik?.values?.name && formik?.values?.name.includes('Polytechnic')
      ? levelDataND
      : levelData

  if (!loanAll) return <FullScreenLoader show={true} />
  return (
    <InfoContainer>
      <InfoContent>
        <InfoContentLeft onSubmit={formik.handleSubmit}>
          <InfoText>Institution Information</InfoText>
          <Div display="flex" width="95%" justify="flex-end">
            <ViewDetailBtn onClick={toggleRequirements}>
              View Requirements
            </ViewDetailBtn>{' '}
          </Div>
          {show && (
            <LoanRequirements OnClose={toggleRequirements} show={show} />
          )}
          <InputDiv>
            <InputWrap>
              <CustomSelectMain
                // mt="1.5rem"
                astheric={true}
                label={'Name of Institution'}
                name="name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.errors.name}
                visible={formik.touched.name}
              >
                <option style={{ color: 'grey' }} value="">
                  Select Institution
                </option>
                {uniData?.map((uni) => (
                  <option
                    className="active-option"
                    key={uni.id}
                    value={uni.value}
                  >
                    {uni.label}
                  </option>
                ))}
              </CustomSelectMain>

              {formik.values.name.toLowerCase() === 'others' && (
                <TextInput
                  astheric={true}
                  label="Specify"
                  type="text"
                  placeholder="Name of Institution"
                  name="othername"
                  id="othername"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.othername}
                  error={formik.errors.othername}
                  visible={formik.touched.othername}
                />
              )}

              <TextInput
                astheric={true}
                label="Faculty"
                placeholder="Science"
                name="faculty"
                id="faculty"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.faculty}
                error={formik.errors.faculty}
                visible={formik.touched.faculty}
              />

              <CustomSelectMain
                mt="1.5rem"
                astheric={true}
                label={'Year Of Study'}
                name="study_year"
                value={formik.values.study_year}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.errors.study_year}
                visible={formik.touched.study_year}
              >
                <option style={{ color: 'grey' }} value="">
                  Select Study of Year
                </option>
                {studyLevelData?.map((local) => (
                  <option
                    className="active-option"
                    key={local.id}
                    value={local.value}
                  >
                    {local.label}
                  </option>
                ))}
              </CustomSelectMain>

              <CustomSelectMain
                mt="-0.5rem"
                astheric={true}
                label={'Certificate In View'}
                name="certificate"
                value={formik.values.certificate}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.errors.certificate}
                visible={formik.touched.certificate}
              >
                <option style={{ color: 'grey' }} value="">
                  Select Certificate
                </option>
                {certData?.map((local) => (
                  <option
                    className="active-option"
                    key={local.id}
                    value={local.value}
                  >
                    {local.label}
                  </option>
                ))}
              </CustomSelectMain>
            </InputWrap>
            <InputWrap>
              <TextInput
                astheric={true}
                label="Course Of Study"
                placeholder="Chemistry"
                name="course"
                id="course"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.course}
                error={formik.errors.course}
                visible={formik.touched.course}
              />
              <TextInput
                label="Mat. No"
                placeholder="Mat. No"
                name="matric_number"
                id="matric_number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.matric_number}
                error={formik.errors.matric_number}
                visible={formik.touched.matric_number}
              />
              <TextInput
                astheric={true}
                label="Year of Graduation"
                type="month"
                placeholder="Year of Graduation"
                name="graduation_year"
                id="graduation_year"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.graduation_year}
                error={formik.errors.graduation_year}
                visible={formik.touched.graduation_year}
              />
              {formik.values.certificate === 'Others' && (
                <TextInput
                  astheric={true}
                  label="Specify"
                  type="text"
                  placeholder="ICAN, HSE etc"
                  name="othercertificate"
                  id="othercertificate"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.othercertificate}
                  error={formik.errors.othercertificate}
                  visible={formik.touched.othercertificate}
                />
              )}
            </InputWrap>
          </InputDiv>
          <ProceedBtn type="submit" disabled={loanStore.isLoading}>
            {loanStore.isLoading ? 'Loading...' : 'Proceed'}
          </ProceedBtn>
        </InfoContentLeft>

        <InfoContentRight>
          <Img src={BankImg} alt="Personal infographics" />
        </InfoContentRight>
      </InfoContent>
    </InfoContainer>
  )
}

export default observer(InstitutionInfoPage)
