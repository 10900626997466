import React from "react"
import BarChart from "./BarChart"
// import { UserData, LoanDate } from "./BarChartData"
import { useQuery } from "react-query"
import { loanChart } from "services/LoanService"
import FullScreenLoader from "../FullScreenLoader"

const ChartsComp = () => {
	const { data, isLoading } = useQuery("chart", loanChart)

	const chartData = {
		labels: data?.data?.map(data => data.lga),
		datasets: [
			{
				label: "No. of Students",
				data: data?.data?.map(data => (data.students ? data.students : 0)),
				backgroundColor: "rgba(137, 27, 124, 0.7)"
			}
		]
	}

	if (isLoading) {
		return <FullScreenLoader />
	}

	return (
		<div style={{ width: "100%" }}>
			<h4>No. of Students by L.G.A</h4>
			<BarChart barChartData={chartData} />
		</div>
	)
}

export default ChartsComp
