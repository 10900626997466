import React from "react"
import { AddPostLink, MainPostDiv } from "./style"
import { Div } from "globalStyles"
import BlogCard from "./Card"
import { BlogPostContainer } from "./Card/style"
import { BASE_URL } from "services"
import http from "services/httpService"
import { useQuery } from "react-query"
import FullScreenLoader from "components/Dashboard/FullScreenLoader"

function BlogsPost() {
	const url = `${BASE_URL}/blog`
	const fetcher = async () => {
		const res = await http.get(url)
		return res?.data?.data
	}

	const { isLoading, data: blogPosts } = useQuery("posts", fetcher)
	if (isLoading) return <FullScreenLoader show={true} />
	return (
		<MainPostDiv>
			<Div display="flex" width="100%" alignI="center" justify="space-between" mt="10px">
				<h3>Blog Posts</h3>
				<AddPostLink to="/blog/create-post">Create Post</AddPostLink>
			</Div>
			<BlogPostContainer>
				{blogPosts?.map(blog => (
					<BlogCard key={blog._id} blog={blog} />
				))}
			</BlogPostContainer>
		</MainPostDiv>
	)
}

export default BlogsPost
