import { configure, makeAutoObservable, observable } from "mobx"

// import jwt_decode from "jwt-decode"
configure({
	enforceActions: "never"
})

class StaffStore {
	isLoading = false
	show = false
	showConfirm = false
	deactivate = false
	selected = {}
	constructor() {
		makeAutoObservable(this, {
			isLoading: observable,
			show: observable,
			showConfirm: observable,
			deactivate: observable,
			selected: observable
		})
	}

	loading() {
		this.isLoading = !this.isLoading
	}
	showConfirmPop() {
		this.showConfirm = !this.showConfirm
	}
	showDeactivatePop() {
		this.deactivate = !this.deactivate
	}
	showDetails() {
		this.show = !this.show
	}
	setSelect(payload) {
		this.selected = payload
	}
}

const staffStore = new StaffStore()

export default staffStore
