import authStore from "mobx/AuthStore"
import toast from "react-hot-toast"

import { BASE_URL } from "services"
import http from "./httpService"

export async function getAllLoans() {
	try {
		const { data } = await http.get(`${BASE_URL}/loans`)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function getApprovedLoans() {
	try {
		const { data } = await http.get(`${BASE_URL}/loans/admin/approved`)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function getRefferedLoans() {
	try {
		const { data } = await http.get(`${BASE_URL}/loans/admin/reffered`)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function getRecentLoans() {
	try {
		const { data } = await http.get(`${BASE_URL}/loans/admin/recent`)
		return data
	} catch (err) {
		if (err && err.response) {
			// err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function getPendingLoans() {
	try {
		const { data } = await http.get(`${BASE_URL}/loans/admin/pending`)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function getRejectedLoans() {
	try {
		const { data } = await http.get(`${BASE_URL}/loans/admin/rejected`)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function getAcceptedLoans() {
	try {
		const { data } = await http.get(`${BASE_URL}/loans/admin/accepted`)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function getDisbursedLoans() {
	try {
		const { data } = await http.get(`${BASE_URL}/loans/admin/disbursed`)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function getLoan(loanId) {
	try {
		const { data } = await http.get(`${BASE_URL}/loans/${loanId}`)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}

export async function updateLoanStatus(payload) {
	authStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/loans/admin/status/update`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		authStore.loading()
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function referLoan(payload) {
	authStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/loans/admin/reffered/`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		authStore.loading()
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function updateLoanAmount(payload) {
	authStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/loans/admin/amount/`, payload)
		// toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		authStore.loading()
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function requestEdit(payload) {
	authStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/loans/admin/edit-request/`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		authStore.loading()
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function rejectLoan(payload) {
	authStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/loans/admin/rejected`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		authStore.loading()
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function loanAnalytics() {
	authStore.loading()
	try {
		const { data } = await http.get(`${BASE_URL}/loans/admin/analytics/board`)
		// toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		authStore.loading()
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function getDrafts() {
	authStore.loading()
	try {
		const { data } = await http.get(`${BASE_URL}/loans/admin/drafts`)
		// toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		authStore.loading()
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function deleteDraft(id) {
	authStore.loading()
	try {
		const { data } = await http.delete(`${BASE_URL}/loans/admin/drafts/${id}`)
		// toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		authStore.loading()
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}

	}
}
export async function sendDraftEmailReminder(payload) {
	authStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/loans/admin/drafts/email-reminder`,payload)
		// toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		authStore.loading()
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}

	}
}
export async function reviewLoanByLPH(id) {
	authStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/loans/${id}/lph-reviewed`)
		// toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		authStore.loading()
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}

	}
}
export async function reviewLoanByLO(id) {
	authStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/loans/${id}/lo-reviewed`)
		// toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		authStore.loading()
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}

	}
}
export async function loanChart() {
	authStore.loading()
	try {
		const { data } = await http.get(`${BASE_URL}/loans/admin/chart/board`)
		// toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		authStore.loading()
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
