import {MsgBody } from "./style"
import LoanAppTable from "pages/Memo/LoanTable"
import MemoTitle from "./MemoTitle"
import ThreadMessage from "./ThreadMessage"


export default function MemoBody({singleMemo,loans,adminRole,selectedLoans,selectLoan}) {
   
    return(
        <MsgBody>
        <MemoTitle
            note={singleMemo.note}
            reciever={singleMemo.to_admin.admin_role}
            sender={singleMemo.admin.admin_role}
        />
        <div style={{marginBottom:"80px"}}>
          
            <div className="pl-8 py-4 border-l border-[rgba(57,26,96,0.5)] my-auto ">
                {singleMemo.threads.map((thread,index) =>{
                    return <ThreadMessage selectedLoans={selectedLoans} thread={thread} singleMemo={singleMemo} isLastMessage={singleMemo.threads.length === index + 1}/>
                })}
            </div>
            {/* <button onClick={() => toggleHandler()}>Close</button> */}
        </div>
        <LoanAppTable selectedLoans={selectedLoans} selectLoan={selectLoan} title="LOAN APPLICATIONS SENT VIA MEMO" tableData={loans} role={adminRole} />
    </MsgBody>
    )
}