import {Flex, Table,Checkbox} from 'antd';
import { ACCEPTED_COLUMNS } from './appColumns';

export default function AcceptedTable({dataSource,selected,setSelected}){
        const selectedLoans = selected.map(s=>s.id)

        const onSelectChange = (item) => {
            if(item){
                if(selectedLoans.includes(item.id)){
                    setSelected(prev=>{
                        return prev.filter(data=>data.id !== item.id)
                    })
                    return;
                }
                setSelected(prev=>[
                    ...prev,
                    item
                ])
            }

        };

        const customColumns = [
            {
                title:"Pick",
                render:(item)=>(
                    <Checkbox
                    checked={selectedLoans.includes(item.id)}
                    onChange={()=>onSelectChange(item)}
                    />
                )
            },
            ...ACCEPTED_COLUMNS,
        ]

        return (
            <Flex gap="middle" vertical>
                <Table columns={customColumns} dataSource={dataSource} />
            </Flex>
        );
};
