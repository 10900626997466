import { Link } from "react-router-dom";
import { DASHBOARD_MEMO_DETAILS_ROUTE } from "routes";
import { RemActionBtn } from "./style";

export const STAFF_DATA = [
  {
    id: 1,
    first_name: 'Aaron',
    last_name: 'Agonowei',
    email: 'aasquar@gmail.com',
    role: 'Loan Officer',
    action: <RemActionBtn>Remove</RemActionBtn>,
    app_status: 'Approved',
    link: <Link to={DASHBOARD_MEMO_DETAILS_ROUTE}>View</Link>,
    msg_status: 'read',

  },
  {
    id: 2,
    first_name: 'Outin',
    last_name: 'Deke',
    email: 'outowei@gmail.com',
    role: 'Loan Officer',
    action: <RemActionBtn>Remove</RemActionBtn>,
    app_status: 'Approved',
    link: <Link to={DASHBOARD_MEMO_DETAILS_ROUTE}>View</Link>,
    msg_status: 'unread',
  },
  {
    id: 3,
    first_name: 'Sam',
    last_name: 'Ekele',
    email: 'sosor@gmail.com',
    role: 'Loan Processing Officer',
    action: <RemActionBtn>Remove</RemActionBtn>,
    app_status: 'Rejected',
    link: <Link to={DASHBOARD_MEMO_DETAILS_ROUTE}>View</Link>,
    msg_status: 'read',
  },
  {
    id: 4,
    first_name: 'Festus',
    last_name: 'Kurobo',
    email: 'fesman@gmail.com',
    role: 'Auditor',
    action: <RemActionBtn>Remove</RemActionBtn>,
    app_status: 'Approved',
    link: <Link to={DASHBOARD_MEMO_DETAILS_ROUTE}>View</Link>,
    msg_status: 'read',
  },
  {
    id: 5,
    first_name: 'Samedi',
    last_name: 'Samul',
    email: 'ssjohnmike@gmail.com',
    role: 'Loan Officer',
    action: <RemActionBtn>Remove</RemActionBtn>,
    app_status: 'Rejected',
    link: <Link to={DASHBOARD_MEMO_DETAILS_ROUTE}>View</Link>,
    msg_status: 'unread',
  },
]
