import axios from "axios"
import authStore from "mobx/AuthStore"
import { toast } from "react-hot-toast"
import { useQuery } from "react-query"
import { BASE_URL } from "services"
import http from "./httpService"
import checkboxStore from "mobx/CheckboxStore"

const fetchAdmins = () => {
	return axios.get(`${BASE_URL}/admin/fetch_members`)
}

export const useAdminData = (onSuccess, onError) => {
	return useQuery("admins", fetchAdmins, {
		onSuccess,
		onError
	})
}

export async function adminInvite(payload) {
	authStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/admin/auth/invite`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}

//Memo service
export async function memoService(payload) {
	authStore.loading()
	try {
		if (!payload.memoId && checkboxStore.selectedValues.length <= 0) {
			authStore.loading()
		}
		if (!payload.memoId && checkboxStore.selectedValues.length <= 0) return toast.error("Please select atleast one approved loan to send a memo")

		if (!payload.memoId && checkboxStore.selectedValues.length >= 1) {
			payload.selectedLoans = checkboxStore.selectedValues
		}
		const { data } = await http.post(`${BASE_URL}/memos/create`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function createMemo(payload) {
	authStore.loading()
	try {
		if (!payload.memoId && checkboxStore.selectedValues.length <= 0) {
			authStore.loading()
		}
		if (!payload.memoId && checkboxStore.selectedValues.length <= 0) return toast.error("Please select atleast one loan to send a memo")

		if (!payload.memoId && checkboxStore.selectedValues.length >= 1) {
			payload.selectedLoans = checkboxStore.selectedValues
		}
		const { data } = await http.post(`${BASE_URL}/admin-memos/`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addRejectMemoThread(payload) {
	authStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/admin-memos/${payload.memo_id}/rejected`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function disburseLoans(payload) {
	authStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/loans/admin/disbursed`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addRaiseMemoThread(payload) {
	authStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/admin-memos/${payload.memo_id}/raised`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addForwardMemoThread(payload) {
	authStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/admin-memos/${payload.memo_id}/forwarded`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addESApprovedMemoThread(payload) {
	authStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/admin-memos/executive/${payload.memo_id}/accepted`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addESRejectedMemoThread(payload) {
	authStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/admin-memos/executive/${payload.memo_id}/rejected`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function addAcceptMemoThread(payload) {
	authStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/admin-memos/${payload.memo_id}/accepted`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}

export async function memoServiceOthers(payload) {
	authStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/memos/create/others`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function memoServiceEs(payload) {
	authStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/memos/create/es`, payload)
		toast.success(data.message)
		authStore.loading()
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}
