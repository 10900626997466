import styled from 'styled-components'
import Colors from 'utils/colors'

export const AdminCon = styled.div`
  width: calc(100% - 65px);
  display: flex;
  flex-direction: column;
  /* background-color: yellow; */
  padding: 20px 30px;
`
export const AdminFirstDiv = styled.div`
  display: flex;
  flex-direction: column;
`
export const AdminHead = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: green; */
`

export const AdminBody = styled.div`
  /* background-color: red; */
  /* margin-left: 30px; */
`
export const AdminBodyInput = styled.div`
  display: flex;
  gap: 10px;
  /* margin-left: 30px; */
`
export const InputHold = styled.div`
  width: 30%;
  /* background-color: yellow; */
  /* margin-left: 30px; */
`
export const BodyInputRight = styled.div`
  display: flex;
  /* margin-left: 30px; */
`

export const ActionHeader = styled.h1``
export const ActionBtn = styled.button`
  width: 100px;
  height: 30px;
  background: ${Colors.primary};
  color: white;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-right: 10px;
`
export const StaffSubBtn = styled.button`
  width: 120px;
  height: 40px;
  margin-top: 20px;
  border-radius: 7px;
  border: none;
  outline: none;
  cursor: pointer;
  background: ${Colors.primary};
  color: white;
`

export const CloseBtn = styled.button`
  background: red;
  color: white;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  outline: none;
  /* top: 10px; */
  /* right: 10px; */
  /* margin-right: 30px; */
  padding: 5px 7px;
`
export const AdminOverlay = styled.div`
  display: flex;
  width: calc(100% - 230px);
  margin-left: 230px;
  top: 0;
  left: 0;
  /* left: 230px; */
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 800;
  /* background: rgba(49, 49, 49, 0.8); */
  background: white;
  /* overflow-y: scroll; */
`

export const OverlayDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 50px;
`
export const AdminSeconDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const RemActionBtn = styled.button`
  background: none;
  border: none;
  outline: none;
  color: blue;
  cursor: pointer;
`
