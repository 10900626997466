import { AppLoanIcon, IncompleteIcon, LoanDueIcon, PendLoanIcon, RejLoanIcon, TotalAppIcon, TotalDisIcon, TotalRepIcon } from "utils/assets"
// import or from "assets/icons/online_riders.svg";
// import pr from "assets/icons/pend_rides.svg";
// import ofliner from "assets/icons/offline_riders.svg";
// import prides from "assets/icons/pend_rides.svg";
// import ttrips from "assets/icons/total_trips.svg";
// import comtrips from "assets/icons/completed_trips.svg";
// import cantrips from "assets/icons/cancelled_trips.svg";
// import strips from "assets/icons/shedule_trips.svg";
// import otrips from "assets/icons/ongoing_trips.svg";
// import revenue from "assets/icons/revenue.svg";

const cardData = [
	{
		tr: {
			icon: TotalAppIcon,
			title: "Total Applications"
		}
	},
	{
		or: {
			icon: AppLoanIcon,
			title: "Approved Loans"
		}
	},
	{
		ofliner: {
			icon: PendLoanIcon,
			title: "Pending Loans"
		}
	},
	{
		prides: {
			icon: RejLoanIcon,
			title: "Rejected Applications"
		}
	},
	{
		tp: {
			icon: TotalDisIcon,
			title: "Total Amount Disbursed"
		}
	},
	{
		cant: {
			icon: TotalRepIcon,
			title: "Total Amount Repayed"
		}
	},
	{
		ct: {
			icon: LoanDueIcon,
			title: "Loans Due For Repayment"
		}
	},
	{
		st: {
			icon: IncompleteIcon,
			title: "Incomplete Applictaions"
		}
	}
]

export default cardData
