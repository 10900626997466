import { SlideInPopUp } from "components/Dashboard/FullScreenLoader/style"
import styled from "styled-components"
import Colors from "utils/colors"
import media from "utils/media"

export const TransTableBody = styled.table`
	width: 100%;
	border-collapse: collapse;
	background: #ffffff;
	border-bottom: 1px solid #e4e7eb;
	margin-bottom: 2rem;
	/* width: 100%; */
	/* overflow-x: scroll; */
	td {
		text-align: left;
		padding: 15px 10px;
		font-size: 14px;
		border-bottom: 1px solid #e4e7eb;
	}
	th {
		padding: 20px 10px;
		text-align: left;
		text-transform: capitalize;
		color: ${Colors.white};
		background-color: ${Colors.primary};
		font-size: 14px;
	}

	th:first-child {
		border-top-left-radius: 8px;
	}
	th:last-child {
		border-top-right-radius: 8px;
	}
	td {
		color: ${Colors.black};
		font-size: 14px;
	}
	tr:nth-child(even) {
		background: #ecf4f7;
	}

	${media.mobile`
  width: 100%;
  td, th {
      white-space: nowrap;
    }
   
    `}
	.bottom-header {
		padding: 10px 10px;
		text-align: left;
		text-transform: capitalize;
		color: ${Colors.white};
		background-color: ${Colors.primary};
		font-size: 14px;
	}
`

export const TransTextHeader = styled.th`
	text-align: left;
	padding: 8px;
	border-bottom: 2px solid red;
	td {
		text-align: left;
		padding: 8px;
		color: ${Colors.white};
	}
`
export const TransTableContainer = styled.div`
	width: calc(100% - 10px);
	padding: 0px 5px;
`
export const TransTableContent = styled.div`
	width: 100%;

	box-sizing: border-box;
	${media.mobile`
	border:none;
	overflow-x: scroll;
	::-webkit-scrollbar {
		/* width: 0; */
		background: transparent;
	}
	::-webkit-scrollbar-thumb {
		background: transparent;
	}
	`}
`

export const TablePopupDiv = styled.div`
	width: 160px;
	height: 150px;
	left: 83%;
	/* height: 200px; */
	display: flex;
	padding-top: 30px;
	padding-bottom: 30px;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	color: ${Colors.black};
	margin-top: 20px;
	position: absolute;
	background: #ecf4f7;
	border-radius: 8px;
	z-index: 0;
	${media.mobile`
    width:160px;
  left: 40%;
  /* width: 90%; */
  `}
`

export const DownCsvBtn = styled.button`
	height: 35px;
	/* padding: 0px 10px; */
	outline: none;
	border: none;
	color: ${Colors.white};
	background: #ff7900;
	border-radius: 5px;
	cursor: pointer;
`

export const TableDetailTop = styled.div`
	width: calc(100% - 10px);
	display: flex;
	padding: 0px 5px;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 7px;
`
export const Loandetails = styled.div`
	height: 100%;
	top: 0%;
	left: 0%;
	right: 0%;
	bottom: 0%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${({ show }) => (show ? "rgba(0, 0, 0, 0.5)" : "#f90")};
	position: fixed;
	transition: all 0.5s ease-out;
	animation-name: ${SlideInPopUp};
	animation-duration: 0.5s;
	z-index: 1005;
`
