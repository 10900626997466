// import FullScreenLoader from "components/FullScreenLoader"
import { FlutterWaveButton, closePaymentModal } from "flutterwave-react-v3"
import { Img } from "globalStyles"
import { observer } from "mobx-react-lite"
import authStore from "mobx/AuthStore"
import loanStore from "mobx/LoanStore"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { DASHBOARD_APPLY_ROUTE } from "routes"
import { BASE_URL } from "services"
import http from "services/httpService"
import { verifyPayment } from "services/ReappLoanService"
import useSWR from "swr"
import { CreditCardImg } from "utils/assets"
import { AppFeeCon, AppFeeHeader, AppFeeImg, AppFeePara, AppFeeParaDiv, ProceedBtn } from "./style"
import  CONFIG  from "../../../config"
import FullScreenLoader from "../FullScreenLoader"

const AppFee = ({ id }) => {
	const [onFlutterwavePaymentSuccess, setOnFlutterwavePaymentSuccess] = useState(false)
	const navigate = useNavigate()

	const url = `${BASE_URL}/users/who_i_am`
	const fetcher = async () => {
		authStore.loggedInStatus()
		const res = await http.get(url)
		return res?.data?.data
	}

	const { data: userProfile } = useSWR(url, fetcher)

	const config = {
		public_key: CONFIG.FLUTTERWAVE_PUBLIC_KEY, //"FLWPUBK_TEST-e6194fcdae04fbd8bf253480c634cd90-X",
		tx_ref: Date.now(),
		amount:  CONFIG.APPLICATION_FEE, //2000,
		currency: "NGN",
		payment_options: "card, mobilemoney, ussd",
		customer: {
			email: userProfile?.email,
			phonenumber: userProfile?.phone,
			name: `${userProfile?.first_name} ${userProfile?.surname}`
		},
		customizations: {
			title: "Proceed to Make Payment",
			description: "Payment for loan application fee",
			logo: "https://res.cloudinary.com/dxsksma2c/image/upload/v1670423940/logo_mgwsvi.svg"
		}
	}

	const fwConfig = {
		...config,
		// text: "Pay Now",
		className: "flutter-btn",
		callback: async response => {
			setOnFlutterwavePaymentSuccess(true)
			if (response.status === "successful") {
				const payment = await verifyPayment({
					reference: `${response?.tx_ref}`,
					id
				})

				if (!payment.error) {
					navigate(`${DASHBOARD_APPLY_ROUTE}`)
				}
				// console.log(response, payment)
			}
			closePaymentModal() // this will close the modal programmatically
		},
		onClose: () => {
			setOnFlutterwavePaymentSuccess(false)
		}
	}
	useEffect(() => {
		handlePaystackPayment()
		// eslint-disable-next-line
	}, [onFlutterwavePaymentSuccess])

	const handlePaystackPayment = async () => {
		if (onFlutterwavePaymentSuccess) {
			// const param = {
			// 	customerEmail: "",
			// 	amountDue: 2500
			// }
			// const response = await makePayment(param)
			// console.log(response)
			// if (!response.error) {
			// }
		}
	}
	if (!userProfile) return <FullScreenLoader show={true} />
	if (loanStore.isLoading_pay) return <FullScreenLoader show={true} />
	return (
		<>
			{loanStore.isLoading && <FullScreenLoader show={true} />}
			<AppFeeCon>
				<AppFeeHeader>Processing Fee</AppFeeHeader>
				<AppFeeParaDiv>
					<AppFeePara>Kindly note that there is no application fee attached to the BYSHELB loan. However, you are required to pay a processing fee of ₦2,000.00.</AppFeePara>
				</AppFeeParaDiv>
				<AppFeeImg>
					<Img src={CreditCardImg} alt="credit carrd" />
				</AppFeeImg>
				<FlutterWaveButton {...fwConfig}>
					<ProceedBtn>Pay Now</ProceedBtn>
				</FlutterWaveButton>
			</AppFeeCon>
		</>
	)
}

export default observer(AppFee)
