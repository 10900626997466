import { configure, makeAutoObservable, observable } from "mobx"

configure({
	enforceActions: "never"
})

class LoanStore {
	isLoading = false
	isLoading_bvn = false
	userId = ""
	currentStepUnder = ""
	userEmail = ""
	user_key = ""
	currentUser = {}
	constructor() {
		makeAutoObservable(this, {
			isAuth: observable,
			isLoading: observable,
			resendingOtp: observable,
			currentUser: observable,
			userId: observable,
			currentStep: observable,
			user_key: observable,
		})
	}

	loading() {
		this.isLoading = !this.isLoading
	}

	loadingBvn() {
		this.isLoading_bvn = !this.isLoading_bvn
	}

	getUserToken = () => {
		const token = sessionStorage.getItem("token")

		if (!token) return null
		return token
	}

	setCurrentStepUnder(payload) {
		this.currentStepUnder = localStorage.setItem("progress-state", payload)
		this.getCurrentStepUnder()
	}

	getCurrentStepUnder() {
		const status = localStorage.getItem("progress-state")
		this.currentStepUnder = status ? status : ""
	}

	setUserEmail(payload) {
		localStorage.setItem("auth-email", payload)
		this.getUserEmail()
	}

	getUserEmail() {
		const email = localStorage.getItem("auth-email")
		this.userEmail = email ? email : ""
	}

	setUserKey(payload) {
		localStorage.setItem("user_key", payload)
		this.user_key = payload;
	}

	getUserKey = () => {
		const user_key = localStorage.getItem("user_key");
		return this.user_key = user_key;
	}
}

const loanStore = new LoanStore()

export default loanStore
