import React from "react"
import TextInput from "components/TextInput"
import { useFormik } from "formik"
import * as Yup from "yup"
import TextAreaBlog from "components/TextAreaBlog/TextAreaBlog"
import { AddBlogDiv, AddPostButton, CoverImageButton, CoverImageDiv } from "./style"
import { useRef } from "react"
import blogStore from "mobx/BlogStore"
import { observer } from "mobx-react-lite"
import { createBlogPost } from "services/BlogService"
import { useState } from "react"
import { toast } from "react-hot-toast"
import { useQuill } from "react-quilljs"
import { Div } from "globalStyles"
import http from "services/httpService"
import { BASE_URL } from "services"
import { useQuery } from "react-query"
import FullScreenLoader from "components/Dashboard/FullScreenLoader"
import "quill/dist/quill.snow.css"
import { useNavigate } from "react-router-dom"
import { DASHBOARD_BLOG_ROUTE } from "routes"

function AddPost() {
	const { quill, quillRef } = useQuill()
	const [coverImage, setCoverImage] = useState(null)
	const [slug, setSlug] = useState("")
	const coverImageRef = useRef()
	const navigate = useNavigate()

	const url = `${BASE_URL}/admin/who_i_am`
	const fetcher = async () => {
		const res = await http.get(url)
		return res?.data?.data
	}

	const { isLoading, data: userProfile } = useQuery("profile", fetcher)
	const formik = useFormik({
		enableReinitialize: false,
		initialValues: {
			title: "",
			readTime: "",
			excerpt: ""
		},
		onSubmit: async (data, helpers) => {
			if (!coverImage) return toast.error("Please upload cover image")
			if (!slug) return toast.error("Write post data is required")
			if (slug.length < 100) return toast.error("Please write up to 100 characters")
			const formData = new FormData()
			formData.append("title", data.title)
			formData.append("excerpt", data.excerpt)
			formData.append("readTime", data.readTime)
			formData.append("slug", slug)
			formData.set("coverImage", coverImage)
			formData.append("admin_id", userProfile._id ? userProfile._id : "")

			const res = await createBlogPost(formData)
			if (!res.error) {
				helpers.resetForm()
				setSlug("")
				setCoverImage(null)
				navigate(DASHBOARD_BLOG_ROUTE)
			}
		},
		validationSchema: Yup.object({
			title: Yup.string().max(120).required().label("Title"),
			readTime: Yup.number().positive().required().label("Read Time"),
			excerpt: Yup.string().max(120).required().label("Excerpt")
		})
	})
	// console.log("first", userProfile)
	const handlePickcoverImage = () => {
		coverImageRef.current.click()
	}

	const handleChangeCoverImage = e => {
		if (e.target.files.length > 0) {
			setCoverImage(e.target.files[0])
		} else {
			setCoverImage(null)
		}
	}
	React.useEffect(() => {
		window.scrollTo(0, 0)
		if (quill) {
			quill.on("text-change", (delta, oldDelta, source) => {
				setSlug(quill.root.innerHTML) // Get innerHTML using quill
			})
		}
	}, [quill])
	if (isLoading) return <FullScreenLoader show={true} />
	return (
		<AddBlogDiv onSubmit={formik.handleSubmit}>
			<Div width="100%" display="flex" justify="center">
				<h2>CREATE BLOG POST</h2>
			</Div>
			<CoverImageButton type="button" onClick={handlePickcoverImage}>
				Upload Cover Image
			</CoverImageButton>

			<TextInput
				label="Title"
				name="title"
				onBlur={formik.handleBlur}
				type="text"
				onChange={formik.handleChange}
				value={formik.values.title}
				error={formik.errors.title}
				visible={formik.touched.title}
				placeholder="Your post title here"
			/>
			<TextAreaBlog
				label="Excerpt"
				name="excerpt"
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
				value={formik.values.excerpt}
				error={formik.errors.excerpt}
				visible={formik.touched.excerpt}
			/>
			<TextInput
				type="number"
				label="Read Time"
				name="readTime"
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
				value={formik.values.readTime}
				error={formik.errors.readTime}
				visible={formik.touched.readTime}
				placeholder="example, 4"
			/>
			<CoverImageDiv>
				<div ref={quillRef} />
			</CoverImageDiv>
			<input style={{ display: "none" }} type="file" ref={coverImageRef} onChange={handleChangeCoverImage} />
			<AddPostButton type="submit">{blogStore.isLoading ? "Please wait..." : "Submit"}</AddPostButton>
		</AddBlogDiv>
	)
}

export default observer(AddPost)
