import { AuthHeader } from "components/Auth"
import { AuthCard, Authcontainer, AuthContent } from "components/Auth/style"
import TextInput from "components/TextInput"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Img } from "globalStyles"
import authStore from "mobx/AuthStore"
import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { acceptInvite } from "services/AuthService"
import { AppLogo2 } from "utils/assets"
import { LoginContainer, LoginDiv, LoginFormContainer } from "../style"
import { Authbtn } from "./style"
import { observer } from "mobx-react-lite"
import { LOGIN_ROUTE } from "routes"

function CreateAccComp() {
	const params = useParams()
	const navigate = useNavigate()

	const formik = useFormik({
		enableReinitialize: false,
		initialValues: {
			first_name: "",
			surname: "",
			phone: "",
			password: ""
		},
		onSubmit: async (data, helpers) => {
			const res = await acceptInvite(data, params?.token)
			if (!res.error) {
				helpers.resetForm()
				navigate(LOGIN_ROUTE)
			}
		},
		validationSchema: Yup.object({
			first_name: Yup.string().max(50).required().label("First Name"),
			surname: Yup.string().max(50).required().label("Surname"),
			phone: Yup.string().min(11).max(14).required().label("Phone"),
			password: Yup.string().min(8).required("Password is required.").label("Password")
		})
	})

	const handleSubmit = async(e)=>{
		e.preventDefault()
		formik.submitForm()
	}
	return (
		<Authcontainer>
			<AuthContent>
				<Img src={AppLogo2} alt="BSL" />
				<LoginContainer>
					<LoginDiv onSubmit={handleSubmit}>
						<AuthCard>
							<LoginFormContainer>
								<AuthHeader text="Verify your Account" />
								<TextInput
									label="Surname"
									name="surname"
									id="surname"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.surname}
									error={formik.errors.surname}
									visible={formik.touched.surname}
									placeholder="Surname"
								/>
								<TextInput
									label="First Name"
									name="first_name"
									id="first_name"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.first_name}
									error={formik.errors.first_name}
									visible={formik.touched.first_name}
									placeholder="First Name"
									mt="0.5rem"
								/>
								<TextInput
									label="Phone"
									name="phone"
									type="phone"
									id="phone"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.z}
									error={formik.errors.email}
									visible={formik.touched.email}
									placeholder="Phone Number"
									mt="0.5rem"
								/>
								<TextInput
									label="Password"
									name="password"
									type="password"
									id="password"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.password}
									error={formik.errors.password}
									visible={formik.touched.password}
									placeholder="Password"
									mt="0.5rem"
								/>
								<Authbtn type="submit" disabled={authStore.isLoading ? true : false} title={authStore.isLoading ? "Loading..." : "Submit"}>
									{" "}
									Continue{" "}
								</Authbtn>
							</LoginFormContainer>
						</AuthCard>
					</LoginDiv>
				</LoginContainer>
			</AuthContent>
		</Authcontainer>
	)
}

export default observer(CreateAccComp)
