import EnqDetailsComp from 'components/Dashboard/Enquiry/EnqDetailsComp'
import MainLayout from 'layout/MainLayout'
import React from 'react'

const EnqDetailsPage = () => {
  return (
    <MainLayout>
      <EnqDetailsComp />
    </MainLayout>
  )
}

export default EnqDetailsPage
