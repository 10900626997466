import { message, Modal, Spin } from 'antd';
import {convertArrayToCSV} from 'convert-array-to-csv'
import dayjs from 'dayjs';
import { useState } from 'react';
import { disburseLoans } from 'services/AdminUserService';

function getUpperCase(value){
    if(value) return value.toUpperCase();
    return "";
}
function getCSVData(loanData){
    return loanData.map((loan, index)=>{
        return {
			"S/N":index + 1,
			"NAME": `${loan?.bank_account?.account_name.toUpperCase()}`,
			"PHONE":loan?.applicant?.phone,
			"AGE":loan?.applicant?.age,
			"SEX":loan?.applicant?.gender,
			"LGA":loan?.applicant?.lga,
			"INSTITUTION":loan?.institution?.name ? loan?.institution?.name?.toUpperCase():"",
			"LEVEL": loan?.institution?.current_study_year ? loan?.institution?.current_study_year?.toUpperCase() : "",
			"COURSE":loan?.institution?.course ? loan?.institution?.course?.toUpperCase() : "",
			"AMOUNT":loan?.amount ? loan?.amount.toLocaleString() : "0.00",
			"INSURANCE": `${(0.01 * loan?.amount).toLocaleString()}.00`,
			"NET AMOUNT":(loan?.amount - (0.01 * loan?.amount)).toLocaleString(),
			"BANK":loan?.bank_account?.bank_name ? loan?.bank_account?.bank_name?.toUpperCase() : "",
			"BANK_CODE":loan?.bank_account?.bank_code ? loan?.bank_account?.bank_code.toString() : "",
			"ACCOUNT NUMBER":`'${loan?.bank_account?.account_number}`,
			"FIRST GUARANTOR":`${loan?.guarantor_one?.surname?.toUpperCase()} ${loan?.guarantor_one?.first_name?.toUpperCase()} ${getUpperCase(loan?.guarantor_one?.middle_name)}`,
			"PHONE (FIRST GUARANTOR)":`'${loan?.guarantor_one?.phone} `,
			"WORK PLACE (FIRST_GUARANTOR)":`${loan?.guarantor_one?.work_place}`,
			"SECOND GUARANTOR":`${loan?.guarantor_two?.surname?.toUpperCase()} ${loan?.guarantor_two?.first_name?.toUpperCase()} ${getUpperCase(loan?.guarantor_two?.middle_name)}`,
			"PHONE (SECOND GUARANTOR)":`'${loan?.guarantor_two?.phone} `,
			"WORK PLACE (SECOND GUARANTOR)":`${loan?.guarantor_two?.work_place}`,
			"DATE DISBURSED":loan?.disburse_date ? `${dayjs(loan?.disburse_date).format("MMM D, YYYY")}` : `${dayjs(loan?.updated_at).format("MMM D, YYYY")}`,
			"MONTHLY REPAYMENT AMOUNT":`${(loan?.monthly_repayment_amount).toLocaleString()}`,
			"MONTHLY REPAYMENT STARTS": loan?.repayment_period_starts ? `${dayjs(loan?.repayment_period_starts).format("MMM D, YYYY")}` : ""
        }
    })
}


export default function DownloadCSVBtn({data,fileName="accepted_loans"}){
    const [loading,setLoading] = useState(false);
    const downloadCSV = async()=>{
        if(data.length === 0){
            message.warning("Please select at least one loan from the list")
            return
        }
        try {
            setLoading(true);

            const csvData =  getCSVData(data)
            
            const csv = convertArrayToCSV(csvData);

            const blob = new Blob([csv], { type: "text/plain" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");

            link.download = `${fileName}.csv`;
            link.href = url;
            link.click();

            URL.revokeObjectURL(url)
        } catch (error) {
            console.log(error);
        }
        finally{
            setLoading(false);
        }
    }
    return (
        <button onClick={downloadCSV} disabled={loading} className="bg-gray-200 text-[#333333] border disabled:opacity-60 border-gray-500 px-4 flex gap-2 font-medium rounded-md py-2">
           {loading?<Spin/>:(
             <>
             <span><i className="bi bi-download"></i></span>
             <span>Download CSV ({data.length})</span>
             </>
           )}
        </button>
    )
}
export function DisburseBtn({data}){
    const [loading,setLoading] = useState(false);
    const [open,setOpen] = useState(false)

    const selectedLoans = data.map(item => item.id)

    const closeModal = ()=>{
        setOpen(false)
    }
    const openModal = ()=>{
        if(data.length === 0){
            message.warning("Please select atleast one loan for disbursement")
            return;
        }
        setOpen(true)
    }
    const handleSubmit=async()=>{
        if(data.length === 0){
            message.warning("Please select atleast one loan for disbursement")
            return;
        }
        setLoading(true)
        const res = await disburseLoans({selectedLoans})
        setOpen(false)
        if(!res.error){
            message.success("Loans added as disbursed Loans successfully")
        }
        closeModal()
        window.location.reload()

    }
    return (
        <>
            <Modal open={open} footer={null} maskClosable={false} closeIcon={null} centered>
               <div className="text-center">
                <h3 className="text-2xl font-semibold text-primary py-2 border-b">Confirm Disbursement</h3>
                <p className="text-lg my-6">Are you sure you want to confirm these {data.length} name(s) as disbursed loans. This action cannot be reversed</p>
                <footer className="flex justify-center items-center gap-4 w-full">
                    <button onClick={closeModal} className="text-lg font-medium bg-red-200 rounded-md text-red-800 px-8 py-2">Cancel</button>
                    <button disabled={loading} onClick={handleSubmit} className="text-lg disabled:opacity-50 disabled:cursor-not-allowed font-medium px-8 py-2 rounded-md bg-primary-200 text-primary-800">
                        {loading ?(
                            <Spin/>
                        ) : "Continue"}
                    </button>
                    
                </footer>
               </div>
            </Modal>

            <button onClick={openModal} className="bg-primary-100 border border-primary-200 text-primary px-4 flex gap-2 font-medium rounded-md py-2">
                <span>Disburse Selected ({data.length})</span>
            </button>
        </>
        
    )
}