import authStore from "mobx/AuthStore"
import toast from "react-hot-toast"

import { BASE_URL } from "services"
import http from "./httpService"

export async function loginUser(user) {
	authStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/admin/auth/login`, user)
		authStore.loading()
		sessionStorage.setItem("token", data?.data?.authorization)
		return data
	} catch (err) {
		authStore.loading()
		if (err && err.response) {
			err?.response?.data?.error && err.response.data === "pending" && authStore.setUserEmail(user?.email)
			err?.response?.data?.error && err.response.data === "pending" && authStore.setCurrentStep("verify")
			err?.response?.data?.error && toast.error(err.response.data.message)
			return err.response.data
		} else {
			toast.error("Network Error")
			return err
		}
	}
}

export async function sendEmail(payload) {
	authStore.getInitialAuth()
	authStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/auth/register`, payload)
		toast.success(data.message)
		authStore.loading()
		authStore.setCurrentStep("verify")
		authStore.setUserEmail(data?.data?.email)
		authStore.setInitialAuth(data?.data?.authorization)

		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function verifyOtpEmail(payload) {
	authStore.loading()
	authStore.getInitialAuth()
	try {
		const { data } = await http.post(`${BASE_URL}/auth/verify_otp`, payload)
		toast.success(data.message)
		authStore.loading()
		authStore.setCurrentStep("")
		authStore.setUserEmail("")
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}

export async function resendOtpEnd(payload) {
	authStore.resending_otp()
	try {
		const { data } = await http.post(`${BASE_URL}/auth/resend_verification_email`, payload)
		toast.success(data.message)
		authStore.resending_otp()

		authStore.setCurrentStep("verify")

		return data
	} catch (err) {
		if (err && err.response) {
			authStore.resending_otp()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.resending_otp()
			toast.error("Network Error")
		}
	}
}

export async function signupUser(payload) {
	authStore.loading()

	try {
		const { data } = await http.put(`${BASE_URL}/auth/create_account`, payload)
		authStore.loading()
		toast.success(data.message)
		authStore.setCurrentStep("setpass")
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}

export async function setPassword(payload) {
	authStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/auth/set_password`, payload)

		authStore.loading()
		authStore.setCurrentStep("")
		authStore.setUserEmail("")
		authStore.setInitialAuth("")
		localStorage.removeItem("AUTH_TOKEN")
		localStorage.removeItem("auth-state")
		toast.success(data.message)
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}

export async function forgotPasswordEndPoint(payload) {
	authStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/auth/forgot_password`, payload)
		authStore.loading()
		authStore.setUserEmail(payload.email)
		toast.success(data.message)
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function resetPasswordEndpoint(payload) {
	authStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/auth/reset_password`, payload)
		authStore.loading()
		authStore.setUserEmail("")
		toast.success(data.message)
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}

export async function acceptInvite(payload, token) {
	authStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/invite/auth/accept_invite/${token}`, payload)
		authStore.loading()
		toast.success(data.message)
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}

export async function deactivateStaff(payload) {
	authStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/admin/deactivate_staff`, payload)
		authStore.loading()
		toast.success(data.message)
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}
export async function activateStaff(payload) {
	authStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/admin/activate_staff`, payload)
		authStore.loading()
		toast.success(data.message)
		return data
	} catch (err) {
		if (err && err.response) {
			authStore.loading()
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			authStore.loading()
			toast.error("Network Error")
		}
	}
}
