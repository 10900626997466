import styled from "styled-components/macro"
import Colors from "utils/colors"
import media from "utils/media"

export const CreateContainer = styled.div`
	width: 70%;
`
export const CreateAccountButton = styled.button`
	width: 100%;
	height: 35px;
	border: none;
	outline: none;
	border-radius: 10px;
	background: ${Colors.gradient};
	color: ${Colors.white};
	padding: 5px 20px;
	margin-top: 20px;
	cursor: pointer;
`
export const SetPasswordButton = styled.button`
	width: 40%;
	height: 35px;
	border: none;
	outline: none;
	border-radius: 10px;
	background: ${Colors.gradient};
	color: ${Colors.white};
	padding: 5px 20px;
	margin-top: 20px;
	cursor: pointer;
`
export const AuthContentcreate = styled.div`
	width: 100%;
	height: 100%;

	background-repeat: no-repeat;
	background-position: -14% 130%;
	background-size: 400px 400px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	${media.mobile`
	background-position: -40.5% 110%;
	background-size: 200px 200px;
	`}
`
export const AuthSectionUnique = styled.div`
	width: 100%;
	background: ${Colors.gradient};
`
export const EmailCreateText = styled.p`
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 200;
	font-size: 9px;
	line-height: 11px;
	letter-spacing: 0.005em;
	text-transform: capitalize;
	color: ${Colors.black};
`

export const VerifyResendBtn = styled.button`
	background: transparent;
	outline: none;
	border: none;
	font-weight: 700;
	margin-left: -10px;
	color: ${Colors.primaryLight};
	width: 100px;
	margin-top: 10px;
	cursor: pointer;
`

export const VerifyExpires = styled.p`
	font-size: 12px;
	color: ${Colors.primaryLight};
	margin-top: 20px;
`

export const Authbtn = styled.button`
	width: 30%;
	height: 35px;
	border: none;
	outline: none;
	border-radius: 10px;
	background: ${Colors.gradient};
	color: ${Colors.white};
	padding: 5px 20px;
	margin-top: 20px;
	cursor: pointer;
	${media.mobile`
	width: 50%;
	height: 35px;
	`}
`