import TextInput from 'components/TextInput'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Img } from 'globalStyles'
import React from 'react'
import { GuarantorImage } from 'utils/assets'
import {
  InfoContainer,
  InfoContent,
  InfoContentLeft,
  InfoContentRight,
  InfoText,
  InputDiv,
  InputWrap,
  ProceedBtn,
} from './style'

import { observer } from 'mobx-react-lite'
import { addLoanGuarantorOne } from 'services/ReappLoanService'

import loanStore from 'mobx/LoanStore'
import { useSearchParams } from 'react-router-dom'

const GuarantorForm = ({ loanType = '', id, user, mutate }) => {
  const [searchParams] = useSearchParams()
  const qinfo = searchParams.get('q')
  const userId = qinfo.split(' ')[0]
  const loanId = qinfo.split(' ')[1]
  const user_key = { user_key: userId }

  const formik = useFormik({
    initialValues: {
      first_name: '',
      middle_name: '',
      surname: '',
      phone: '',
      email: '',
      work_place: '',
    },

    onSubmit: async (data, helpers) => {
      if (loanType === 'undergraduate') {
        const payload = { ...data }
        // payload.id = id
        payload.id = loanId
        payload.user_key = userId
        const underResponse = await addLoanGuarantorOne(payload)
        if (!underResponse.error) {
          mutate()
        }
      }
      if (loanType === 'postgraduate') {
        const payload = { ...data }
        // payload.id = id
		payload.id = loanId
        payload.user_key = userId
        const underResponse = await addLoanGuarantorOne(payload)
        if (!underResponse.error) {
          mutate()
        }
      }
      if (loanType === 'professional') {
        const payload = { ...data }
        // payload.id = id
		payload.id = loanId
        payload.user_key = userId
        const underResponse = await addLoanGuarantorOne(payload)
        if (!underResponse.error) {
          mutate()
        }
      }
    },

    validationSchema: Yup.object({
      first_name: Yup.string().max(50).required().label('First Name'),
      middle_name: Yup.string().max(50).label('Middle Name'),
      surname: Yup.string().max(50).required().label('Surname'),
      phone: Yup.string().max(100).required().label('Phone No.'),
      email: Yup.string().max(255).required().label('Email'),
      work_place: Yup.string().max(255).required().label('Place of Work'),
    }),
  })

  return (
    <InfoContainer>
      <InfoContent>
        <InfoContentLeft onSubmit={formik.handleSubmit}>
          <InfoText>Guarantor's Details (ONE)</InfoText>

          <InputDiv>
            <InputWrap>
              <TextInput
                astheric={true}
                label="Surname"
                placeholder="Surname"
                name="surname"
                id="surname"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.surname}
                error={formik.errors.surname}
                visible={formik.touched.surname}
              />
              <TextInput
                label="Middle Name"
                placeholder="Middle Name"
                name="middle_name"
                id="middle_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.middle_name}
                error={formik.errors.middle_name}
                visible={formik.touched.middle_name}
              />

              <TextInput
                astheric={true}
                label="Phone No."
                placeholder="Phone No."
                name="phone"
                id="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                error={formik.errors.phone}
                visible={formik.touched.phone}
                type="tel"
              />
            </InputWrap>

            <InputWrap>
              <TextInput
                astheric={true}
                label="First Name"
                placeholder="First Name"
                name="first_name"
                id="first_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
                error={formik.errors.first_name}
                visible={formik.touched.first_name}
              />

              <TextInput
                astheric={true}
                label="Place of Work"
                placeholder="State Secretariat"
                name="work_place"
                id="work_place"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.work_place}
                error={formik.errors.work_place}
                visible={formik.touched.work_place}
              />

              <TextInput
                astheric={true}
                label="Email"
                placeholder="Enter your email"
                type="email"
                name="email"
                id="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.errors.email}
                visible={formik.touched.email}
              />
            </InputWrap>
          </InputDiv>
          <ProceedBtn type="submit" disabled={loanStore.isLoading}>
            {loanStore.isLoading ? 'Loading...' : 'Proceed'}
          </ProceedBtn>
        </InfoContentLeft>
        <InfoContentRight>
          <Img src={GuarantorImage} alt="image" />
        </InfoContentRight>
      </InfoContent>
    </InfoContainer>
  )
}

export default observer(GuarantorForm)
