import React, { useState } from "react"
import PendLoansComp from "../Loan/PendingLoans"
import RejLoansComp from "../Loan/RejectedLoans"
import RefferedLoans from "../Loan/RefferedLoans"
import DisLoansComp from "../Loan/DisbursedLoans"
import useAdminData from "hooks/useAdminData"
import TabHeader from "../TabHeader"

const tabs = [
	{label:"Loans",index:1},
	{label:"Reffered",index:2},
	{label:"Disbursed",index:3},
	{label:"Rejected",index:4},
]

export function TabContent({currentTab}){
	const {userProfile,adminRole} = useAdminData()
	if(currentTab === 1){
		return(
			<div>
				<h3 className="text-3xl font-bold my-4 text-[#242424]">Pending Loans</h3>
				<PendLoansComp />
			</div>
		)
	}
	if(currentTab === 2){
		return(
			<div>
				<h3 className="text-3xl font-bold my-4 text-[#242424]">Reffered Loans</h3>
				<RefferedLoans admin_role={adminRole} user={userProfile}/>
			</div>
		)
    }
	if(currentTab === 3){
		return (
			<div>
				<h3 className="text-3xl font-bold my-4 text-[#242424]">Disbursed Loans</h3>
				<DisLoansComp/>
			</div>
		)
	}
	return (
		<div>
			<h3 className="text-3xl font-bold my-4 text-[#242424]">Rejected Loans</h3>
			<RejLoansComp />
		</div>
	)

}

export default function LoanOfficerTabs() {
	const [toggleState, setToggleState] = useState(1)

	return (
		<section>
			<TabHeader tabs={tabs} selectTab={setToggleState} currentTab={toggleState}/>
			<section className="p-4">
				<TabContent currentTab={toggleState}/>
			</section>
		</section>
	)
}

