export default function GuarantorTab({loan}){
    const {guarantor_one,guarantor_two} = loan
    return (
      <div>
        <section className="my-4 mb-8">
          <h3 className="text-xl font-bold mb-2 text-secondary">First Guarantor's Details</h3>
          <div className="grid grid-cols-2 gap-2">
            <div>
              <div className="mb-4">
                <p className="font-medium text-lg">Full Name</p>
                <p className="text-base">{guarantor_one.first_name + " " + guarantor_one.surname}</p>
              </div>
              <div className="">
                <p className="font-medium text-lg">Work Place</p>
                <p className="text-base">{guarantor_one.work_place}</p>
              </div>
            </div>
            <div>
              <div className="mb-4">
                <p className="font-medium text-lg">Email</p>
                <p className="text-base">{guarantor_one.email}</p>
              </div>
              <div className="my-1">
                <p className="font-medium text-lg">Phone No</p>
                <p className="text-base">{guarantor_one.phone}</p>
              </div>
            </div>
          </div>
        </section>
        <section className="my-4">
          <h3 className="text-xl font-bold mb-2 text-secondary">Second Guarantor's Details</h3>
          <div className="grid grid-cols-2 gap-2">
            <div>
              <div className="mb-4">
                <p className="font-medium text-lg">Full Name</p>
                <p className="text-base">{guarantor_two.first_name + " " + guarantor_two.surname}</p>
              </div>
              <div className="">
                <p className="font-medium text-lg">Work Place</p>
                <p className="text-base">{guarantor_two.work_place}</p>
              </div>
            </div>
            <div>
              <div className="mb-4">
                <p className="font-medium text-lg">Email</p>
                <p className="text-base">{guarantor_two.email}</p>
              </div>
              <div className="my-1">
                <p className="font-medium text-lg">Phone No</p>
                <p className="text-base">{guarantor_two.phone}</p>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="my-4">
          <h3 className="text-xl font-bold mb-4">Second Guarantor's Details</h3>
        </section> */}
      </div>
    )
  }