import { observer } from "mobx-react-lite"
import { useParams } from "react-router-dom"
import { referLoan, rejectLoan, requestEdit } from "services/LoanService"
import * as Yup from "yup"
import { ProtabCta } from "./style"
import LoanActionButton from "./LoanActionButton"
import useAdminData from "hooks/useAdminData"




const ModalComp = ({ loan }) => {
	const params = useParams()
	const loanData = loan?.data[0]
	const {userProfile:currentAdmin} = useAdminData()

	const handleRejectSubmision = async(data, helpers,closeModal)=>{
		const payload = { ...data }
			payload.id = params.id
			payload.status = "rejected"

			const rejectResponse = await rejectLoan(payload)
			if (!rejectResponse?.error) {
				closeModal()
				// setAction("")
			}
	}
	const referValidationSchema = Yup.object({
		comment: Yup.string().label("Comment"),
		amount: Yup.string().label("Amount")
	})
	const handleReferSubmision = async (data, helpers,closeModal) => {
		const payload = { ...data }
		payload.id = params.id
		payload.status = "approved"
		const approveResponse = await referLoan(payload)
		if (!approveResponse?.error) {
			closeModal()
			window.location.reload()
			// setAction("")
		}
	}
	const handleRequestEdit = async (data, helpers,closeModal) => {
		const payload = { ...data }
		payload.id = params.id
		payload.status = "approved"
		const approveResponse = await requestEdit(payload)
		if (!approveResponse?.error) {
			closeModal()
		}
	}
	
	if(loanData.status === "rejected" || loanData.status === "accepted") return null;
	if(loanData.in_memo === true) return null;
	if(currentAdmin.admin_role === "lo" && loanData.reffered && currentAdmin.email !== loanData.refferedBy) return null;
	if(currentAdmin.admin_role !== "lo" ){
		return(
			<ProtabCta>
				<LoanActionButton
				isActive
				btnText={"✖ Reject"}
				bg={"#E57373"}
				color={"#B71C1C"}
				modalTitle={"State reasons for Rejecting this application"}
				amount={loanData.amount}
				handleSubmit={handleRejectSubmision}
				validationSchema={referValidationSchema}
				disabled
				/>

				<LoanActionButton
				isActive
				btnText={"Request Edit"}
				disabled
				bg={"#42A5F5"}
				amount={loanData.amount}
				modalTitle={"Add a description of you want this student to update on his form"}
				handleSubmit={handleRequestEdit}
				validationSchema={referValidationSchema}
				/>
		</ProtabCta>
		)
	}

	return (
		<ProtabCta>
				<LoanActionButton
				isActive={!loanData.reffered}
				btnText={"✔ Refer"}
				modalTitle={"State reasons for Reffering this application"}
				amount={loanData.amount}
				handleSubmit={handleReferSubmision}
				validationSchema={referValidationSchema}
				/>

				<LoanActionButton
				isActive
				btnText={"✖ Reject"}
				bg={"#E57373"}
				color={"#B71C1C"}
				modalTitle={"State reasons for Rejecting this application"}
				amount={loanData.amount}
				handleSubmit={handleRejectSubmision}
				validationSchema={referValidationSchema}
				disabled
				/>

				<LoanActionButton
				isActive
				btnText={"Request Edit"}
				disabled
				bg={"#42A5F5"}
				amount={loanData.amount}
				modalTitle={"Add a description of you want this student to update on his form"}
				handleSubmit={handleRequestEdit}
				validationSchema={referValidationSchema}
				/>
		</ProtabCta>
	)
}

export default observer(ModalComp)
