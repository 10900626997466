import { message, Popover } from "antd";
import { DRAFTS_COLUMNS } from "components/Dashboard/Loan/AcctAcceptedLoans/appColumns";
import { useEffect, useState } from "react";
import { deleteDraft, getDrafts, sendDraftEmailReminder } from "services/LoanService";
import useModal from "./useModal";


const ActionPopoverContent = ({closePopover,deleteLoan,sendMail,sendSMS}) => {
    return (
        <div className="text-center" style={{minWidth:"180px"}}>
            <header className="text-right">
                <button onClick={closePopover}><i className="bi bi-x-lg"></i></button>
            </header>
            <div>
                <button onClick={sendMail} className="block my-2 w-full bg-primary-50 text-primary-600 px-4 py-2 rounded-md" >Send Email</button>
                <button className="block my-2 w-full bg-secondary-50 text-secondary-600 px-4 py-2 rounded-md" onClick={sendSMS}>Send SMS</button>
                <button onClick={deleteLoan} className="bg-red-600 text-white w-full px-4 py-2 block my-2 rounded-md font-medium">Delete Draft</button>
            </div>
        </div>
    )
}
const ActionPopover = ({loan,deleteLoanDraft,sendEmailReminder})=>{
    const [open,setOpen] = useState(false);
    const handleDeleteLoan = ()=>{
        deleteLoanDraft(loan.id)
        setOpen(false)
    }
    const handleSendMail = ()=>{
        sendEmailReminder(loan.id)
        setOpen(false)
    }
    const handleSendSMS = ()=>{

    }
    return (
        <Popover
        content={
            <ActionPopoverContent
                closePopover={() => setOpen(false)}
                deleteLoan={handleDeleteLoan}
                sendMail={handleSendMail}
                sendText={handleSendSMS}
            />
        }
        placement="bottom"
        open={open}
        trigger={"click"}
        onOpenChange={()=>setOpen(true)}
        >
            <button className="text-xl">
                <bi className="bi-three-dots-vertical"></bi>
            </button>
        </Popover>
    )
}

export default function useDrafts(){
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [comment, setComment] = useState("")
    const [sendingEmailReminder, setSendingEmailReminder] = useState(false)
    const [selectedId,setSelectedId] = useState(null)
    const [open,openDeleteModal,closeDeleteModal] = useModal(false,setSelectedId)
    const [emailReminderOpened,openEmailReminderModal,closeEmailReminderModal] = useModal(false,setSelectedId)
    const [drafts, setDrafts] = useState([]);

    const deleteLoanDraft = async   ()=>{
        setDeleteLoading(true)
        const res = await deleteDraft(selectedId)
        if(res){
            closeDeleteModal()
            message.success("Loan deleted successfully")
            await fetchData()
        }
        setDeleteLoading(false)
    }
    const sendEmailReminder = async ()=>{
        if(comment.trim().length === 0) return message.error("Please add a comment to your reminder")
        setSendingEmailReminder(true)
        const data = await sendDraftEmailReminder({
            id:selectedId,
            comment,
        })
        if(data){
            closeEmailReminderModal()
            message.success("Email Reminder sent successfully")
            setComment("")
        }
        setSendingEmailReminder(false)
    }
    const fetchData = async ()=>{
        setLoading(true)
        const res = await getDrafts()
        if(res){
            setDrafts(res.data)
        }
        setLoading(false)
    }

    useEffect(()=>{
        fetchData()
    },[])

    const draftColumns = [
        ...DRAFTS_COLUMNS,
        {
            title: "Actions",
            render: (originalRow) => {
                return (
                    <ActionPopover
                    loan={originalRow}
                    sendEmailReminder={openEmailReminderModal}
                    deleteLoanDraft={openDeleteModal}
                    />
                )
            }
        },
    ]

    return {
        drafts,
        loading,
        deleteLoading,
        deleteLoanDraft,
        closeDeleteModal,
        open,
        openDeleteModal,
        emailReminderOpened,
        closeEmailReminderModal,
        sendEmailReminder,
        sendingEmailReminder,
        draftColumns,
        comment,
        setComment,
        refetch:fetchData
    }
}