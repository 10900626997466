import dayjs from "dayjs";

export const COLUMNS = [
  {
    Header: "Applicant",
    accessor: row => `${row.surname} ${row.first_name}`,
  },
  {
	Header: "BVN",
	accessor: "bvn",
  },
  {
	Header: "Date of Birth",
	accessor: "dob",
	Cell: ({ value }) => {
		return dayjs(value).format('DD/MM/YYYY');
	  },
  },
  {
    Header: "Phone No",
    accessor: "phone",
  },
  {
    Header: "Email",
    accessor: "email",
  }
];

