import { Img } from "globalStyles"
import React from "react"
import { Link } from "react-router-dom"
import { AppLogo2 } from "utils/assets"
import { AuthBottomDIv, Authbtn, Authcontainer, AuthText } from "./style"

function AuthComponent({ children }) {
	return (
		<Authcontainer>
			<Img src={AppLogo2} alt="BSL" />
			{/* <AuthContent>{children}</AuthContent> */}
		</Authcontainer>
	)
}

export default AuthComponent

export const AuthHeader = ({ text }) => {
	return <AuthText>{text}</AuthText>
}
export const AuthButton = ({ title = "Continue", ...rest }) => {
	return <Authbtn {...rest}>{title}</Authbtn>
}

export const AuthBottomLink = ({ text = "Already have an account?", link, linktext = "Log in" }) => {
	return (
		<AuthBottomDIv>
			<p className="auth--bottom-link">{text}</p>{" "}
			<Link className="link-bottom" to={link}>
				{linktext}
			</Link>
		</AuthBottomDIv>
	)
}
