import React from "react"
import dayjs from "dayjs"
import CommaNumber from "comma-number"
import { CloseBtnDetails, ItemImage, MoreDetContyainer } from "./style"
import DetailItem from "./DetailItem"
import { CloseIcon } from "utils/assets"
import { Img } from "globalStyles"

function Tabledetails({ payload, close }) {
	function calculate_age(dob) {
		const g = new Date(dob)
		var diff_ms = Date.now() - g.getTime()
		var age_dt = new Date(Date.now() - diff_ms)
		return Math.abs(age_dt.getUTCFullYear() - new Date().getFullYear())
	}
	return (
		<MoreDetContyainer>
			<CloseBtnDetails onClick={close}>
				<Img src={CloseIcon} alt="Close" />
			</CloseBtnDetails>
			<ItemImage>
				<Img src={payload?.applicant?.avatar} alt="Student" />
			</ItemImage>
			<h5 style={{ marginBottom: "-10px", marginLeft: "15px" }}>Personal info</h5>
			<DetailItem title="Name:" value={`${payload.applicant?.surname} ${payload.applicant?.first_name} ${payload.applicant?.middle_name}`} />
			<DetailItem title="Phone Number:" value={`${payload?.applicant?.phone}`} />
			<DetailItem title="Age:" value={calculate_age(payload?.applicant?.dob) <= 1 ? `${calculate_age(payload?.applicant?.dob)}year` : `${calculate_age(payload?.applicant?.dob)}years`} />
			<DetailItem title="Address:" value={payload?.applicant?.address ? payload?.applicant?.address : ""} />
			<DetailItem title="Hometown:" value={payload?.applicant?.address ? payload?.applicant?.hometown : ""} />
			<h5 style={{ marginBottom: "-10px", marginLeft: "15px" }}>Institution</h5>
			<DetailItem title="Name:" value={payload?.institution?.name ? payload?.institution?.name : ""} />
			<DetailItem title="Faculty:" value={payload?.institution?.faculty ? payload?.institution?.faculty : ""} />
			<DetailItem title="Course:" value={payload?.institution?.course ? payload?.institution?.course : ""} />
			<DetailItem title="Level:" value={payload?.institution?.current_study_year ? payload?.institution?.current_study_year : ""} />
			<h5 style={{ marginBottom: "-10px", marginLeft: "15px" }}>Loan</h5>
			<DetailItem title="Application Status:" value={payload?.request_status ? payload?.request_status : ""} />
			<DetailItem title="Amount:" value={payload?.amount ? `₦${CommaNumber(payload?.amount)}.00` : `₦0.00`} />
			<DetailItem title="Repayment Starts:" value={payload?.repayment_period_starts ? `${dayjs(payload?.repayment_period_starts).format("MMM D, YYYY")}` : "Nil"} />
			<DetailItem title="Monthly Repayment:" value={payload?.monthly_repayment_amount ? `₦${CommaNumber(payload?.monthly_repayment_amount)}` : "Nil"} />
			<h5 style={{ marginBottom: "-10px", marginLeft: "15px" }}>Bank Details</h5>
			<DetailItem title="Account Name:" value={payload?.bank_account?.account_name ? payload?.bank_account?.account_name : ""} />
			<DetailItem title="Account Number:" value={payload?.bank_account?.account_number ? payload?.bank_account?.account_number : ""} />
			<DetailItem title="Bank:" value={payload?.bank_account?.bank_name ? payload?.bank_account?.bank_name : ""} />

			<h5 style={{ marginBottom: "-10px", marginTop: "10px", marginLeft: "15px" }}>Guarantor One</h5>
			<DetailItem title="Name:" value={`${payload?.guarantor_one?.surname} ${payload?.guarantor_one?.first_name} ${payload?.guarantor_one?.middle_name}`} />
			<DetailItem title="Phone Number:" value={`${payload?.guarantor_one?.phone}`} />
			<DetailItem title="Work Place:" value={`${payload?.guarantor_one?.work_place}`} />
			<h5 style={{ marginBottom: "-10px", marginTop: "10px", marginLeft: "15px" }}>Guarantor Two</h5>
			<DetailItem title="Name:" value={`${payload?.guarantor_two?.surname} ${payload?.guarantor_two?.first_name} ${payload?.guarantor_two?.middle_name}`} />
			<DetailItem title="Phone Number:" value={`${payload?.guarantor_two?.phone}`} />
			<DetailItem title="Work Place:" value={`${payload?.guarantor_two?.work_place}`} />
		</MoreDetContyainer>
	)
}

export default Tabledetails
