import styled from "styled-components/macro"

export const RecentAppContainer = styled.div`
	wdith: 50%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
`
export const RecentImg = styled.div`
	wdith: 40px;
	height: 40px;
	border-radius: 50%;
	img {
		wdith: 100%;
		height: 100%;
		border-radius: 50%;
		object-fit: cover;
	}
`
export const RecentItem = styled.div`
	wdith: calc(100% - 16px);
	display: flex;
	height: 60px;
	align-items: center;
	padding: 0 8px;
	justify-content: space-between;
	/* background: #ffffff; */
	margin-bottom: 5px;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`

export const StatusDiv = styled.div`
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 5px;
	border-radius: 8px;
	background: ${({ bgColor }) => (bgColor ? bgColor : "#f57483")};
	color: #222c00;
	.text {
		margin-top: 13px;
	}
`
