import React, { useEffect, useState } from "react"
import { FormSettingsSubmit, Settingsheader } from "./style"

import authStore from "mobx/AuthStore"
import { portalStatus, updatePortalStatus } from "services/UserService"
function PortalStatus() {
	const [state, setState] = useState("Open");
	const handlePortalStatus = async (status) => {
		const update = await updatePortalStatus({status});
		if(!update.error) getPortalStatus();
	}
	const getPortalStatus = async () => {
			const info = await portalStatus();
			if(!info.error){
				if(info.data.loanPortal.toLowerCase() === "open") setState("Close")
				else setState("Open")
			}
	}
	useEffect(() => {
		getPortalStatus();
	});

	return ( 
		<>
			<Settingsheader>Change Portal Status</Settingsheader>
				<FormSettingsSubmit type="submit" onClick={e =>handlePortalStatus(e.target.value)} value={state} disabled={authStore.isLoading ? true : false}>
					{authStore.isLoading ? "Loading..." : `${state}`}
				</FormSettingsSubmit>
			</>
	)
}

export default PortalStatus
