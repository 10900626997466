import { useState,useEffect } from "react"
import { useParams } from "react-router-dom"
import { getMemo } from "services/MemoService"

function parseJSON(jsonData,defaultValue){
	try {
		return JSON.parse(jsonData)
	} catch (error) {
		return defaultValue
	}
}

export function useMemoData(){
    const [loading,setLoading] = useState(true)
    const [memo,setMemo] = useState(null)
    const [selectedLoans,setSelectedLoans] = useState([])

    const selectLoan = (id)=>{
        console.log(id,selectedLoans)
        if(selectedLoans.includes(id)){
            setSelectedLoans(selectedLoans.filter(loan_id=>loan_id !== id ))
            return;
        }
        setSelectedLoans(prev=>[...prev,id])
    }
	const params = useParams()

    useEffect(() => {
		async function getMemoData(){
			setLoading(true)
			const res = await getMemo(params.id)
			if(res.status === "success"){
				const loans = parseJSON(res.data.applications,[])
				const loans_id = loans.map(loan=>loan._id)
				setMemo(res.data)
				setSelectedLoans(loans_id)
			}
			setLoading(false)
		}
		getMemoData()
	},[params])

    return {
        loading,
        memo,
		selectLoan,
		selectedLoans
    }
}