import { SlideInPopUp } from "components/Dashboard/FullScreenLoader/style"
import styled from "styled-components/macro"
import media from "utils/media"

export const DeleteFormContainer = styled.div`
	width: 25%;
	min-height: 150px;
	background: #ffffff;
	display: flex;
	flex-direction: column;
	padding: 15px;
	box-shadow: 0px 0px 13px 1px rgba(63, 63, 68, 0.24), 0px 1px 3px rgba(63, 63, 68, 0.15);
	border-radius: 8px;
	${media.tablet`
  width: 50%;
  min-height: 150px;
  
  `}
	${media.mobile`
  width: 80%;
  min-height: 150px;
  
  `}
`

export const BtnContainer = styled.div`
	width: 100%;
	height: 40px;
	bottom: 0px;
	margin-top: 30px;
	/* background: #f90; */
	display: flex;
	justify-content: space-between;
	/* align-self: end; */
`

export const PauseGoalFormContainer = styled.div`
	width: 20%;
	height: 150px;
	background: #ffffff;
	padding: 15px;
	box-shadow: 0px 0px 13px 1px rgba(63, 63, 68, 0.24), 0px 1px 3px rgba(63, 63, 68, 0.15);
	border-radius: 46px;
	${media.tablet`
  width: 50%;
  height: 440px;
  
  `}
	${media.mobile`
  width: 90%;
  height: 440px;
  
  `}
`

export const FormsModal = styled.div`
	height: 100%;
	top: 0%;
	left: 0%;
	right: 0%;
	bottom: 0%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${({ show }) => (show ? "rgba(0, 0, 0, 0.5)" : "#f90")};
	position: fixed;
	transition: all 0.5s ease-out;
	animation-name: ${SlideInPopUp};
	animation-duration: 0.5s;
	z-index: 100005;
`
export const Text = styled.p`
	text-align: center;
`

export const ConfirmActionButton = styled.button`
	text-align: center;
`

export const WlButton = styled.button`
	background-color: ${({ bc }) => (bc ? bc : null)};
	width: ${({ w }) => (w ? w : null)};
	height: ${({ h }) => (h ? h : null)};
	flex: ${({ fg }) => (fg ? fg : null)};
	padding: ${({ p }) => (p ? p : null)};
	border: ${({ border }) => (border ? `1px solid ${border}` : `none`)};
	border-radius: ${({ br }) => (br ? br : null)};
	outline: none;
	font-weight: ${({ fw }) => (fw ? fw : null)};
	font-size: ${({ fs }) => (fs ? fs : null)};
	display: ${({ display }) => display};
	align-items: ${({ alignI }) => alignI || null};
	cursor: pointer;
	box-shadow: ${({ bs }) => (bs ? bs : null)};
	box-sizing: ${({ bSizing }) => (bSizing ? bSizing : null)};
	color: ${({ color }) => (color ? color : null)};
	margin: ${({ m }) => (m ? m : null)};
	margin-top: ${({ mt }) => (mt ? mt : null)};
	margin-left: ${({ ml }) => (ml ? ml : null)};
	margin-right: ${({ mr }) => (mr ? mr : null)};
	margin-bottom: ${({ mb }) => (mb ? mb : null)};
	border-bottom: ${({ bm }) => (bm ? bm : null)};
	&:hover {
		background: ${({ hv }) => (hv ? hv : null)};
	}
`
