import media from "utils/media"
import styled from "styled-components/macro"

export const SMCardBody = styled.div`
	width: ${({ w }) => (w ? w : "calc(100% - 30px)")};
	height: 80px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/* flex: 23 10 18%; */
	padding: 15px;
	/* margin-right: 15px;
  margin-bottom: 15px; */
	background: #ffffff;
	box-shadow: 0px 2px 12px rgba(10, 2, 13, 0.04);
	border-radius: 4px;
	${media.tablet`
  width:calc(100% - 30px);
    /* margin-top:20px; */
    `}
	${media.mobile`
    width:92%;
    /* margin-top:20px; */
    
    `}
`
export const SmContent = styled.div`
	width: 100%;
	display: flex;
	align-items: center;

	${media.tablet`
  justify-content: space-between;
  
    `}
	${media.mobile`
    width:92%;
    
    `}
`
