import React from "react"
import { RecentImg, RecentItem, StatusDiv } from "./style"
import { ByshelbP, Img } from "globalStyles"
import { AppLogo } from "utils/assets"
import CommaNumber from "comma-number"
function RecentItemLoan({ loan }) {

	function getStatusData(status) {
		if(status === "approved") return {color:"#5caa77",text:"Approved"}
		if(status === "rejected") return {color:"#e23644",text:"Rejected"}
		if(status === "pending") return {color:"#ffae00",text:"Pending"}
		if(status === "disbursed") return {color:"#5caa77",text:"Disbursed"}
		if(status === "reviewing") return {color:"#9E9E9E",text:"Reviewing"}
		if(status === "accepted") return {color:"#61a779",text:"Accepted"}
		return {color:"#e23644",text:status}
	}
	const statusData = getStatusData(loan?.status)
	return (
		<RecentItem>
			<RecentImg>
				<Img width="40px" height="40px" src={loan?.applicant?.avatar ? loan?.applicant?.avatar : AppLogo} alt={"user application"} />
			</RecentImg>
			<ByshelbP fs="13px">{loan?.loan_type}</ByshelbP>
			<ByshelbP fs="13px">{`₦${CommaNumber(loan?.amount)}.00`}</ByshelbP>
			<StatusDiv bgColor={statusData.color}>
				<ByshelbP fs="13px" tf="capitalize">
					{statusData.text}
				</ByshelbP>
			</StatusDiv>
			<ByshelbP fs="13px">{loan?.institution?.current_study_year ? loan?.institution?.current_study_year : ""}</ByshelbP>
		</RecentItem>
	)
}

export default RecentItemLoan
