import FullScreenLoader from "components/Dashboard/FullScreenLoader"
import React, { useMemo, useState } from "react"
import { Div } from "globalStyles"
import { useQuery } from "react-query"
import { getApprovedLoans } from "services/LoanService"
import FilterComp from "../FilterComp"
import { TableContainer } from "../style"
import { observer } from "mobx-react-lite"
import ApprovedLoanTable from "./ApprovedLoansTable"

const AppLoansComp = ({ admin_role, user }) => {
	const { data: approvedLoans, isLoading } = useQuery("approved-loans", getApprovedLoans)
	const [searchQuery,setSearchQuery] = useState("")
	const data = useMemo(() => {
		const appLoans = approvedLoans?.data ? approvedLoans.data : []
		return appLoans
	}, [approvedLoans?.data])

	const filterDataByName = (dataArr) => dataArr.filter(loan=>{
		return (
			loan.applicant.first_name.toLowerCase().includes(searchQuery.toLowerCase().trim()) ||
			loan.applicant.surname.toLowerCase().includes(searchQuery.toLowerCase().trim())
		)
	})

	if (isLoading || !data) return <FullScreenLoader show={true} />

	return (
		<>
			<TableContainer>
				<Div width="100%" display="flex" justify="space-between" alignI="center">
					<FilterComp filter={searchQuery} setFilter={setSearchQuery} />
				</Div>
				<ApprovedLoanTable dataSource={filterDataByName(data)}/>
			</TableContainer>
		</>
	)
}

export default observer(AppLoansComp)