import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { addLoanAccountDetails } from 'services/ReappLoanService'
import styled from 'styled-components'
import Colors from 'utils/colors'

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

const PopupContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  max-width: 80%;
  max-height: 80%;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;

  h2 {
    margin-top: 0;
  }

  form {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;

      input {
        margin-top: 5px;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }

    button {
      margin-top: 20px;
      padding: 10px;
      border: none;
      background: ${Colors.primary};
      color: white;
      border-radius: 4px;
      cursor: pointer;

      &:hover {

      background: ${Colors.primaryLight};

      }
    }
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`

const LoanRecordList = styled.div`
  display: flex;
  overflow-x: auto;
  margin-top: 20px;
  padding-bottom: 10px;

  .record {
    min-width: 350px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-right: 10px;
    background: #f9f9f9;
    position: relative;
  }

  .record div {
    margin-bottom: 5px;
  }


   .remove-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    color: red;
    cursor: pointer;
  }
  
`

const RecordBtn = styled.button`
    
      margin-top: 20px;
      padding: 10px;
      border: none;
      background: ${Colors.primary};
      color: white;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
     
      background: ${Colors.primaryLight};

      }
`

const AddLoanInfo = ({ onClose, userId }) => {
  const [loanRecords, setLoanRecords] = useState([])

  const initialValues = {
    institution_name: '',
    amount: '',
    application_date: '',
    disburse_date: '',
    loan_type: 'undergraduate',
    guarantor_one_first_name: '',
    guarantor_one_last_name: '',
    guarantor_one_phone: '',
    guarantor_one_work_place: '',
    guarantor_one_email: '',
    guarantor_one_nin: '',
    guarantor_two_first_name: '',
    guarantor_two_last_name: '',
    guarantor_two_phone: '',
    guarantor_two_work_place: '',
    guarantor_two_email: '',
    guarantor_two_nin: '',
  }

  const validationSchema = Yup.object({
    institution_name: Yup.string().required('Institution name is required'),
    amount: Yup.number().required('Amount is required'),
    application_date: Yup.date().required('Application date is required'),
    disburse_date: Yup.date().required('Disburse date is required'),
    loan_type: Yup.string().required('Loan type is required'),
    guarantor_one_first_name: Yup.string().required('First name is required'),
    guarantor_one_last_name: Yup.string().required('Last name is required'),
    guarantor_one_phone: Yup.string().length(11, 'Phone number must be 11 digits').required('Phone number is required'),
    guarantor_one_work_place: Yup.string().required('Work place is required'),
    guarantor_one_email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    guarantor_one_nin: Yup.string().length(11, 'NIN must be 11 digits').required('NIN is required'),
    guarantor_two_first_name: Yup.string().required('First name is required'),
    guarantor_two_last_name: Yup.string().required('Last name is required'),
    guarantor_two_phone: Yup.string().length(11, 'Phone number must be 11 digits').required('Phone number is required'),
    guarantor_two_work_place: Yup.string().required('Work place is required'),
    guarantor_two_email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    guarantor_two_nin: Yup.string().length(11, 'NIN must be 11 digits').required('NIN is required'),
  })

  const handleAddRecord = (values, resetForm) => {
    setLoanRecords([...loanRecords, values])
    resetForm()
  };

  const handleRemoveRecord = (index) => {
    setLoanRecords(loanRecords.filter((_, i) => i !== index));
  };

  const handleFinalSubmit = async () => {
    for (let record of loanRecords) {
      const payload = {
        id: userId,
        institution_name: record.institution_name,
        amount: record.amount,
        application_date: record.application_date,
        disburse_date: record.disburse_date,
        loan_type: record.loan_type,
        guarantor_one: {
          first_name: record.guarantor_one_first_name,
          last_name: record.guarantor_one_last_name,
          phone: record.guarantor_one_phone,
          work_place: record.guarantor_one_work_place,
          email: record.guarantor_one_email,
          nin: record.guarantor_one_nin,
        },
        guarantor_two: {
          first_name: record.guarantor_two_first_name,
          last_name: record.guarantor_two_last_name,
          phone: record.guarantor_two_phone,
          work_place: record.guarantor_two_work_place,
          email: record.guarantor_two_email,
          nin: record.guarantor_two_nin,
        },
      }

      try {
        await addLoanAccountDetails(payload)
        console.log('Record added successfully')
      } catch (error) {
        console.error('Error adding record:', error)
      }
    }
    onClose()
  }

  return (
    <PopupOverlay>
      <PopupContent>
        <CloseButton onClick={onClose}>×</CloseButton>
        <h2>Add Loan Details</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) =>
            handleAddRecord(values, resetForm)
          }
        >
          {({ isSubmitting }) => (
            <Form>
              <label>
                Institution Name:
                <Field type="text" name="institution_name" />
                <ErrorMessage
                  name="institution_name"
                  component="div"
                  style={{ color: 'red' }}
                />
              </label>
              <label>
                Amount:
                <Field type="number" name="amount" />
                <ErrorMessage
                  name="amount"
                  component="div"
                  style={{ color: 'red' }}
                />
              </label>
              <label>
                Application Date:
                <Field type="date" name="application_date" />
                <ErrorMessage
                  name="application_date"
                  component="div"
                  style={{ color: 'red' }}
                />
              </label>
              <label>
                Disburse Date:
                <Field type="date" name="disburse_date" />
                <ErrorMessage
                  name="disburse_date"
                  component="div"
                  style={{ color: 'red' }}
                />
              </label>
              <label>
                Loan Type:
                <Field as="select" name="loan_type">
                  <option value="undergraduate">Undergraduate</option>
                  <option value="postgraduate">Postgraduate</option>
                </Field>
                <ErrorMessage
                  name="loan_type"
                  component="div"
                  style={{ color: 'red' }}
                />
              </label>
              <h3>Guarantor One</h3>
              <label>
                First Name:
                <Field type="text" name="guarantor_one_first_name" />
                <ErrorMessage
                  name="guarantor_one_first_name"
                  component="div"
                  style={{ color: 'red' }}
                />
              </label>
              <label>
                Last Name:
                <Field type="text" name="guarantor_one_last_name" />
                <ErrorMessage
                  name="guarantor_one_last_name"
                  component="div"
                  style={{ color: 'red' }}
                />
              </label>
              <label>
                Phone:
                <Field type="text" name="guarantor_one_phone" />
                <ErrorMessage
                  name="guarantor_one_phone"
                  component="div"
                  style={{ color: 'red' }}
                />
              </label>
              <label>
                Work Place:
                <Field type="text" name="guarantor_one_work_place" />
                <ErrorMessage
                  name="guarantor_one_work_place"
                  component="div"
                  style={{ color: 'red' }}
                />
              </label>
              <label>
                Email:
                <Field type="email" name="guarantor_one_email" />
                <ErrorMessage
                  name="guarantor_one_email"
                  component="div"
                  style={{ color: 'red' }}
                />
              </label>
              <label>
                NIN:
                <Field type="text" name="guarantor_one_nin" />
                <ErrorMessage
                  name="guarantor_one_nin"
                  component="div"
                  style={{ color: 'red' }}
                />
              </label>
              <h3>Guarantor Two</h3>
              <label>
                First Name:
                <Field type="text" name="guarantor_two_first_name" />
                <ErrorMessage
                  name="guarantor_two_first_name"
                  component="div"
                  style={{ color: 'red' }}
                />
              </label>
              <label>
                Last Name:
                <Field type="text" name="guarantor_two_last_name" />
                <ErrorMessage
                  name="guarantor_two_last_name"
                  component="div"
                  style={{ color: 'red' }}
                />
              </label>
              <label>
                Phone:
                <Field type="text" name="guarantor_two_phone" />
                <ErrorMessage
                  name="guarantor_two_phone"
                  component="div"
                  style={{ color: 'red' }}
                />
              </label>
              <label>
                Work Place:
                <Field type="text" name="guarantor_two_work_place" />
                <ErrorMessage
                  name="guarantor_two_work_place"
                  component="div"
                  style={{ color: 'red' }}
                />
              </label>
              <label>
                Email:
                <Field type="email" name="guarantor_two_email" />
                <ErrorMessage
                  name="guarantor_two_email"
                  component="div"
                  style={{ color: 'red' }}
                />
              </label>
              <label>
                NIN:
                <Field type="text" name="guarantor_two_nin" />
                <ErrorMessage
                  name="guarantor_two_nin"
                  component="div"
                  style={{ color: 'red' }}
                />
              </label>
              <button type="submit" disabled={isSubmitting}>
                Add Record
              </button>
            </Form>
          )}
        </Formik>
        <LoanRecordList>
          {loanRecords.map((record, index) => (
            <div className="record" key={index}>
              <div>Institution: {record.institution_name}</div>
              <div>Amount: {record.amount}</div>
              <div>Application Date: {record.application_date}</div>
              <div>Disburse Date: {record.disburse_date}</div>
              <div>Loan Type: {record.loan_type}</div>
              <div>Guarantor One: {record.guarantor_one_first_name} {record.guarantor_one_last_name}</div>
              <div>Guarantor Two: {record.guarantor_two_first_name} {record.guarantor_two_last_name}</div>
              <button className="remove-button" onClick={() => handleRemoveRecord(index)}>X</button>
            </div>
          ))}
        </LoanRecordList>
        <RecordBtn onClick={handleFinalSubmit}>Submit All Records</RecordBtn>
      </PopupContent>
    </PopupOverlay>
  )
}

export default AddLoanInfo
