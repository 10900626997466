import React from 'react'
import LoginComponent from '../../components/Auth/Login'



function LoginPage() {
  return (
    <div>
     <LoginComponent />
    </div>
  )
}

export default LoginPage
