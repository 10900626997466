import { Img } from "globalStyles"
import React from "react"
import { SectionLoaderIcon } from "utils/assets"
import { FullScreenLoaderContainer } from "./style"

function FullScreenLoader({ show }) {
	return (
		<FullScreenLoaderContainer show={show}>
			<div>
				<Img src={SectionLoaderIcon} alt="Loading" />
			</div>
		</FullScreenLoaderContainer>
	)
}

export default FullScreenLoader
