import { Modal, Spin, Table } from "antd";
import useDrafts from "hooks/useDrafts";

function ActionModal({children,opened,onClose,actionLoading,onOk,containerClass}){
 return (
    <Modal open={opened} centered footer={null} maskClosable={false} closeIcon={null} closable={false}>
        <div className={containerClass}>
            {children}
            <div className="flex items-center  justify-between w-full gap-4">
                <button
                onClick={onClose}
                disabled={actionLoading}
                className="bg-red-100 text-red-600 border rounded-full font-medium border-red-300 px-4 py-2 disabled:opacity-55 disabled:cursor-not-allowed"
                >
                        Cancel
                </button>
                <button
                onClick={onOk}
                disabled={actionLoading}
                className="bg-primary-100 text-primary-600 rounded-full font-medium border border-primary-300 px-4 py-2 disabled:opacity-55 disabled:cursor-not-allowed"
                >
                    {actionLoading ? <Spin/> : <span>Continue</span>}
                </button>
            </div>
        </div>
    </Modal>
    )
}

export default function DraftsTable(){
    const {
        loading,
        draftColumns,
        setComment,
        comment,
        open,
        closeDeleteModal,
        drafts,
        deleteLoading,
        deleteLoanDraft,
        emailReminderOpened,
        closeEmailReminderModal,
        sendEmailReminder,
        sendingEmailReminder
    } = useDrafts()
    return (
        <>
        <ActionModal opened={open} onClose={closeDeleteModal} actionLoading={deleteLoading} onOk={deleteLoanDraft}>
            <p className="text-3xl text-center font-semibold">Confirm Delete</p>
            <p className="my-4 text-center">Are you sure you wish to delete this loan draft, this action can not be reversed</p>
        </ActionModal>
        <ActionModal opened={emailReminderOpened} onClose={closeEmailReminderModal} actionLoading={sendingEmailReminder} onOk={sendEmailReminder}>
            <p className="text-3xl text-center font-semibold mb-4 text-secondary">Email Reminder</p>
            <p className="mt-8 text-lg font-medium">Comment</p>
            <textarea type="text"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                style={{outline: "none"}}
                className="border w-full mb-6 p-2 rounded-md resize-none"
                cols={4}
                placeholder="Add Reminder Comment"
            />
        </ActionModal>
        <div className="p-4">
            <header>
                <p className="text-3xl font-bold text-secondary">Loan Drafts ({drafts.length})</p>
            </header>
            <div className="my-4">
                <Table loading={loading} columns={draftColumns} dataSource={drafts} />
            </div>
        </div>
        </>
    )
}