import EnquiryComp from 'components/Dashboard/Enquiry'
import MainLayout from 'layout/MainLayout'
import React from 'react'

const EnquiryPage = () => {
  return (
    <MainLayout>
      <EnquiryComp />
    </MainLayout>
  )
}

export default EnquiryPage
