import { SlideInPopUp } from "components/Dashboard/FullScreenLoader/style"
import styled from "styled-components/macro"
import Colors from "utils/colors"
import media from "utils/media"

export const TransTableBody = styled.table`
	width: 100%;
	font-size: 14px;
	border-collapse: collapse !important;
	td {
		/* text-align: center; */
		padding: 15px 5px;
		font-size: 14px;
		/* border-bottom: 1px solid #e4e7eb; */
	}
	th {
		padding: 20px 5px;
		/* text-align: center; */
		text-transform: capitalize;
		border-bottom: 1px solid #e4e7eb;
		color: ${Colors.black};
		font-size: 14px;
	}

	td {
		color: ${Colors.black};
		font-size: 14px;
	}
	tr:nth-child(even) {
		/* border-bottom: 1px solid #e4e7eb; */
	}
	${media.mobile`
  /* width: 150vw; */
  td, th {
      white-space: nowrap;
    }
    `}
`

export const TransTextHeader = styled.th`
	text-align: left;
	padding: 8px;
	border-bottom: 2px solid red;
	td {
		text-align: left;
		padding: 8px;
		/* border-bottom:2px solid red;  */
		color: ${Colors.white};
	}
`
export const TransTableContainer = styled.div`
	width: calc(100% - 0px);
	margin-top: 10px;
	padding: 0px 0px;
`
export const TransTableContent = styled.div`
	width: 100%;

	box-sizing: border-box;
	${media.mobile`
	border:none;
	overflow-x: scroll;
	::-webkit-scrollbar {
		/* width: 0; */
		background: transparent;
	}
	::-webkit-scrollbar-thumb {
		background: transparent;
	}
	`}
`

export const TablePopupDiv = styled.div`
	width: 160px;
	right: 1.2%;
	top: 33%;
	/* height: 200px; */
	display: flex;
	padding-top: 10px;
	padding-bottom: 10px;
	/* align-items: center; */
	flex-direction: column;
	/* justify-content: center; */
	background: ${Colors.white};
	color: ${Colors.black};
	margin-top: 20px;
	position: absolute;
	border-width: 1px 1px 1px 1px;
	width: 122px;
	height: 74px;
	border-radius: 3px;
	border-color: #eaeaea;
	background: #ffffff;
	box-shadow: 0px 0px 0px rgba(255, 255, 225, 1);
	z-index: 0;
	${media.mobile`
    width:160px;
  left: 52%;
  /* width: 90%; */
  `}
`

export const TableTopDiv = styled.div`
	width: 90%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	h5 {
		color: ${Colors.white};
		font-size: 24px;
	}
	${media.mobile`
    flex-direction: column;
	align-items: flex-start;
	gap:15px;
	-webkit-gap:15px;
    `}
`
export const TableDetailsPageModal = styled.div`
	position: absolute;
	height: 300px;
	margin-top: -120px;
	left: 0%;
	right: 0%;
	background: ${({ show }) => (show ? "rgba(0, 0, 0, 0)" : "transparent")};
	/* position: fixed; */
	transition: all 0.5s ease-out;
	/* transform: ${({ show }) => (show ? `translateY(0)` : `translateY(-20px)`)}; */
	animation-name: ${SlideInPopUp};
	animation-duration: 0.5s;
	z-index: 90;
`

export const ReqImagDiv = styled.div`
	width: 50px;
	height: 50px;
	border-radius: 50%;
	aligin-items: center;
	justify-content: center;
	img {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		object-fit: cover;
	}
`
export const Statuslabel = styled.div`
	width: 79px;
	display: flex;
	aligin-items: center;
	justify-content: center;
	height: 37px;
	border: none;
	color: ${({ status }) => (status === "activated" ? "#013220" : status === "pending" ? "#F6A74B" : status === "deactivated" || status === "suspended" ? "#9E0038" : "")};
	background: ${({ status }) =>
		status === "activated" ? "rgba(32, 94, 32, 0.12)" : status === "pending" ? "rgba(246, 167, 75, .12)" : status === "deactivated" || status === "suspended" ? "#F9DDE7" : ""};
	border-radius: 8px;
	cursor: pointer;
	.text__value {
		margin-top: 8px;
		text-transform: capitalize;
	}
`
export const ViewAdminBtn = styled.button`
	width: 79px;
	display: flex;
	aligin-items: center;
	border: none;
	justify-content: center;
	height: 37px;
	border: none;
	color: ${({ status }) => (status === "activated" ? "#013220" : status === "pending" ? "#F6A74B" : status === "deactivated" || status === "suspended" ? "#9E0038" : "")};
	background: ${({ status }) =>
		status === "activated" ? "rgba(32, 94, 32, 0.12)" : status === "pending" ? "rgba(246, 167, 75, .12)" : status === "deactivated" || status === "suspended" ? "#F9DDE7" : ""};
	border-radius: 8px;
	cursor: pointer;
	.text__value {
		margin-top: 8px;
		text-transform: capitalize;
	}
`
export const ActivateStaffBtn = styled.button`
	width: 243px;
	height: 46px;
	margin-top: 20px;
	margin-bottom: 100px;
	margin-left: 15px;
	color: #ffffff;
	background: #205e20;
	box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
	border: none;
	border-radius: 8px;
	cursor: pointer;
`

export const DeActivateStaffBtn = styled.button`
	width: 243px;
	height: 46px;
	border: none;
	margin-top: 20px;
	margin-bottom: 100px;
	margin-left: 15px;
	color: #ffffff;
	background: #9e0038;
	box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	cursor: pointer;
`
