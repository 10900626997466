import FullScreenLoader from "components/Dashboard/FullScreenLoader"
import React, {useMemo, useState } from "react"
import { useQuery } from "react-query"

import { getApprovedLoans } from "services/LoanService"
import FilterComp from "../FilterComp"
import { TableContainer } from "../style"

// import EmptyData from "components/Emptydata"
import { observer } from "mobx-react-lite"
// import checkboxStore from "mobx/CheckboxStore"
import DownloadCSVBtn, { DisburseBtn } from "./DownloadCsvBtn"
import AcceptedTable from "./Table"
import { Checkbox } from "antd"

// import { TableContainer, TableDiv } from './style'

const AppLoansComp = ({ admin_role, user }) => {
	const { data: approvedLoans, isLoading } = useQuery("", getApprovedLoans)
	const [selected,setSelected] = useState([])

	const data = useMemo(() => {
		const appLoans = approvedLoans?.data ? approvedLoans.data : []
		return appLoans
	}, [approvedLoans?.data])
	if (isLoading) return <FullScreenLoader show={true} />

	const getIsSelected = (allLoans)=>{
		const selectedIds = selected.map(s=>s.id)
		return allLoans.every(loan=>selectedIds.includes(loan.id));
	}
	const selectAll = (allLoans)=>{
		const isAllSelected = getIsSelected(allLoans)
		return ()=>{
			if(!isAllSelected) return setSelected(allLoans)
			setSelected([])
		}
	}
	return (
		<div>
			<TableContainer>
				<header className="flex items-center gap-2 my-4">
					<FilterComp/>
					<div className="ml-auto flex items-center gap-2">
						{admin_role === "acc" && <DownloadCSVBtn data={selected}/>}
						{admin_role === "acc" && <DisburseBtn data={selected}/>}
					</div>
				</header>
				<div className="flex items-center gap-2">
					<Checkbox checked={getIsSelected(data)} onChange={selectAll(data)} />
					<p>Select All</p>
				</div>
				<AcceptedTable selected={selected} setSelected={setSelected} dataSource={data} />
			</TableContainer>
			{/* {!isLoading && data.length <= 0 ? <EmptyData text="No new approved loans yet" /> : null} */}
		</div>
	)
}

export default observer(AppLoansComp)
