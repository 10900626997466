const Colors = {
	primary: "#391A60",
	primaryLight: "#6D0062",
	secondary: "#EEBC1D",
	red: "#D10A0A",
	black: "#000000",
	white: "#FFFFFF",
	muted: "#D9D9D9",
	light: "#A7A7A7",
	gradient: "linear-gradient(73.6deg, rgba(57, 26, 96, 0.97) 4.94%, rgba(109, 0, 98, 0.97) 71.35%)",
	whiteGradient: "rgba(0,0,0, 0.5)"
}
export default Colors
