import { message, Modal, Spin } from "antd";
import { useState } from "react";
import SuperUserService from "../SuperUserService";

export default function DeleteLoan({id,refetch}){
    const [open,setOpen] = useState(false);
    const [loading,setLoading] = useState(false)

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleDelete = async() => {
        setLoading(true);
        const [data,error] = await SuperUserService.deleteLoan(id);
        setLoading(false);
        if(!data){
            handleClose()
            return message.error(error.message || "Sorry could not delete Loan at the moment please try again later");
        }
        message.success("Loan Deleted Successfully");
        if(refetch){
            await refetch();
        }
    }
    return (
        <>
        <Modal centered open={open} maskClosable={false} onCancel={handleClose} footer={null}>
            <h3 className="text-center text-3xl">Confirm Delete</h3>
            <p className="my-2 text-center">Are you sure you want to delete this loan, this action can not be reversed</p>
            <div className="flex items-center justify-center gap-4 my-2">
                <button disabled={loading} className="border border-solid border-red-400 disabled:cursor-not-allowed disabled:opacity-55 text-red-600 bg-red-50 font-medium px-6 py-2 rounded-full" onClick={handleClose}>Cancel</button>
                <button onClick={handleDelete} disabled={loading} className="border border-solid disabled:cursor-not-allowed disabled:opacity-55 border-secondary-400 text-secondary-600 bg-secondary-50 rounded-full font-medium px-6 py-2">
                    {loading ? <Spin/> : <span>Continue</span>}
                </button>
            </div>
        </Modal>
        <button onClick={handleOpen}>
            <i className="bi bi-trash text-red-700"></i>
        </button>
        </>
    )
}