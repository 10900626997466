import dashboardIcon from "assets/icons/dashboard.svg"
import dashboardActive from "assets/icons/dashboardActive.svg"

import userIcon from "assets/icons/userIcon.svg"
import userActive from "assets/icons/userIconActive.svg"

// import walletIcon from "assets/icons/wallet.svg"
// import walletActive from "assets/icons/walletActive.svg"

import applyIcon from "assets/icons/applyIcon.svg"
import applytActive from "assets/icons/applyActive.svg"

import settingsIcon from "assets/icons/settings.svg"
import settingsActive from "assets/icons/settingsActive.svg"

import {
	DASHBOARD_BLOG_ROUTE,
	DASHBOARD_ENQUIRY_ROUTE,
	DASHBOARD_LOAN_ROUTE,
	DASHBOARD_MEMO_ROUTE,
	DASHBOARD_NEWSLETTER_ROUTE,
	DASHBOARD_PROFILEROUTE,
	DASHBOARD_RE_APPLICANT_ROUTE,
	DASHBOARD_SETTINGS_ROUTE,
	DASHBOARD_STAFF_ROUTE,
	HOME_ROUTE
} from "routes"
import { BlogActive, BlogIcon, MessagesActive, MessagesIcon, NewsLetterActive, NewsLetterIcon, StaffActive, StaffIcon, memoIcon, memoIconActive } from "utils/assets"

export const menuData = [
	{
		icon: dashboardActive,
		activeIcon: dashboardIcon,
		text: "Dashboard",
		isActive: false,
		link: HOME_ROUTE
	},
	{
		icon: userIcon,
		activeIcon: userActive,
		text: "Profile",
		isActive: false,
		link: DASHBOARD_PROFILEROUTE
	},
	{
		icon: applyIcon,
		activeIcon: applytActive,
		text: "Loans",
		isActive: false,
		link: DASHBOARD_LOAN_ROUTE
	},
	{
		icon: memoIcon,
		activeIcon: memoIconActive,
		text: "Memo",
		isActive: false,
		link: DASHBOARD_MEMO_ROUTE
	},
	{
		icon: StaffActive,
		activeIcon: StaffIcon,
		text: "Staff",
		isActive: false,
		link: DASHBOARD_STAFF_ROUTE
	},
	{
		icon: applyIcon,
		activeIcon: applytActive,
		text: "Re-Applicant",
		isActive: false,
		link: DASHBOARD_RE_APPLICANT_ROUTE
	},
	{
		icon: settingsActive,
		activeIcon: settingsIcon,
		text: "Settings",
		isActive: false,
		link: DASHBOARD_SETTINGS_ROUTE
	}
]
export const fdoData = [
	{
		icon: dashboardActive,
		activeIcon: dashboardIcon,
		text: "Dashboard",
		isActive: false,
		link: HOME_ROUTE
	},
	{
		icon: userIcon,
		activeIcon: userActive,
		text: "Profile",
		isActive: false,
		link: DASHBOARD_PROFILEROUTE
	},

	{
		icon: MessagesIcon,
		activeIcon: MessagesActive,
		text: "Enquiry",
		isActive: false,
		link: DASHBOARD_ENQUIRY_ROUTE
	},
	{
		icon: NewsLetterIcon,
		activeIcon: NewsLetterActive,
		text: "Newsletter",
		isActive: false,
		link: DASHBOARD_NEWSLETTER_ROUTE
	},
	{
		icon: settingsActive,
		activeIcon: settingsIcon,
		text: "Settings",
		isActive: false,
		link: DASHBOARD_SETTINGS_ROUTE
	}
]

export const lphData = [
	{
		icon: dashboardActive,
		activeIcon: dashboardIcon,
		text: "Dashboard",
		isActive: false,
		link: HOME_ROUTE
	},
	{
		icon: userIcon,
		activeIcon: userActive,
		text: "Profile",
		isActive: false,
		link: DASHBOARD_PROFILEROUTE
	},
	{
		icon: applyIcon,
		activeIcon: applytActive,
		text: "Loans",
		isActive: false,
		link: DASHBOARD_LOAN_ROUTE
	},
	{
		icon: memoIcon,
		activeIcon: memoIconActive,
		text: "Memo",
		isActive: false,
		link: DASHBOARD_MEMO_ROUTE
	},
	{
		icon: BlogIcon,
		activeIcon: BlogActive,
		text: "Blog",
		isActive: false,
		link: DASHBOARD_BLOG_ROUTE
	},
	{
		icon: applyIcon,
		activeIcon: applyIcon,
		text: "Re-Applicant",
		isActive: false,
		link: DASHBOARD_RE_APPLICANT_ROUTE
	},
	{
		icon: settingsActive,
		activeIcon: settingsIcon,
		text: "Settings",
		isActive: false,
		link: DASHBOARD_SETTINGS_ROUTE
	}
]
export const accData = [
	{
		icon: dashboardActive,
		activeIcon: dashboardIcon,
		text: "Dashboard",
		isActive: false,
		link: HOME_ROUTE
	},
	{
		icon: userIcon,
		activeIcon: userActive,
		text: "Profile",
		isActive: false,
		link: DASHBOARD_PROFILEROUTE
	},
	{
		icon: applyIcon,
		activeIcon: applytActive,
		text: "Loans",
		isActive: false,
		link: DASHBOARD_LOAN_ROUTE
	},
	// {
	// 	icon: memoIcon,
	// 	activeIcon: memoIconActive,
	// 	text: "Memo",
	// 	isActive: false,
	// 	link: DASHBOARD_MEMO_ROUTE
	// },
	{
		icon: settingsActive,
		activeIcon: settingsIcon,
		text: "Settings",
		isActive: false,
		link: DASHBOARD_SETTINGS_ROUTE
	}
]

export const loData = [
	{
		icon: dashboardActive,
		activeIcon: dashboardIcon,
		text: "Dashboard",
		isActive: false,
		link: HOME_ROUTE
	},
	{
		icon: userIcon,
		activeIcon: userActive,
		text: "Profile",
		isActive: false,
		link: DASHBOARD_PROFILEROUTE
	},
	{
		icon: applyIcon,
		activeIcon: applytActive,
		text: "Loans",
		isActive: false,
		link: DASHBOARD_LOAN_ROUTE
	},
	{
		icon: applyIcon,
		activeIcon: applyIcon,
		text: "Re-Applicant",
		isActive: false,
		link: DASHBOARD_RE_APPLICANT_ROUTE
	},
	{
		icon: settingsActive,
		activeIcon: settingsIcon,
		text: "Settings",
		isActive: false,
		link: DASHBOARD_SETTINGS_ROUTE
	}
]

export const blogData = [
	{
		icon: dashboardActive,
		activeIcon: dashboardIcon,
		text: "Dashboard",
		isActive: false,
		link: HOME_ROUTE
	},
	{
		icon: BlogIcon,
		activeIcon: BlogActive,
		text: "Blog",
		isActive: false,
		link: DASHBOARD_BLOG_ROUTE
	},
	{
		icon: userIcon,
		activeIcon: userActive,
		text: "Profile",
		isActive: false,
		link: DASHBOARD_PROFILEROUTE
	},

	{
		icon: settingsActive,
		activeIcon: settingsIcon,
		text: "Settings",
		isActive: false,
		link: DASHBOARD_SETTINGS_ROUTE
	}
]
