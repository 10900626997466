import React from "react"
import { ContactTextInputContainer, GeneralTypeSelect } from "./style"
import { ErrorMsgSpan } from "components/TextInput/style"
import { Div, Img } from "globalStyles"
import { ArrowSelectDown } from "utils/assets"

function CustomSelectMain({ children, value, onChange, astheric = false, label, name, mt = "", width = "100%", error, visible, ErrorMt, ...rest }) {
	return (
		<ContactTextInputContainer mt={mt} width={width}>
			<Div display="flex">
				<label htmlFor={name}>
					{label} {astheric && ""}
				</label>
				{astheric && (
					<label style={{ color: "red" }} htmlFor={name}>
						*
					</label>
				)}
			</Div>
			<GeneralTypeSelect>
				<select value={value} onChange={onChange} name={name} {...rest}>
					{children}
				</select>
				<div>
					<Img width="12px" height="12px" src={ArrowSelectDown} alt="select" />
				</div>
			</GeneralTypeSelect>
			{visible && error ? <ErrorMsgSpan ErrorMt={`${mt}`}>{error}</ErrorMsgSpan> : null}
		</ContactTextInputContainer>
	)
}

export default CustomSelectMain
