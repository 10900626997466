import React, { useEffect, useState } from 'react'
import AllMesComp from './MemoTable'
import ReadMesComp from './MemoTable/ReadMesComp'
import SentMesComp from './MemoTable/SentMesComp'
import UnreadMesComp from './MemoTable/UnreadMesComp'

import {
  MemoContent,
  MemoTabDiv,
} from './style'
import { getAllRecievedMemos } from 'services/MemoService'

const MemoTab = () => {
  const [toggleState] = useState(1)


  useEffect(()=>{
    const getMemoData = async()=>{
      const res = await getAllRecievedMemos()
      console.log("memo response", res)
    }
    getMemoData()
  },[])

  return (
    <MemoTabDiv>
      {/* <MemoBloc>
        <AllMessageBtn
          className={toggleState === 1 ? 'tabs active-tabs' : ''}
          onClick={() => toggleTab(1)}
        >
          Receieved Memos
        </AllMessageBtn>
        <ReadMessageBtn
          className={toggleState === 2 ? 'tabs active-tabs' : ' '}
          onClick={() => toggleTab(2)}
        >
          Read
        </ReadMessageBtn>
        <UnreadMessageBtn
          className={toggleState === 3 ? 'tabs active-tabs' : ' '}
          onClick={() => toggleTab(3)}
        >
          Unread
        </UnreadMessageBtn>
        <SentMessageBtn
          className={toggleState === 4 ? 'tabs active-tabs' : ' '}
          onClick={() => toggleTab(4)}
        >
          Sent
        </SentMessageBtn>
      </MemoBloc> */}

      <MemoContent>
        <div
          className={toggleState === 1 ? 'content  active-content' : 'content'}
        >
          <h1>Memos</h1>
          <AllMesComp />
        </div>

        <div
          className={toggleState === 2 ? 'content  active-content' : 'content'}
        >
          <h1>Read Messages</h1>
          <ReadMesComp />
        </div>
        <div
          className={toggleState === 3 ? 'content  active-content' : 'content'}
        >
          <h1>Unread Messages</h1>
          <UnreadMesComp />
        </div>
        <div
          className={toggleState === 4 ? 'content  active-content' : 'content'}
        >
          <h1>Sent Messages</h1>
          <SentMesComp />
        </div>
      </MemoContent>
    </MemoTabDiv>
  )
}

export default MemoTab
