import { ErrorMsgSpan } from "components/TextInput/style"
import React from "react"
import { TextArea, TextInputContainer } from "./style"

function TextAreaBlog({ children, value, onChange, label, name, mt = "", descText = "Write excerpt here...", width = "100%", error, visible, ErrorMt, ...rest }) {
	return (
		<TextInputContainer>
			<label htmlFor={name}>{label}</label>
			<TextArea placeholder={descText} value={value} onChange={onChange} name={name} {...rest} />
			{visible && error ? <ErrorMsgSpan ErrorMt={`${mt}`}>{error}</ErrorMsgSpan> : null}
		</TextInputContainer>
	)
}

export default TextAreaBlog
