import FullScreenLoader from "components/Dashboard/FullScreenLoader"
import React, { useMemo, useState } from "react"
import { useQuery } from "react-query"
import { useTable, usePagination, useGlobalFilter } from "react-table"
import { getDisbursedLoans } from "services/LoanService"
import FilterComp from "../FilterComp"
import { TableContainer, TableDiv } from "../style"
import { DISCOLUMNS } from "./disColumns"
import EmptyData from "components/Emptydata"
import SectionLoader from "components/SectionLoader"
import useAdminData from "hooks/useAdminData"
import DownloadCSVBtn from "../AcctAcceptedLoans/DownloadCsvBtn"


const DisLoansComp = () => {
	const {adminRole} = useAdminData()
	const { data: disbLoans, isLoading } = useQuery("disbursed-loans", getDisbursedLoans)
	const [query, setQuery] = useState("")

	const columns = useMemo(() => DISCOLUMNS, [])
	const data = useMemo(() => {
		const disbLoanna = disbLoans?.data
		? disbLoans.data.filter(
			(loan)=>{
				if(query) return ((loan.applicant?.first_name.toLowerCase().includes(query.toLowerCase())) || (loan.applicant?.surname.toLowerCase().includes(query.toLowerCase())))
				return true
			}
		)
		: []
		return disbLoanna
	}, [disbLoans?.data,query])

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		canPreviousPage,
		canNextPage,
		pageOptions,
		gotoPage,
		pageCount,
		setPageSize,
		state,
		prepareRow
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0 }
		},
		useGlobalFilter,
		usePagination
	)
	const { pageIndex, pageSize } = state
	if (isLoading) return <FullScreenLoader show={true} />

	return (
		<>
			<TableContainer>
			<header className="flex items-center gap-2 my-4">
					<div className="max-w-[500px]">
						<FilterComp filter={query} setFilter={setQuery}/>
					</div>
					<div className="ml-auto flex items-center gap-2">
						{(adminRole === "acc" || adminRole === "es" || adminRole === "lph")
						&& <DownloadCSVBtn fileName="disbursed_loans" data={data}/>
						}
					</div>
				</header>
				<TableDiv {...getTableProps()}>
					<thead>
						{headerGroups?.map(headerGroup => (
							<tr {...headerGroup?.getHeaderGroupProps()}>
								{headerGroup.headers?.map(column => (
									<th {...column?.getHeaderProps()}> {column.render("Header")} </th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page?.map(row => {
							prepareRow(row)
							return (
								<tr {...row?.getRowProps()}>
									{row?.cells?.map(cell => {
										return <td {...cell?.getCellProps()}> {cell?.render("Cell")} </td>
									})}
								</tr>
							)
						})}
					</tbody>
				</TableDiv>
				{isLoading && <SectionLoader />}
				{!isLoading && data.length > pageSize && (
					<div>
						<span>
							Page{" "}
							<strong>
								{pageIndex + 1} of {pageOptions.length}
							</strong>{" "}
						</span>
						<span>
							| Go to page:{" "}
							<input
								type="number"
								defaultValue={pageIndex + 1}
								onChange={e => {
									const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
									gotoPage(pageNumber)
								}}
								style={{ width: "50px" }}
							/>
						</span>
						<select value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
							{[10, 25, 50].map(pageSize => (
								<option key={pageSize} value={pageSize}>
									Show {pageSize}
								</option>
							))}
						</select>
						<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
							{" "}
							{"<<"}{" "}
						</button>
						<button onClick={() => previousPage()} disabled={!canPreviousPage}>
							Previous
						</button>
						<button onClick={() => nextPage()} disabled={!canNextPage}>
							Next
						</button>
						<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
							{" "}
							{">>"}{" "}
						</button>
					</div>
				)}
			</TableContainer>
			{!isLoading && data.length <= 0 ? <EmptyData text="No disbursed loans yet" /> : null}
		</>
	)
}

export default DisLoansComp
