import { BASE_URL } from "services"
import http from "./httpService"
import { useQuery } from "react-query"
import { toast } from "react-hot-toast"
import authStore from "mobx/AuthStore"

const fetchAllMessages = () => {
	return http.get(`${BASE_URL}/messages`)
}
const fetchRepliedMessages = () => {
	return http.get(`${BASE_URL}/messages/replied`)
}

const fetchReadMessages = () => {
	return http.get(`${BASE_URL}/messages/read`)
}
const fetchUnreadMessages = () => {
	return http.get(`${BASE_URL}/messages/unread`)
}

export const fetchSingleMessages = async messageId => {
	try {
		const { data } = await http.get(`${BASE_URL}/messages/view/${messageId}`)
		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}

export const useRepliedMessageData = (onSuccess, onError) => {
	return useQuery("replied-messages", fetchRepliedMessages, {
		onSuccess,
		onError
	})
}
export const useMessageData = (onSuccess, onError) => {
	return useQuery("read-messages", fetchReadMessages, {
		onSuccess,
		onError
	})
}
export const useAllMessageData = (onSuccess, onError) => {
	return useQuery("messages", fetchAllMessages, {
		onSuccess,
		onError
	})
}

export const useUnreadMessageData = (onSuccess, onError) => {
	return useQuery("unread-messages", fetchUnreadMessages, {
		onSuccess,
		onError
	})
}
export const useSingleMessageData = (onSuccess, onError, messageId) => {
	return useQuery(
		["single-message", messageId],
		() => fetchSingleMessages(messageId),
		{ keepPreviousData: true },
		{
			onSuccess,
			onError
		}
	)
}

export async function replyMessage(payload, messageId) {
	authStore.replyingMsg()
	try {
		const { data } = await http.put(`${BASE_URL}/messages/reply_message/${messageId}`, payload)
		toast.success(data.message)
		authStore.replyingMsg()
		return data
	} catch (err) {
		authStore.replyingMsg()
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function SendNewsLetter(payload) {
	authStore.replyingMsg()
	try {
		const { data } = await http.post(`${BASE_URL}/newsletter/send_message`, payload)
		toast.success(data.message)
		authStore.replyingMsg()
		return data
	} catch (err) {
		authStore.replyingMsg()
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}

export const getSubscribersCount = async () => {
	try {
		const { data } = await http.get(`${BASE_URL}/newsletter/count-subscribers`)
		return data
	} catch (error) {}
}
