import TextInput from "components/TextInput"
import { Div, Img } from "globalStyles"
import { useFormik } from "formik"
import * as Yup from "yup"
import React from "react"
import commaNumber from "comma-number"
import {
	FundsImage
	//  NextOfKinImg
} from "utils/assets"
import {
	InfoContainer,
	InfoContent,
	InfoContentLeft,
	InfoContentRight,
	InfoText,
	InputDiv,
	InputWrap,
	//  LoanCompContent,
	//   LoanCompDiv,
	//   LoanCompText,
	ProceedBtn
} from "./style"
import CustomSelectMain from "layout/SelectInput"
// import bankData from "./bankData"
import { empData } from "./empData"
import { BASE_URL } from "services"
import http from "services/httpService"
import useSWR from "swr"
// import { resolveBank } from "services/MiscService"
import { observer } from "mobx-react-lite"
// import { addLoanDetailsUnder } from "services/LoanService"
import loanStore from "mobx/LoanStore"
// import FullScreenLoader from "components/FullScreenLoader"
import "components/Dashboard/CheckBox/radio.css"
import { addLoanDetailsUnder } from "services/ReappLoanService"
import { resolveBank } from "services/MiscService"
import FullScreenLoader from "../FullScreenLoader"
import { useSearchParams } from "react-router-dom"
// import CustomCheckBox from "components/CheckBox"

const LoanComp = ({ loanType = "", id, user, mutate }) => {
const [searchParams] = useSearchParams()
  const qinfo = searchParams.get("q")
  const userId = qinfo.split(" ")[0];
  const loanId = qinfo.split(" ")[1]
  const user_key = { user_key: userId }

	const url = `${BASE_URL}/misc/banks`
	const fetcher = async () => {
		const res = await http.get(url)
		return res?.data?.data
	}

	const { data: banks } = useSWR(url, fetcher)
	const banksDad = banks
		? banks?.sort(function (a, b) {
				const nameA = a.name.toUpperCase()
				const nameB = b.name.toUpperCase()
				if (nameA < nameB) {
					return -1
				}
				if (nameA > nameB) {
					return 1
				}
				return 0
		  })
		: [{ name: "No banks", code: "", id: "hes" }]

	const urlTwo = `${BASE_URL}/re-applicant/admin/users/who_i_am`
	// const urlTwo = `${BASE_URL}/users/who_i_am`
	const fetcherTwo = async () => {
		const res = await http.post(urlTwo, {params:{...user_key}})
		return res?.data?.data
	}

	const { data: userProfile } = useSWR(urlTwo, fetcherTwo)

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			account_name: userProfile?.bank_account?.account_name ? userProfile?.bank_account?.account_name : "",
			account_number: userProfile?.bank_account?.account_number ? userProfile?.bank_account?.account_number : "",
			bank_code: userProfile?.bank_account?.bank_code ? userProfile?.bank_account?.bank_code : "",
			amount: "",
			purpose: "",
			loan_tenure: "",
			employment_status: userProfile?.employment_status ? userProfile?.employment_status : ""
		},

		onSubmit: async (data, helpers) => {
			if (loanType === "undergraduate") {
				const selectedBank = banks?.filter(bank => bank.code === data.bank_code)

				const payload = { ...data }
				payload.loanId = loanId
				payload.user_key = userId

				payload.id = id
				payload.bank_name = selectedBank[0]?.name

				const underResponse = await addLoanDetailsUnder(payload)
				if (!underResponse.error) {
					mutate()
				}
			}
			if (loanType === "postgraduate") {
				const selectedBank = banks?.filter(bank => bank.code === data.bank_code)

				const payload = { ...data }
				payload.id = id
				payload.bank_name = selectedBank[0]?.name

				const underResponse = await addLoanDetailsUnder(payload)
				if (!underResponse.error) {
					mutate()
				}
			}
			if (loanType === "professional") {
				const selectedBank = banks?.filter(bank => bank.code === data.bank_code)

				const payload = { ...data }
				payload.id = id
				payload.bank_name = selectedBank[0]?.name

				const underResponse = await addLoanDetailsUnder(payload)
				if (!underResponse.error) {
					mutate()
				}
			}
		},

		validationSchema: Yup.object({
			account_name: Yup.string().max(50).required().label("Account Name"),
			account_number: Yup.string().min(10).max(10).required().label("Account Number"),
			bank_code: Yup.string().max(100).required().label("Bank Name"),
			amount: Yup.string().required().label("How Much do you need"),
			purpose: Yup.string().max(150).required().label("Purpose of loan"),
			employment_status: Yup.string().max(255).required().label("Employment Status"),
			loan_tenure: Yup.number().required().label("Loan Tenure")
		})
	})

	const checkBankName = async () => {
		const resolvePayload = {
			bank_code: formik.values.bank_code,
			account_number: formik.values.account_number
		}

		const response = await resolveBank(resolvePayload)

		if (response.data.data && formik.values.account_number.length === 10 && formik.values.account_name === "") {
			formik.setFieldValue("account_name", response?.data?.data?.account_name)
		} else {
			formik.setFieldValue("account_name", "")
		}
	}

	if (formik.values.account_number.length < 10 && formik.values.account_name) {
		formik.setFieldValue("account_name", "")
	}
	if (formik.values.account_number.length === 10 && formik.values.account_name === "") {
		checkBankName()
	} else {
	}
	if (!banks) return <FullScreenLoader show={true} />

	return (
		<InfoContainer>
			<InfoContent>
				<InfoContentLeft onSubmit={formik.handleSubmit}>
					<InfoText>Loan Details</InfoText>
					<InputDiv>
						<InputWrap>
							<TextInput
								astheric={true}
								label="How Much do you need"
								type="number"
								placeholder="200,000"
								name="amount"
								id="amount"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.amount}
								error={formik.errors.amount}
								visible={formik.touched.amount}
							/>
							<CustomSelectMain
								astheric={true}
								label="Bank Name"
								name="bank_code"
								id="bank_code"
								disabled={true}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.bank_code}
								error={formik.errors.bank_code}
								visible={formik.touched.bank_code}
							>
								<option style={{ color: "grey" }} value="">
									Select Bank
								</option>
								{banksDad?.map(bank => (
									<option className="active-option" key={bank?.id} value={bank.code}>
										{bank.name}
									</option>
								))}
							</CustomSelectMain>
							<TextInput
								astheric={true}
								label="Account Number"
								placeholder="Account Number"
								name="account_number"
								id="account_number"
								disabled={true}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.account_number}
								error={formik.errors.account_number}
								visible={formik.touched.account_number}
							/>
							<Div width="100%" mt="1.5rem">
								<label style={{ marginBottom: "5px" }}>
									Loan Tenure<span style={{ color: "red" }}>*</span>
								</label>
								<div className="wrapper">
									<input type="radio" name="loan_tenure" value="12" onChange={formik.handleChange} id="option-1" />
									<input type="radio" name="loan_tenure" value="24" onChange={formik.handleChange} id="option-2" />
									<label htmlFor="option-1" class="option option-1">
										<div className="dot"></div>
										<span>12months</span>
									</label>
									<label htmlFor="option-2" className="option option-2">
										<div className="dot"></div>
										<span>24months</span>
									</label>
								</div>
								{formik.touched.loan_tenure && formik.errors.loan_tenure && <span style={{ color: "red" }}>{formik.errors.loan_tenure}</span>}

								<Div mt="1.5rem">
									{formik.values.amount && formik.values.loan_tenure && !formik.errors.loan_tenure && (
										<p style={{ fontSize: "18px", color: "black" }}>
											Monthly Repayment Amount: ₦{commaNumber(((JSON.parse(formik.values.amount) + 10) / JSON.parse(formik.values.loan_tenure)).toFixed(2))}
										</p>
									)}
									{formik.values.amount && formik.values.loan_tenure && !formik.errors.loan_tenure && (
										<p style={{ fontSize: "11px", marginTop: "5px" }}>You start repaying six(6) months after disbursement</p>
									)}
								</Div>
							</Div>
						</InputWrap>

						<InputWrap>
							<TextInput
								astheric={true}
								label="Purpose of loan"
								placeholder="School Fees"
								name="purpose"
								id="purpose"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.purpose}
								error={formik.errors.purpose}
								visible={formik.touched.purpose}
							/>
							<TextInput
								astheric={true}
								label="Account Name."
								placeholder="John Doe"
								name="account_name"
								id="account_name"
								disabled
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.account_name}
								error={formik.errors.account_name}
								visible={formik.touched.account_name}
							/>
							<CustomSelectMain
								label="Employment Status"
								placeholder="Student"
								name="employment_status"
								id="employment_status"
								disabled
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.employment_status}
								error={formik.errors.employment_status}
								visible={formik.touched.employment_status}
							>
								<option style={{ color: "grey" }} value="">
									{/* -------- */}
								</option>
								{empData?.map(local => (
									<option className="active-option" key={local.id} value={local.value}>
										{local.label}
									</option>
								))}
							</CustomSelectMain>
						</InputWrap>
					</InputDiv>

					<ProceedBtn type="submit" disabled={loanStore.isLoading}>
						{loanStore.isLoading ? "Loading..." : "Proceed"}
					</ProceedBtn>
				</InfoContentLeft>
				<InfoContentRight>
					<Img src={FundsImage} alt="image" />
				</InfoContentRight>
			</InfoContent>
		</InfoContainer>
	)
}

export default observer(LoanComp)
