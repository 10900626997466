import LoanComp from 'components/Dashboard/Loan'
import MainLayout from 'layout/MainLayout'
import React from 'react'

const LoanPage = () => {
  return (
    <MainLayout>
      <LoanComp />
    </MainLayout>
  )
}

export default LoanPage