import AuthSideNav from "components/AuthNav/SideBar"
import { observer } from "mobx-react-lite"
import React from "react"
import { DashboardBody, DashboardLayout } from "./style"
import useAdminData from "hooks/useAdminData"

const Mainlayout = props => {
	const {userProfile} = useAdminData()
	return (
		<div className="body-bg">
			<DashboardLayout>
				<AuthSideNav user={userProfile} />
				<DashboardBody>{props.children}</DashboardBody>
			</DashboardLayout>
		</div>
	)
}

export default observer(Mainlayout)
