import React, { useState } from "react"
import { ActionBtnBlog, BlogCardDiv, EditLin, ImageCard, NewsCardCont, ViewsContainer } from "./style"
import millify from "millify"
import { Div } from "globalStyles"
import ConfirmAction from "components/ConfirmAction/confirmAction"
import { FormsModal } from "components/ConfirmAction/style"
import { deleteBlogPost } from "services/BlogService"
import { observer } from "mobx-react-lite"
import blogStore from "mobx/BlogStore"
import { useQueryClient } from "react-query"
function BlogCard({ blog }) {
	const [show, setShow] = useState(false)
	const [post, setPost] = useState("")
	const queryClient = useQueryClient()
	function handleDelete(payload) {
		setPost(payload)
		setShow(!show)
	}

	function handleCloseDelete(payload) {
		setPost("")
		setShow(!show)
	}

	async function handleSubmitDelete() {
		const response = await deleteBlogPost(post)
		if (!response.error) {
			queryClient.invalidateQueries("posts")
			setPost("")
			setShow(!show)
		}
	}
	return (
		<BlogCardDiv>
			{show && (
				<FormsModal show={show}>
					<ConfirmAction
						onContinue={!blogStore.isLoading && handleSubmitDelete}
						onCancel={!blogStore.isLoading && handleCloseDelete}
						label="Are you sure, you want delete this post?"
						loadingLabel={blogStore.isLoading ? "Deleting..." : ""}
					/>
				</FormsModal>
			)}
			<NewsCardCont>
				<ImageCard>
					<img src={blog?.image?.url} alt={blog?.title} />
				</ImageCard>
				{/* <h1>Helllo new title from above</h1> */}
				<h2>{blog?.title}</h2>
				<Div width="100%" display="flex" alignI="center" justify="space-between" align="flex-end" mt="1.5rem">
					<ViewsContainer>
						<svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M11 0C6 0 1.73 3.11 0 7.5C1.73 11.89 6 15 11 15C16 15 20.27 11.89 22 7.5C20.27 3.11 16 0 11 0ZM11 12.5C8.24 12.5 6 10.26 6 7.5C6 4.74 8.24 2.5 11 2.5C13.76 2.5 16 4.74 16 7.5C16 10.26 13.76 12.5 11 12.5ZM11 4.5C9.34 4.5 8 5.84 8 7.5C8 9.16 9.34 10.5 11 10.5C12.66 10.5 14 9.16 14 7.5C14 5.84 12.66 4.5 11 4.5Z"
								fill="#565151"
							/>
						</svg>
						<span>{millify(blog?.views)}</span>
						<p style={{ marginLeft: "10px" }}>{blog?.readTime}mins read</p>
					</ViewsContainer>
					<ViewsContainer>
						<EditLin to={`/blog/${blog?.blogUrl}/${blog?.id}`} bgColor="#020254">
							<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M16.3 6.175L12.05 1.975L13.45 0.575C13.8333 0.191667 14.3043 0 14.863 0C15.421 0 15.8917 0.191667 16.275 0.575L17.675 1.975C18.0583 2.35833 18.2583 2.821 18.275 3.363C18.2917 3.90433 18.1083 4.36667 17.725 4.75L16.3 6.175ZM14.85 7.65L4.25 18.25H0V14L10.6 3.4L14.85 7.65Z"
									fill="white"
								/>
							</svg>
						</EditLin>
						<ActionBtnBlog ml="8px" onClick={() => handleDelete(blog.id)}>
							<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3C0.716667 3 0.479 2.90433 0.287 2.713C0.0956668 2.521 0 2.28333 0 2C0 1.71667 0.0956668 1.479 0.287 1.287C0.479 1.09567 0.716667 1 1 1H5C5 0.716667 5.096 0.479 5.288 0.287C5.47933 0.0956666 5.71667 0 6 0H10C10.2833 0 10.521 0.0956666 10.713 0.287C10.9043 0.479 11 0.716667 11 1H15C15.2833 1 15.5207 1.09567 15.712 1.287C15.904 1.479 16 1.71667 16 2C16 2.28333 15.904 2.521 15.712 2.713C15.5207 2.90433 15.2833 3 15 3V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM5 13C5 13.2833 5.096 13.5207 5.288 13.712C5.47933 13.904 5.71667 14 6 14C6.28333 14 6.521 13.904 6.713 13.712C6.90433 13.5207 7 13.2833 7 13V6C7 5.71667 6.90433 5.479 6.713 5.287C6.521 5.09567 6.28333 5 6 5C5.71667 5 5.47933 5.09567 5.288 5.287C5.096 5.479 5 5.71667 5 6V13ZM9 13C9 13.2833 9.096 13.5207 9.288 13.712C9.47933 13.904 9.71667 14 10 14C10.2833 14 10.521 13.904 10.713 13.712C10.9043 13.5207 11 13.2833 11 13V6C11 5.71667 10.9043 5.479 10.713 5.287C10.521 5.09567 10.2833 5 10 5C9.71667 5 9.47933 5.09567 9.288 5.287C9.096 5.479 9 5.71667 9 6V13Z"
									fill="white"
								/>
							</svg>
						</ActionBtnBlog>
					</ViewsContainer>
				</Div>
			</NewsCardCont>
		</BlogCardDiv>
	)
}

export default observer(BlogCard)
