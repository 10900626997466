import millify from "millify"
import TextInput from "components/TextInput"
import { useFormik } from "formik"
import React, { useMemo } from "react"
import { SendNewsLetter, getSubscribersCount } from "services/MessageService"
import * as Yup from "yup"
import { FormContent, FormFor, SendNewsBtn } from "./style"
import TextAreaComp from "components/TextArea/TextAreaComp"
import authStore from "mobx/AuthStore"
import { observer } from "mobx-react-lite"
import { Div } from "globalStyles"
import { useQuery } from "react-query"
import SectionLoader from "components/SectionLoader"
function NewsLetterForm() {
	const formik = useFormik({
		enableReinitialize: false,
		initialValues: {
			subject: "",
			message: ""
		},
		onSubmit: async (data, helpers) => {
			const response = await SendNewsLetter(data)
			if (!response.error) {
				helpers.resetForm()
			}
		},
		validationSchema: Yup.object({
			subject: Yup.string().max(255).required().label("Subject"),
			message: Yup.string().min(8).required("Message").label("Message")
		})
	})
	const { isLoading, data: subscribers } = useQuery("subscribers", getSubscribersCount)
	const data = useMemo(() => {
		const subs = subscribers?.data ? subscribers?.data : {}
		return subs
	}, [subscribers?.data])

	return (
		<FormContent>
			<h3>NewsLetter</h3>

			{isLoading ? <SectionLoader /> : <h4>Total Number of Subscribers: {millify(data?.count ? data?.count : 0)}</h4>}

			<FormFor onSubmit={formik.handleSubmit}>
				<Div display="flex" width="100%" justify="center" mt="-20px">
					<h2>Send NewsLetter</h2>
				</Div>
				<TextInput
					label="Subject:"
					type="text"
					name="subject"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.subject}
					error={formik.errors.subject}
					visible={formik.touched.subject}
					disabled={authStore.replying}
				/>
				<TextAreaComp
					label="Message:"
					name="message"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.message}
					error={formik.errors.message}
					visible={formik.touched.message}
					disabled={authStore.replying}
					placeholder="Type your message here..."
				/>
				<SendNewsBtn disabled={authStore.replying}>{authStore.replying ? "Send..." : "Send Newsletter"}</SendNewsBtn>
			</FormFor>
		</FormContent>
	)
}

export default observer(NewsLetterForm)
