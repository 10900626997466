import styled from "styled-components"
import Colors from "utils/colors"

export const MemoTabDiv = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	width: calc(100% - 40px);
	/* height: 300px; */
	/* background: #f1f1f1; */
	margin: 20px auto 0;
	word-break: break-all;
	/* border: 1px solid rgba(0, 0, 0, 0.274); */
`

export const MemoBloc = styled.div`
	display: flex;
	gap: 5px;
	border: none;
	.active-tabs {
		background: white;
		color: white;
		background: ${Colors.primaryLight};
		border-bottom: 1px solid transparent;
	}

	.active-tabs::before {
		content: "";
		display: block;
		position: absolute;
		top: 45px;
		left: 50%;
		transform: translateX(-50%);
		width: calc(100%);
		height: 2px;
		background: rgb(88, 147, 241);
		/* background: rgb(88, 147, 241); */
	}

	button {
		border: none;
	}
`

export const MemoContent = styled.div`
	flex-grow: 1;

	.content {
		background: white;
		padding: 0px;
		width: 100%;
		height: 100%;
		display: none;
	}
	.content h2 {
		padding: 0px 0 5px 0px;
	}
	.content hr {
		width: 100px;
		height: 2px;
		background: #222;
		margin-bottom: 5px;
	}
	.content p {
		width: 100%;
		height: 100%;
	}
	.active-content {
		display: block;
	}
`

export const AllMessageBtn = styled.button`
	width: 50%;
	padding: 15px;
	text-align: center;
	color: white;
	cursor: pointer;
	border-bottom: 1px solid rgba(0, 0, 0, 0.274);
	box-sizing: content-box;
	position: relative;
	outline: none;
	background: linear-gradient(73.6deg, rgba(5, 146, 36, 0.97) 4.94%, rgba(5, 146, 36, 0.97) 71.35%);
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
`
export const ReadMessageBtn = styled.button`
	width: 50%;
	padding: 15px;
	text-align: center;
	color: white;
	cursor: pointer;
	border-bottom: 1px solid rgba(0, 0, 0, 0.274);
	box-sizing: content-box;
	position: relative;
	outline: none;
	background: gray;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
`
export const UnreadMessageBtn = styled.button`
	width: 50%;
	padding: 15px;
	text-align: center;
	color: white;
	cursor: pointer;
	border-bottom: 1px solid rgba(0, 0, 0, 0.274);
	box-sizing: content-box;
	position: relative;
	outline: none;
	background: orangered;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
`
export const SentMessageBtn = styled.button`
	width: 50%;
	padding: 15px;
	text-align: center;
	color: white;
	cursor: pointer;
	border-bottom: 1px solid rgba(0, 0, 0, 0.274);
	box-sizing: content-box;
	position: relative;
	outline: none;
	background: yellowgreen;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
`

export const MemoDetailsDiv = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
`
export const MsgDiv = styled.div`
	width: 100%;
	display: flex;
	/* justify-content: center; */
	align-items: center;
	gap: 10px;
`

export const MsgBtn = styled.button`
	width: 100px;
	height: 25px;
	border: none;
	outline: none;
	background: skyblue;
	color: white;
	border-radius: 10px;
	cursor: pointer;
`

export const MsgBody = styled.div`
	flex-grow: 1;

	.cont {
		background: white;
		padding: 20px;
		width: 100%;
		height: 100%;
		display: none;
	}
	.cont h2 {
		padding: 0px 0 5px 0px;
	}
	.cont hr {
		width: 100px;
		height: 2px;
		background: #222;
		margin-bottom: 5px;
	}
	.cont p {
		width: 100%;
		height: 100%;
	}
	.active-cont {
		display: block;
	}
`
export const MemoContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: ${Colors.primary};
	height: 56px;
	color: ${Colors.white};
	width: calc(100% - 30px);
	padding: 0px 15px;
	cursor: pointer;
	margin-top: 15px;
	border-radius: 5px;
	margin-bottom: 15px;
`
