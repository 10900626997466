import CommaNumber from "comma-number"


export const ACCEPTED_COLUMNS = [
	{
		title: "First Name",
		render: (originalRow) => {
				return <p>{originalRow?.applicant?.surname} {originalRow?.applicant?.first_name}</p>
		}
	},
	{
		title: "LGA",
		render: (originalRow) => <p>{originalRow?.applicant?.lga}</p>
	},
	{
		title: "Institution",
		render: (originalRow) => <div style={{ maxWidth: "250px" }}>{originalRow?.institution?.name}</div>
	},
	{
		title: "Amount",
		render: (originalRow) => <p>₦{CommaNumber(originalRow?.amount)}</p>
	},
	{
		title: "Loan Type",
		dataIndex: "loan_type"
	},
	{
		title: "Status",
		render: (originalRow) => {
			return <p className="capitalize">{originalRow.status}</p>
		}
	},
	{
		title: "Reffered By",
		render: (originalRow) => <p>{originalRow?.refferedBy}</p>
	},
	// {
	// 	title: "View Details",
	// 	render: (originalRow) => originalRow?.status && <Link to={`${DASHBOARD_LOAN_DETAILS}/${originalRow.id}`}>View</Link>
	// }
]




