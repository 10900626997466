import loanStore from "mobx/LoanStore"
import { toast } from "react-hot-toast"
import { BASE_URL } from "services"
import http from "./httpService"

export async function getAllBanks(payload) {
	try {
		const { data } = await http.get(`${BASE_URL}/misc/banks`, payload)
		toast.success(data.message)
		// authStore.resending_otp()
		// authStore.setCurrentStep("verify")
		// console.log("Helll Data email", data)

		return data
	} catch (err) {
		if (err && err.response) {
			// authStore.resending_otp()
			err?.response?.data?.error && toast.error(err.response.data.message)
			// console.log("Helll", err.response.data)
		} else {
			// authStore.resending_otp()
			toast.error("Network Error")
		}
	}
}

export async function resolveBank(payload) {
	try {
		const { data } = await http.post(`${BASE_URL}/misc/resolve_bank`, payload)
		toast.success(data.message)

		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}

export async function resolveBVN(payload) {
	loanStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/misc/resolve_bvn`, payload)
		toast.success(data.message)
		loanStore.setCurrentStepUnder("")
		loanStore.loading()
		
		return data
	} catch (err) {
		loanStore.loading()
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function miscUplaod(payload) {
	try {
		const { data } = await http.post(`${BASE_URL}/misc/upload`, payload)
		toast.success(data.message)
		return data
	} catch (err) {
		if (err && err.response) {
			// authStore.resending_otp()
			err?.response?.data?.error && toast.error(err.response.data.message)
			// console.log("Helll", err.response.data)
			return err?.response?.data
		} else {
			// authStore.resending_otp()
			toast.error("Network Error")
			return err
		}
	}
}

export async function checkPortalStatus() {
	try {
	  const { data } = await http.get(`${BASE_URL}/misc/portal-status`);
	  if(data.data.loanPortal.toLowerCase() === "close"){
		toast.error("Portal is close");
		return {error: true, message:`Portal is ${data.data.loanPortal}`}
	  }
	  return data
	} catch (err) {
		if (err && err.response) {
			// err?.response?.data?.error && toast.error(err.response.data.message)
			return err?.response?.data
		} else {
			toast.error("Network Error")
			return err
		}
	}
}
export async function isRe_apply(type) {
	try {
		const { data } = await http.post(`${BASE_URL}/loans/re-apply`, {type});
		return data
	} catch (err) {
		if (err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
			return err?.response?.data
		} else {
			toast.error("Network Error")
			return err
		}
	}
  }