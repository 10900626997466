import { Link } from "react-router-dom"
import { DASHBOARD_LOAN_DETAILS } from "routes"
import CommaNumber from "comma-number"
import Checkbox from "components/Checkbox"

export const APPCOLUMNS = [
	{
		Header: "Pick",
		accessor: (originalRow) => <Checkbox value={originalRow?._id} />
	},
	{
		Header: "First Name",
		accessor: (originalRow) => originalRow?.applicant?.first_name + " " +  originalRow?.applicant?.surname
	},
	{
		Header: "LGA",
		accessor: (originalRow,) => originalRow?.applicant?.lga
	},
	{
		Header: "Institution",
		accessor: (originalRow,) => <div style={{ maxWidth: "250px" }}>{originalRow?.institution?.name}</div>
	},
	{
		Header: "Amount",
		accessor: (originalRow,) => `₦${CommaNumber(originalRow?.amount)}`
	},
	{
		Header: "Loan Type",
		accessor: "loan_type"
	},
	{
		Header: "Reffered By",
		accessor: (originalRow,) => originalRow?.reffered_by_name
	},
	{
		Header: "View Details",
		accessor: (originalRow,) => originalRow?.status && <Link to={`${DASHBOARD_LOAN_DETAILS}/${originalRow.id}`}>View</Link>
	}
]
export const APPCOLUMNS_V2 = [
	{
		title: 'Pick',
		fixed:"left",
		key: 'pick',
		width:100,
		render: (_,originalRow) => <Checkbox value={originalRow?._id} />
	},
	{
		title: "Applicant",
		fixed:"left",
		width:150,
		render: (_,originalRow) => originalRow?.applicant?.first_name + " " +  originalRow?.applicant?.surname
	},
	{
		title: "LGA",
		key:"lga",
		width:150,
		render: (_,originalRow,) => originalRow?.applicant?.lga
	},
	{
		title: "Institution",
		key:"institution",
		width:250,
		render: (_,originalRow,) => <div style={{ maxWidth: "250px" }}>{originalRow?.institution?.name}</div>
	},
	{
		title: "Amount",
		key:"amount",
		width:100,
		render: (_,originalRow,) => `₦${CommaNumber(originalRow?.amount)}`
	},
	{
		title: "Loan Type",
		dataIndex: "loan_type",
		width:200,
	},
	{
		title: "Reffered By",
		key:"reffered_by",
		width:150,
		render: (_,originalRow,) => originalRow?.reffered_by_name
	},
	{
		title: "View Details",
		key:"details",
		fixed:"right",
		width:150,
		render: (_,originalRow,) => originalRow?.status && <Link to={`${DASHBOARD_LOAN_DETAILS}/${originalRow.id}`}><button className="px-6 py-1 justify-center font-semibold rounded-full border border-secondary-300 bg-secondary-50 text-secondary-700">View</button></Link>
	},
]
export const APPCOLUMNS_V3 = [
	{
		title: 'Revised as Requested',
		key: 'revison_requested',
		fixed:"left",
		width:200,
		render: (_,originalRow) => {
			if(originalRow.edit_requested) return (
				<p className="text-center font-medium">
					{originalRow.edit_request_responded ? "Yes" :"No"}
				</p>
			)
			return <p className="text-center">N/A</p>
		}
	},
	{
		title: "Applicant",
		fixed:"left",
		width:150,
		render: (_,originalRow) => originalRow?.applicant?.first_name + " " +  originalRow?.applicant?.surname
	},
	{
		title: "LGA",
		key:"lga",
		width:150,
		render: (_,originalRow,) => originalRow?.applicant?.lga
	},
	{
		title: "Institution",
		key:"institution",
		width:250,
		render: (_,originalRow,) => <div style={{ maxWidth: "250px" }}>{originalRow?.institution?.name}</div>
	},
	{
		title: "Amount",
		key:"amount",
		width:100,
		render: (_,originalRow,) => `₦${CommaNumber(originalRow?.amount)}`
	},
	{
		title: "Loan Type",
		dataIndex: "loan_type",
		width:200,
	},
	{
		title: "Reffered By",
		key:"reffered_by",
		width:150,
		render: (_,originalRow,) => originalRow?.reffered_by_name
	},
	{
		title: "View Details",
		key:"details",
		fixed:"right",
		width:150,
		render: (_,originalRow,) => originalRow?.status && <Link to={`${DASHBOARD_LOAN_DETAILS}/${originalRow.id}`}><button className="px-6 py-1 justify-center font-semibold rounded-full border border-secondary-300 bg-secondary-50 text-secondary-700">View</button></Link>
	}
]

export function getAppColumns(admin_role,isEditRequestData=false){
	if(isEditRequestData){
		return APPCOLUMNS_V3.filter((col)=>{
			if(col.title === "Pick" && admin_role !== "lph") return false;
			return true
		})
	}
	return APPCOLUMNS_V2.filter((col)=>{
		if(col.title === "Pick" && admin_role !== "lph") return false;
		return true
	})
}
