import { Flex, Table } from "antd";
import { ACCEPTED_COLUMNS } from "./columns";
import DeleteLoan from "./components/DeleteLoan";

export default function SuperuserTable({dataSource,loading,refetch}){

        const customColumns = [
            ...ACCEPTED_COLUMNS,
            {
                title: "",
                render: (originalRow) => <DeleteLoan refetch={refetch} id={originalRow?.id} />
            },
        ]

        return (
            <Flex gap="middle" vertical>
                <Table columns={customColumns} loading={loading} dataSource={dataSource} />
            </Flex>
        );
}