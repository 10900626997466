import styled from "styled-components/macro"
import Colors from "utils/colors"

export const FormContent = styled.div`
	width: calc(100% - 40px);
	padding: 20px 20px;
`
export const FormFor = styled.form`
	width: 60%;
`

export const SendNewsBtn = styled.button`
	height: 35px;
	width: 200px;
	border: none;
	background: ${Colors.primaryLight};
	color: ${Colors.white};
	margin-top: 1.5rem;
	border-radius: 15px;
	cursor: pointer;
`
