import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { DASHBOARD_APPLY_POSTGRAD_ROUTE, DASHBOARD_APPLY_PROFESSIONAL_ROUTE, DASHBOARD_APPLY_UNDERGRAD_ROUTE } from "routes"
import { DashboardBtn, DashboardBtnDiv, DashboardBtnText, DashboardHomeContainer, DashboardTextDiv, HomeHeaderText, HomeParaText } from "./style"
import { checkPortalStatus, isRe_apply } from "services/MiscService"
import toast from "react-hot-toast"
import styled from "styled-components"

const ReappDashboardHome = () => {

	const DialogBox = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	z-index: 1000;
  
	@media screen and (max-width: 768px) {
	  width: 80%;
	}
  `;
  
  const DialogButton = styled.button`
	margin-right: 10px;
	padding: 8px 16px;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	
	&:hover {
	  background-color: #0056b3;
	}
  
	@media screen and (max-width: 768px) {
	  display: block;
	  margin-bottom: 10px;
	  width: 100%;
	}
  `;


	const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [reapplicant, setReapplicant] = useState(false);
  const [selection, setSelection] = useState(false);
  const [loantype, setLoantype] = useState("");

const startLoandProcess = async () => {
  try {
 console.log(reapplicant)
		if (reapplicant === true) {
			// Check if re-applicant
			const checkReapply = await isRe_apply(loantype);
			if (!checkReapply.error) {
			  throw new Error(checkReapply.message);
			}
		}else{

      // Check portal status
      const status = await checkPortalStatus();
      if (!status.error) {
        if (loantype === "undergraduate") navigate(DASHBOARD_APPLY_UNDERGRAD_ROUTE);
        else if (loantype === "postgraduate") navigate(DASHBOARD_APPLY_POSTGRAD_ROUTE);
        else if (loantype === "professional") navigate(DASHBOARD_APPLY_PROFESSIONAL_ROUTE);
      }
    }
    setReapplicant(false)

    }
	catch (error) {
      toast.error(error?.message);
    }
}
  const handleCheckPortal = async (value) => {

    try { 
    setLoantype(value)
		setShowDialog(true)
    }
	catch (error) {
      toast.error(error?.message);
    }
  };
  const handleDialogFalse = async (value) => {
    

    try { 
   // Check portal status
   const status = await checkPortalStatus();
   if (!status.error) {
     if (loantype === "undergraduate") navigate(DASHBOARD_APPLY_UNDERGRAD_ROUTE);
     else if (loantype === "postgraduate") navigate(DASHBOARD_APPLY_POSTGRAD_ROUTE);
     else if (loantype === "professional") navigate(DASHBOARD_APPLY_PROFESSIONAL_ROUTE);
   }
		setShowDialog(false)
    }
	catch (error) {
      toast.error(error?.message);
    }
  };

  
  const handleDialogButtonClick = async (isFirstTimeApplicant) => {
    try {
		// Display dialog box
    const checkReapply = await isRe_apply(loantype);
    if (!checkReapply.error) {
      throw new Error(checkReapply.message);
    }
  
	    setShowDialog(false);
}
 catch (error) {
      toast.error(error?.message);
    }
  };

	return (
		<DashboardHomeContainer>
			<DashboardTextDiv>
				<HomeHeaderText>
					Byshelb is <span>making it easy</span> for
				</HomeHeaderText>
				<HomeHeaderText>students to go through school</HomeHeaderText>
				<HomeParaText>We have distributed loans to over 5,000 Bayelsans studying across Nigerian institutions.</HomeParaText>
			</DashboardTextDiv>

      <DashboardBtnDiv>
		
        <DashboardBtn  onClick={e=>handleCheckPortal("undergraduate")}>
          {/* <Link to={DASHBOARD_APPLY_UNDERGRAD_ROUTE}> */}
            <DashboardBtnText onClick={e=>handleCheckPortal("undergraduate")}>Apply For</DashboardBtnText>
            <DashboardBtnText onClick={e=>handleCheckPortal("undergraduate")}>Undergraduate Loan</DashboardBtnText>
		
        </DashboardBtn>
        <DashboardBtn onClick={e=>handleCheckPortal("postgraduate")}>
          {/* <Link to={DASHBOARD_APPLY_POSTGRAD_ROUTE}> */}
            <DashboardBtnText onClick={e=>handleCheckPortal("postgraduate")} >Apply For</DashboardBtnText>
            <DashboardBtnText onClick={e=>handleCheckPortal("postgraduate")}>Postgraduate Loan</DashboardBtnText>
		
        </DashboardBtn>
        <DashboardBtn onClick={e=>handleCheckPortal("professional")}>
          {/* <Link to={DASHBOARD_APPLY_PROFESSIONAL_ROUTE}> */}
            <DashboardBtnText onClick={e=>handleCheckPortal("professional")}>Apply For</DashboardBtnText>
            <DashboardBtnText onClick={e=>handleCheckPortal("professional")}>Professional Study Loan</DashboardBtnText>

        </DashboardBtn>

      </DashboardBtnDiv>
    </DashboardHomeContainer>
  )
}

export default ReappDashboardHome