import React from "react"
import { CloseBtnDetails, ItemImage, MoreDetContyainer } from "./style"
import StaffItem from "./StaffItem"
import { AuthNavUser, CloseIcon } from "utils/assets"
import { Img } from "globalStyles"
import { observer } from "mobx-react-lite"
import staffStore from "mobx/StaffStore"
import { ActivateStaffBtn, DeActivateStaffBtn } from "../Admin Table/style"

function Staffdetails({ payload, close }) {
	// function calculate_age(dob) {
	// 	const g = new Date(dob)
	// 	var diff_ms = Date.now() - g.getTime()
	// 	var age_dt = new Date(Date.now() - diff_ms)
	// 	return Math.abs(age_dt.getUTCFullYear() - new Date().getFullYear())
	// }

	return (
		<MoreDetContyainer>
			<CloseBtnDetails onClick={close}>
				<Img src={CloseIcon} alt="Close" />
			</CloseBtnDetails>
			<ItemImage>
				<Img src={payload?.avatar?.url ? payload?.avatar?.url : AuthNavUser} alt="Student" />
			</ItemImage>
			<h5 style={{ marginBottom: "-10px", marginLeft: "15px" }}>Personal info</h5>
			<StaffItem title="Name:" value={`${payload.surname} ${payload?.first_name} ${payload?.middle_name}`} />
			<StaffItem title="Phone Number:" value={`${payload?.phone}`} />
			{/* <StaffItem title="Age:" value={calculate_age(payload?.applicant?.dob) <= 1 ? `${calculate_age(payload?.applicant?.dob)}year` : `${calculate_age(payload?.applicant?.dob)}years`} /> */}
			<StaffItem title="Email:" value={payload?.email ? payload?.email : "Not Provided"} />
			<StaffItem title="Address:" value={payload?.address ? payload?.address : "Not Provided"} />
			<StaffItem title="Hometown:" value={payload?.hometown ? payload?.hometown : "Not Provided"} />
			<StaffItem title="Gender:" value={payload?.gender ? payload?.gender : "Not Provided"} />
			<StaffItem title="L.G.A:" value={payload?.lga ? payload?.lga : "Not Provided"} />
			{(payload?.status === "deactivated" || payload?.status === "suspended") && <ActivateStaffBtn onClick={() => staffStore.showConfirmPop()}>Activate Staff</ActivateStaffBtn>}
			{payload?.status === "activated" && <DeActivateStaffBtn onClick={() => staffStore.showDeactivatePop()}>Deactivate Staff</DeActivateStaffBtn>}
		</MoreDetContyainer>
	)
}

export default observer(Staffdetails)
