import { Link } from "react-router-dom"
import styled from "styled-components/macro"
import Colors from "utils/colors"

export const MainPostDiv = styled.div`
	width: calc(100% - 40px);
	disabled: flex;
	padding: 0px 20px;
	flex-direction: column;
`
export const AddPostLink = styled(Link)`
	width: 180px;
	height: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	background-color: ${Colors.primary};
	color: ${Colors.white};
	border-radius: 15px;
`
