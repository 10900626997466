import styled from "styled-components/macro"


export const DashboardLayout = styled.div`
width:100%;
height:100vh;
display:flex;


`
export const DashboardBody = styled.div`
width:calc(100% - 230px);
height:100vh;
display:flex;
flex-direction:column;
flex-wrap:wrap;
overflow-y:scroll;
/* background-color:red; */
`