import FilterComp from "components/Dashboard/Loan/FilterComp"
import { TableContainer, TableDiv } from "components/Dashboard/Loan/style"
import React, { useMemo } from "react"
import { useQuery } from "react-query"

import { useTable, usePagination, useGlobalFilter } from "react-table"
import { getSentMemo } from "services/MemoService"

import { COLUMNS } from "./columns"
import SectionLoader from "components/SectionLoader"
import EmptyData from "components/Emptydata"

const LPHMemoTable = () => {
	const { data: recMemodata, isLoading } = useQuery("received-memo", getSentMemo)

	const columns = useMemo(() => COLUMNS, [])
	const data = useMemo(() => {
		const receivedMemo = recMemodata?.data ? recMemodata?.data : []
		return receivedMemo
	}, [recMemodata?.data])

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		canPreviousPage,
		canNextPage,
		pageOptions,
		gotoPage,
		pageCount,
		setPageSize,
		state,
		setGlobalFilter,
		prepareRow
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0 }
		},
		useGlobalFilter,
		usePagination
	)
	const { pageIndex, pageSize, globalFilter } = state
	return (
		<>
			<TableContainer>
				<FilterComp filter={globalFilter} setFilter={setGlobalFilter} />
				<TableDiv {...getTableProps()}>
					<thead>
						{headerGroups?.map(headerGroup => (
							<tr {...headerGroup?.getHeaderGroupProps()}>
								{headerGroup.headers?.map(column => (
									<th {...column?.getHeaderProps()}> {column.render("Header")} </th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page?.map(row => {
							prepareRow(row)
							return (
								<tr {...row?.getRowProps()}>
									{row?.cells?.map(cell => {
										return <td {...cell?.getCellProps()}> {cell?.render("Cell")} </td>
									})}
								</tr>
							)
						})}
					</tbody>
				</TableDiv>
				{isLoading && <SectionLoader />}
				{!isLoading && data.length > pageSize && (
					<div>
						<span>
							Page{" "}
							<strong>
								{pageIndex + 1} of {pageOptions.length}
							</strong>{" "}
						</span>
						<span>
							| Go to page:{" "}
							<input
								type="number"
								defaultValue={pageIndex + 1}
								onChange={e => {
									const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
									gotoPage(pageNumber)
								}}
								style={{ width: "50px" }}
							/>
						</span>
						<select value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
							{[10, 25, 50].map(pageSize => (
								<option key={pageSize} value={pageSize}>
									Show {pageSize}
								</option>
							))}
						</select>
						<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
							{" "}
							{"<<"}{" "}
						</button>
						<button onClick={() => previousPage()} disabled={!canPreviousPage}>
							Previous
						</button>
						<button onClick={() => nextPage()} disabled={!canNextPage}>
							Next
						</button>
						<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
							{" "}
							{">>"}{" "}
						</button>
					</div>
				)}
			</TableContainer>
			{!isLoading && data.length <= 0 ? <EmptyData text="No re-applicant yet" /> : null}
		</>
	)
}

export default LPHMemoTable
