import React from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { FormSettings, FormSettingsSubmit, SettingsContainer, Settingsheader } from "./style"
import TextInput from "components/TextInput"

import authStore from "mobx/AuthStore"
import { useNavigate } from "react-router-dom"
import { DASHBOARD_LOGOUT_ROUTE } from "routes"
import { updatePassword } from "services/UserService"
import PortalStatus from "../PortalStatus"

function SettingsComponent() {
	const navigate = useNavigate()
	const formik = useFormik({
		enableReinitialize: false,
		initialValues: {
			old_password: "",
			password: "",
			confirm_password: ""
		},
		onSubmit: async (data, helpers) => {
			const response = await updatePassword(data)
			if (!response.error) {
				helpers.resetForm()
				navigate(`${DASHBOARD_LOGOUT_ROUTE}`)
			}
		},
		validationSchema: Yup.object({
			old_password: Yup.string().min(8).required("Old Password is required.").label("Old Password"),
			password: Yup.string().min(8).required("Password is required.").label("Password"),
			confirm_password: Yup.string()
				.oneOf([Yup.ref("password"), null], "Confirm Password must match Password")
				.required("Confirm Password is required.")
		}).label("Confirm Password")
	})

	return (
		<SettingsContainer>
			<h2>Settings</h2>
			<Settingsheader>Change Password</Settingsheader>
			<FormSettings onSubmit={formik.handleSubmit}>
				<TextInput
					label="Old Password"
					name="old_password"
					id="old_password"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.old_password}
					error={formik.errors.old_password}
					visible={formik.touched.old_password}
					placeholder="Old Password"
					mt="0.5rem"
				/>
				<TextInput
					label="New Password"
					name="password"
					id="password"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.password}
					error={formik.errors.password}
					visible={formik.touched.password}
					placeholder="New password"
					// mt="0.5rem"
				/>
				<TextInput
					label="Confirm Password"
					name="confirm_password"
					id="confirm_password"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.confirm_password}
					error={formik.errors.confirm_password}
					visible={formik.touched.confirm_password}
					placeholder="Confirm Password"
				/>
				<FormSettingsSubmit type="submit" disabled={authStore.isLoading ? true : false}>
					{authStore.isLoading ? "Loading..." : "Submit"}
				</FormSettingsSubmit>
			</FormSettings>
			<PortalStatus />
		</SettingsContainer>
	)
}

export default SettingsComponent
