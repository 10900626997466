import React, {useState } from 'react'
import { MemoContent,MemoTabDiv,} from './style'
import LPHMemoTable from './MemoTable/LPHMemoTable'

const LPHMemoTab = () => {
    const [toggleState,] = useState(1)

    return (
        <MemoTabDiv>
        <MemoContent>
            <div className={toggleState === 1 ? 'content  active-content' : 'content'}>
            <h1>Memos</h1>
                <LPHMemoTable/>
            </div>

        </MemoContent>
        </MemoTabDiv>
    )
}

export default LPHMemoTab
