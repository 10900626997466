import SettingsComponent from "components/Dashboard/Settings"
import MainLayout from "layout/MainLayout"
import React from "react"

function SettingsPage() {
	return (
		<MainLayout>
			<SettingsComponent />
		</MainLayout>
	)
}

export default SettingsPage
