// import { Link } from "react-router-dom";
// import { DASHBOARD_LOAN_DETAILS } from "routes";

import { Div } from "globalStyles"
import { Link } from "react-router-dom"
import { DASHBOARD_ENQUIRY_DETAILS_ROUTE } from "routes"

export const ENQ_COLUMNS = [
	{
		Header: "ID",
		accessor: (originRow, rowIndex) => rowIndex + 1
	},
	{
		Header: "From",
		accessor: "fullname"
	},
	{
		Header: "Phone",
		accessor: "phone"
	},
	{
		Header: "Email",
		accessor: "email"
	},

	{
		Header: "Message Status",
		accessor: (originRow, rowIndex) => <p>{originRow?.message ? originRow?.message.substring(0, 15) : ""}...</p>
	},

	{
		Header: "View",
		accessor: originalRow => (
			<Div display="flex" width="100%" alignI="center" justify="center">
				<Link to={`${DASHBOARD_ENQUIRY_DETAILS_ROUTE}/${originalRow?.id}`}>
					<svg width="26" height="15" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M13 4.5C13.9403 4.5 14.8421 4.81607 15.507 5.37868C16.1719 5.94129 16.5455 6.70435 16.5455 7.5C16.5455 8.29565 16.1719 9.05871 15.507 9.62132C14.8421 10.1839 13.9403 10.5 13 10.5C12.0597 10.5 11.1579 10.1839 10.493 9.62132C9.82808 9.05871 9.45455 8.29565 9.45455 7.5C9.45455 6.70435 9.82808 5.94129 10.493 5.37868C11.1579 4.81607 12.0597 4.5 13 4.5ZM13 0C18.9091 0 23.9555 3.11 26 7.5C23.9555 11.89 18.9091 15 13 15C7.09091 15 2.04455 11.89 0 7.5C2.04455 3.11 7.09091 0 13 0ZM2.57636 7.5C3.53157 9.15031 5.01482 10.5407 6.85747 11.5133C8.70013 12.4858 10.8283 13.0013 13 13.0013C15.1717 13.0013 17.2999 12.4858 19.1425 11.5133C20.9852 10.5407 22.4684 9.15031 23.4236 7.5C22.4684 5.84969 20.9852 4.45925 19.1425 3.48675C17.2999 2.51424 15.1717 1.99868 13 1.99868C10.8283 1.99868 8.70013 2.51424 6.85747 3.48675C5.01482 4.45925 3.53157 5.84969 2.57636 7.5Z"
							fill="#5B626F"
						/>
					</svg>
				</Link>
			</Div>
		)
	}
]
