import AuthLayout from 'layout/AuthLayout'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { BASE_URL } from 'services'
import http from 'services/httpService'
import useSWR from 'swr'
import AppFee from '../AppFee'
import GuarantorFormFile from '../GuarantorFormFile'
import InstitutionInfoPage from '../InstitutionInfoPage'
import LoanComp from '../LoanComp'

import UndergraduateForm from './UndergraduateForm'
import {
  DASHBOARD_APPLY_UNDERGRAD_ROUTE,
  DASHBOARD_PAYMENT_DETAILS_ROUTE,
} from 'routes'

import GuarantorForm from '../GuarantorForm'
import GuarantorFormTwo from '../GuarantorFormTwo'
import CompleteYourProfile from 'components/CompleteProfile'
import FullScreenLoader from 'components/Dashboard/FullScreenLoader'
import ReappComplete from '../ReappComplete'
// import loanStore from "mobx/LoanStore"
// import loanOwner from "services/LoanOwner"
// import loanStore from "mobx/LoanStore"

const UnderGraduateLoan = () => {
  const navigate = useNavigate()
//   let params = useParams()
//   console.log('myparams', params)
  const [searchParams] = useSearchParams()
  const params = {}
  const qinfo = searchParams.get('q')
  const userId = qinfo.split(" ")[0]
  const loanId = qinfo.split(" ")[1]
  if (loanId !== '1') params.id = loanId
  const url = `${BASE_URL}/loans/${params?.id}`
  //   const url = `${BASE_URL}/loans/${params?.id}`
  const fetcher = async () => {
    const res = await http.get(url)
    return res?.data?.data
  }

  const { data: loan, mutate } = useSWR(url, fetcher)

  const urlTwo = `${BASE_URL}/re-applicant/admin/users/who_i_am`
//   const urlTwo = `${BASE_URL}/users/who_i_am`
  const fetcherTwo = async () => {
    const res = await http.post(urlTwo, {user_key:userId})
    // const res = await http.get(urlTwo)
    return res?.data?.data
  }

  const { data: userProfile } = useSWR(urlTwo, fetcherTwo)

  if (!loan) return <FullScreenLoader show={true} />
  if (params?.id && loan && loan.length <= 0) {
    navigate(`${DASHBOARD_APPLY_UNDERGRAD_ROUTE}`)
  }

  return (
    <AuthLayout>
      <>
        {userProfile && !userProfile?.hometown ? (
          <CompleteYourProfile />
        ) : userProfile &&
          userProfile?.hometown &&
          !userProfile?.next_of_kin?.first_name ? (
          <CompleteYourProfile
            title="Next of Kin"
            desc="Kindly update your next of kin details under the profile menu to apply"
            or="goto profile menu, next of kin and update"
          />
        ) : userProfile &&
          userProfile?.hometown &&
          userProfile?.next_of_kin?.first_name &&
          !userProfile?.bank_account?.account_number ? (
          <CompleteYourProfile
            link={DASHBOARD_PAYMENT_DETAILS_ROUTE}
            btnText="Payment"
            title="Account Details"
            desc="Kindly update your bank details under the payment detail menu"
            or="goto payment details menu"
          />
        ) : (
          <>
            {loan?.length <= 0 && (
              <InstitutionInfoPage
                loanType={'undergraduate'}
                mutate={mutate}
                user_key={params?.id}
              />
            )}
            {loan?.length > 0 && loan[0]?.loan_step === 'details' && (
              <LoanComp
                id={params?.id}
                mutate={mutate}
                loanType={'undergraduate'}
              />
            )}
            {loan?.length > 0 && loan[0]?.loan_step === 'surety_1' && (
              <GuarantorForm
                id={params?.id}
                mutate={mutate}
                loanType={'undergraduate'}
              />
            )}
            {loan?.length > 0 && loan[0]?.loan_step === 'surety_2' && (
              <GuarantorFormTwo
                id={params?.id}
                mutate={mutate}
                loanType={'undergraduate'}
              />
            )}
            {loan?.length > 0 && loan[0]?.loan_step === 'upload_docs' && (
              <UndergraduateForm mutate={mutate} id={params?.id} />
            )}
            {loan?.length > 0 && loan[0]?.loan_step === 'guarantor' && (
              <GuarantorFormFile
                id={params?.id}
                loanType={'undergraduate'}
                mutate={mutate}
              />
            )}
            {loan?.length > 0 && loan[0]?.loan_step === "" && (
              <ReappComplete  />
            )}
            {/* {loan?.length > 0 && loan[0]?.loan_step === 'payment' && (
              <AppFee id={params?.id} mutate={mutate} />
            )} */}
          </>
        )}
      </>
    </AuthLayout>
  )
}

export default observer(UnderGraduateLoan)
