

import { useState } from 'react'
import AllEnquiries from './Enquiry Table'
import ReadEnqComp from './Enquiry Table/ReadEnq'
import SentEnqComp from './Enquiry Table/SentEnq'
import UnreadEnqComp from './Enquiry Table/UnreadEnq'
import {
  AllMessageBtn,
  MemoBloc,
  MemoContent,
  MemoTabDiv,
  ReadMessageBtn,
  SentMessageBtn,
  UnreadMessageBtn,
} from './style'

const EnquiryTab = () => {
  const [toggleState, setToggleState] = useState(1)

  const toggleTab = (index) => {
    setToggleState(index)
  }

  return (
    <MemoTabDiv>
      <MemoBloc>
        <AllMessageBtn
          className={toggleState === 1 ? 'tabs active-tabs' : ''}
          onClick={() => toggleTab(1)}
        >
          All Messages
        </AllMessageBtn>
        <ReadMessageBtn
          className={toggleState === 2 ? 'tabs active-tabs' : ' '}
          onClick={() => toggleTab(2)}
        >
          Read
        </ReadMessageBtn>
        <UnreadMessageBtn
          className={toggleState === 3 ? 'tabs active-tabs' : ' '}
          onClick={() => toggleTab(3)}
        >
          Unread
        </UnreadMessageBtn>
        <SentMessageBtn
          className={toggleState === 4 ? 'tabs active-tabs' : ' '}
          onClick={() => toggleTab(4)}
        >
          Sent
        </SentMessageBtn>
      </MemoBloc>

      <MemoContent>
        <div
          className={toggleState === 1 ? 'content  active-content' : 'content'}
        >
          <h1>All Messages</h1>
          <AllEnquiries />
        </div>

        <div
          className={toggleState === 2 ? 'content  active-content' : 'content'}
        >
          <h1>Read Messages</h1>
          <ReadEnqComp />
        </div>
        <div
          className={toggleState === 3 ? 'content  active-content' : 'content'}
        >
          <h1>Unread Messages</h1>
          <UnreadEnqComp />
        </div>
        <div
          className={toggleState === 4 ? 'content  active-content' : 'content'}
        >
          <h1>Sent Messages</h1>
          <SentEnqComp />
        </div>
      </MemoContent>
    </MemoTabDiv>
  )
}

export default EnquiryTab
