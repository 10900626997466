import { configure, makeAutoObservable, observable } from "mobx"
import { setJwt } from "services/httpService"

// import jwt_decode from "jwt-decode"
configure({
	enforceActions: "never"
})

class AuthStore {
	isLoading = false
	replying = false
	resendingOtp = false
	isAuth = false
	userId = ""
	currentStep = ""
	userEmail = ""
	currentUser = {}
	constructor() {
		makeAutoObservable(this, {
			isAuth: observable,
			isLoading: observable,
			replying: observable,
			resendingOtp: observable,
			currentUser: observable,
			userId: observable,
			currentStep: observable
		})
	}

	loading() {
		this.isLoading = !this.isLoading
	}
	replyingMsg() {
		this.replying = !this.replying
	}

	resending_otp() {
		this.resendingOtp = !this.resendingOtp
	}

	setInitialAuth(payload) {
		localStorage.setItem("AUTH_TOKEN", payload)
		this.getInitialAuth()
	}

	getInitialAuth() {
		const auth = localStorage.getItem("AUTH_TOKEN")
		const initAuth = auth ? auth : ""
		setJwt(initAuth)
	}

	loggedInStatus() {
		const userStatus = sessionStorage.getItem("token")
		userStatus ? setJwt(userStatus) : setJwt(userStatus)
		this.isAuth = userStatus ? true : false
		return userStatus
	}

	logoutUser() {
		sessionStorage.removeItem("token")
		this.isAuth = false
		this.userId = ""
		this.currentUser = {}
	}
	getUserToken = () => {
		const token = sessionStorage.getItem("token")

		if (!token) return null
		return token
	}

	setCurrentStep(payload) {
		this.currentStep = localStorage.setItem("auth-state", payload)
		this.getCurrentStep()
	}

	getCurrentStep() {
		const status = localStorage.getItem("auth-state")
		this.currentStep = status ? status : ""
	}

	setUserEmail(payload) {
		localStorage.setItem("auth-email", payload)
		this.getUserEmail()
	}

	getUserEmail() {
		const email = localStorage.getItem("auth-email")
		this.userEmail = email ? email : ""
	}
}

const authStore = new AuthStore()

export default authStore
