import { toast } from "react-hot-toast"
import { BASE_URL } from "services"
import http from "./httpService"
import blogStore from "mobx/BlogStore"

export async function createBlogPost(payload) {
	blogStore.loading()
	try {
		const { data } = await http.post(`${BASE_URL}/blog/create-post`, payload)
		toast.success(data.message)
		blogStore.loading()
		return data
	} catch (err) {
		blogStore.loading()
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}

export async function editBlogPost(blogUrl, payload) {
	blogStore.loading()
	try {
		const { data } = await http.put(`${BASE_URL}/blog/edit-post/${blogUrl}`, payload)
		toast.success(data.message)
		blogStore.loading()
		return data
	} catch (err) {
		blogStore.loading()
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
export async function deleteBlogPost(blogUrl) {
	blogStore.loading()
	try {
		const { data } = await http.delete(`${BASE_URL}/blog/delete-post/${blogUrl}`)
		toast.success(data.message)
		blogStore.loading()
		return data
	} catch (err) {
		blogStore.loading()
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}

export async function getBlogPost(blogId) {
	try {
		const { data } = await http.post(`${BASE_URL}/blog/edit/${blogId}`)

		return data
	} catch (err) {
		if (err && err.response) {
			err?.response?.data?.error && toast.error(err.response.data.message)
		} else {
			toast.error("Network Error")
		}
	}
}
