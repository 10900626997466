import { Img } from "globalStyles"
import { observer } from "mobx-react-lite"
import loanStore from "mobx/LoanStore"
import React, { useState } from "react"
import { FileUploader } from "react-drag-drop-files"
import { toast } from "react-hot-toast"
// import { addFilesPostGrads, addFilesPostReapply } from "services/LoanService"
import { FileFormImg } from "utils/assets"
import FileUploadForm from "../FileUploadForm"
import { BASE_URL } from "services"
import http from "services/httpService"
import useSWR from "swr"
import { PgdContainer, PgdFormContent, PgdFormDiv, PgdFormImage, PgdFormLeft, PgdFormRight, PgdHeader, ProceedBtn } from "../style"
import { addFilesPostGrads, addFilesPostReapply } from "services/ReappLoanService"

const PostGraduateForm = ({ mutate, id }) => {
	const [admissionFile, setadmissionFile] = useState(null)
	const [prevCertFile, setPrevCertFile] = useState(null)
	const [payEvidenceFile, setpayEvidenceFile] = useState(null)
	const [letterIdFile, setletterIdFile] = useState(null)
	const [schoolIdFile, setSchoolIdFile] = useState(null)

	const handleChangeAdmin = file => {
		if (file.length > 0) {
			setadmissionFile(file)
		} else {
			setadmissionFile(admissionFile)
		}
	}

	const handleChangePrevCert = file => {
		if (file.length > 0) {
			setPrevCertFile(file)
		} else {
			setPrevCertFile(prevCertFile)
		}
	}
	const handleChangePayEvidence = file => {
		if (file.length > 0) {
			setpayEvidenceFile(file)
		} else {
			setpayEvidenceFile(payEvidenceFile)
		}
	}
	const handleChangeLetterId = file => {
		if (file.length > 0) {
			setletterIdFile(file)
		} else {
			setletterIdFile(letterIdFile)
		}
	}

	const handleChangeSchoolId = file => {
		if (file.length > 0) {
			setSchoolIdFile(file)
		} else {
			setSchoolIdFile(schoolIdFile)
		}
	}

	const fileTypes = ["JPG", "PNG", "JPEG"]

	const handleTypeError = () => {
		toast.error("Upload image types with extension .jpeg, png, jpg")
	}
	const handleSizeError = file => {
		toast.error("Maximum file size allowed is 50kb")
	}

	const url = `${BASE_URL}/loans/user`
	const fetcher = async () => {
		const res = await http.get(url)
		return res?.data?.data
	}

	const { data: loans } = useSWR(url, fetcher)

	const uploadDocuments = async () => {
		if (!admissionFile) return toast.error("Please upload your addmission letter")
		if (!prevCertFile) return toast.error("Please upload your previous certificate")
		if (!letterIdFile) return toast.error("Please upload your letter of identification")
		if (!schoolIdFile) return toast.error("Please upload your School Id card")

		const formData = new FormData()
		formData.append("school_identity_card", schoolIdFile[0])
		formData.append("signed_letter_id", letterIdFile[0])
		formData.append("admission_letter", admissionFile[0])
		formData.append("othercertificate", prevCertFile[0])
		formData.set("id", id)
		const response = await addFilesPostGrads(formData)

		if (!response.error) {
			mutate()
		}
	}

	const uploadDocumentsReapply = async () => {
		if (!admissionFile) return toast.error("Please upload your addmission letter")
		if (!prevCertFile) return toast.error("Please upload your previous certificate")
		if (!letterIdFile) return toast.error("Please upload your letter of identification")
		if (!schoolIdFile) return toast.error("Please upload your School Id card")
		if (!payEvidenceFile) return toast.error("Please upload your previous fee payment evidence")

		const formDatas = new FormData()
		formDatas.append("school_identity_card", schoolIdFile[0])
		formDatas.append("signed_letter_id", letterIdFile[0])
		formDatas.append("school_fee_receipt", payEvidenceFile[0])
		formDatas.append("admission_letter", admissionFile[0])
		formDatas.append("othercertificate", prevCertFile[0])
		formDatas.set("id", id)

		const response = await addFilesPostReapply(formDatas)

		if (!response.error) {
			mutate()
		}
	}

	return (
		<PgdContainer>
			<PgdHeader>Postgraduate Application Verification</PgdHeader>
			<PgdFormDiv>
				<PgdFormContent>
					<PgdFormLeft>
						<FileUploader multiple={true} handleChange={handleChangeAdmin} name="file" maxSize={10000} types={fileTypes} onSizeError={handleSizeError} onTypeError={handleTypeError}>
							<FileUploadForm title="Upload Admission Letter" formats="(jpeg, png, jpg)" indescribe={admissionFile ? `${admissionFile[0]?.name}` : ""} />
						</FileUploader>
						<br />
						<FileUploader multiple={true} handleChange={handleChangeSchoolId} name="file" maxSize={10000} types={fileTypes} onSizeError={handleSizeError} onTypeError={handleTypeError}>
							<FileUploadForm title="School Identity Card" formats="(jpeg, png, jpg)" indescribe={schoolIdFile ? `${schoolIdFile[0]?.name}` : ""} instruction={false} />
						</FileUploader>
						<FileUploader multiple={true} handleChange={handleChangePrevCert} name="file" maxSize={10000} types={fileTypes} onSizeError={handleSizeError} onTypeError={handleTypeError}>
							<FileUploadForm title="Upload Previous Certificate" formats="(jpeg, png, jpg)" indescribe={prevCertFile ? `${prevCertFile[0]?.name}` : ""} instruction={false} />
						</FileUploader>
						<ProceedBtn disabled={loanStore.isLoading} onClick={loans.length <= 1 ? () => uploadDocuments() : () => uploadDocumentsReapply()} type="submit">
							{loanStore.isLoading ? "Loading..." : "Proceed"}
						</ProceedBtn>
					</PgdFormLeft>
					<PgdFormRight>
						<FileUploader multiple={true} handleChange={handleChangeLetterId} name="file" maxSize={10000} types={fileTypes} onSizeError={handleSizeError} onTypeError={handleTypeError}>
							<FileUploadForm title="Signed Letter of Identification" formats="(jpeg, png, jpg)" indescribe={letterIdFile ? `${letterIdFile[0]?.name}` : ""} instruction={true} />
						</FileUploader>
						<br />
						{loans.length > 1 && (
							<FileUploader multiple={true} handleChange={handleChangePayEvidence} name="file" maxSize={10000} types={fileTypes} onSizeError={handleSizeError} onTypeError={handleTypeError}>
								<FileUploadForm title="Upload Payment Evidence" formats="(jpeg, png, jpg)" indescribe={payEvidenceFile ? `${payEvidenceFile[0]?.name}` : ""} />
							</FileUploader>
						)}
					</PgdFormRight>
				</PgdFormContent>
				<PgdFormImage>
					<Img src={FileFormImg} alt="form icon" />
				</PgdFormImage>
			</PgdFormDiv>
		</PgdContainer>
	)
}

export default observer(PostGraduateForm)
