import React, { useEffect, useState } from "react"

import { getAllLoans } from "services/LoanService"
import AppLoansComp from "./ApprovedLoans"
import DisLoansComp from "./DisbursedLoans"
// import LoanTable from "./LoanTable"
import RejLoansComp from "./RejectedLoans"
import { TabContent, TabsContainer } from "./style"
import { BASE_URL } from "services"
import http from "services/httpService"
import { useQuery } from "react-query"
import TabHeader from "../TabHeader"
// import AcceptedLoans from "./AcceptedLoans"

const Tabs = () => {
	const [toggleState, setToggleState] = useState(1)
	const [loans, setLoans] = useState([])

	const url = `${BASE_URL}/admin/who_i_am`
	const fetcher = async () => {
		const res = await http.get(url)
		return res?.data?.data
	}

	const { data: userProfile } = useQuery("profile", fetcher)

	const fetchAllLoans = async () => {
		const result = await getAllLoans()

		setLoans(result?.data)
		return result?.data
	}
	useEffect(() => {
		fetchAllLoans()
	}, [loans])

	const tabs = [
		{label:"Approved",index:1},
		{label:"Rejected",index:2},
		{label:"Disbursed",index:3},
	]

	return (
		<TabsContainer>
			<TabHeader
				currentTab={toggleState}
				selectTab={setToggleState}
				tabs={tabs}
			/>
			<TabContent className="p-4">
				<div className={toggleState === 1 ? "content  active-content" : "content"}>
					<header className="p-2 my-6 mb-9">
						<h1 className="font-semibold text-[#333333] text-4xl">Approved Loans</h1>
					</header>

					<AppLoansComp admin_role={userProfile?.admin_role} user={userProfile} />
				</div>
				{/* <div className={toggleState === 2 ? "content  active-content" : "content"}>
					<header className="p-2 my-6 mb-9">
						<h1 className="font-semibold text-[#333333] text-4xl">Accepted Loans</h1>
					</header>
					<AcceptedLoans />
				</div> */}
				<div className={toggleState === 2 ? "content  active-content" : "content"}>
					<header className="p-2 my-6 mb-9">
						<h1 className="font-semibold text-[#333333] text-4xl">Rejected Loans</h1>
					</header>

					<RejLoansComp />
				</div>
				<div className={toggleState === 3 ? "content  active-content" : "content"}>
					<header className="p-2 my-6 mb-9">
						<h1 className="font-semibold text-[#333333] text-4xl">Disbursed Loans</h1>
					</header>
					<DisLoansComp />
				</div>
			</TabContent>
		</TabsContainer>
	)
}

export default Tabs