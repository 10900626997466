import styled from "styled-components"
import Colors from "utils/colors"
import media from "utils/media"

export const TabsContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	width: calc(100% - 40px);
	/* height: 300px; */
	/* background: #f1f1f1; */
	margin: 20px auto 0;
	word-break: break-all;
	/* border: 1px solid rgba(0, 0, 0, 0.274); */
`
export const TabBloc = styled.div`
	display: flex;
	gap: 5px;
	border: none;
	.active-tabs {
		background: white;
		color: white;
		background: ${Colors.primaryLight};
		border-bottom: 1px solid transparent;
	}

	.active-tabs::before {
		content: "";
		display: block;
		position: absolute;
		top: 45px;
		left: 50%;
		transform: translateX(-50%);
		width: calc(100%);
		height: 2px;
		background: rgb(88, 147, 241);
		/* background: rgb(88, 147, 241); */
	}

	button {
		border: none;
	}
`

export const LoanBtn = styled.button`
	width: 50%;
	padding: 15px;
	text-align: center;
	color: white;
	cursor: pointer;
	/* border-bottom: 1px solid rgba(0, 0, 0, 0.274); */
	box-sizing: content-box;
	position: relative;
	outline: none;
	background: darkgrey;
	border: none;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
`
export const ApprovedBtn = styled.button`
	width: 50%;
	padding: 15px;
	text-align: center;
	color: white;
	cursor: pointer;
	border-bottom: 1px solid rgba(0, 0, 0, 0.274);
	box-sizing: content-box;
	position: relative;
	outline: none;
	background: linear-gradient(73.6deg, rgba(5, 146, 36, 0.97) 4.94%, rgba(5, 146, 36, 0.97) 71.35%);
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
`
export const RejectedBtn = styled.button`
	width: 50%;
	padding: 15px;
	text-align: center;
	color: white;
	cursor: pointer;
	border-bottom: 1px solid rgba(0, 0, 0, 0.274);
	box-sizing: content-box;
	position: relative;
	outline: none;
	background: linear-gradient(73.6deg, rgba(237, 50, 55, 0.97) 4.94%, rgba(237, 50, 55, 0.97) 71.35%);
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
`
export const PendingBtn = styled.button`
	width: 50%;
	padding: 15px;
	text-align: center;
	color: white;
	cursor: pointer;
	border-bottom: 1px solid rgba(0, 0, 0, 0.274);
	box-sizing: content-box;
	position: relative;
	outline: none;
	background: orange;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
`
export const DisbursedBtn = styled.button`
	width: 50%;
	padding: 15px;
	text-align: center;
	color: white;
	cursor: pointer;
	border-bottom: 1px solid rgba(0, 0, 0, 0.274);
	box-sizing: content-box;
	position: relative;
	outline: none;
	background: darkcyan;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
`
export const TabContent = styled.div`
	flex-grow: 1;

	.content {
		background: white;
		padding: 0px;
		width: 100%;
		height: 100%;
		display: none;
	}
	.content h2 {
		padding: 0px 0 5px 0px;
	}
	.content hr {
		width: 100px;
		height: 2px;
		background: #222;
		margin-bottom: 5px;
	}
	.content p {
		width: 100%;
		height: 100%;
	}
	.active-content {
		display: block;
	}
`

export const TableContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`
export const TableDiv = styled.table`
	border-collapse: collapse;
	width: 100%;

	td,
	th {
		/* border: 1px solid #ddd; */
		padding: 8px;
	}

	tr:nth-child(even) {
		background-color: #f2f2f2;
	}

	tr:hover {
		background-color: #ddd;
	}

	th,
	tfoot td {
		padding-top: 12px;
		padding-bottom: 12px;
		text-align: left;
		background: ${Colors.primary};
		color: white;
	}
`

export const SendMemoBtn = styled.button`
	cursor: pointer;
	width: 120px;
	height: 35px;
	border: none;
	border-radius: 3px;
	background: ${Colors.primaryLight};
	color: ${Colors.white};
	margin-top: 1rem;
	margin-bottom: 10px;
`

export const SendMemoBtnTop = styled.button`
	cursor: pointer;
	width: 120px;
	height: 35px;
	border: none;
	border-radius: 3px;
	background: #ff7900;
	color: ${Colors.white};
	/* margin-top: 1rem; */
	margin-bottom: 10px;
`

export const SearchInputType = styled.input`
	width: calc(300px - 40px);
	height: 35px;
	margin-bottom: 10px;
	outline: none;
	border: 1px solid grey;
	/* border: none; */
	background: ${Colors.white};
	color: ${Colors.black};

	border-radius: 20px;
	padding: 0px 20px;
	::placeholder {
		color: ${Colors.black};
		opacity: 0.5;
	}
	${media.mobile`
    width:93%;
	height: 40px;
    `}
`
