import React, { useEffect, useState } from 'react';
import { viewLoanAccountDetails, addLoanAccountDetails, deleteLoanAccountDetails } from 'services/ReappLoanService';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Colors from 'utils/colors';
import toast from 'react-hot-toast';

const DetailsContainer = styled.div`
  width: 77%;
  height: 95%;
  position: fixed;
  top: 50%;
  left: 58%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  overflow-y: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 35px;
  cursor: pointer;
  color: red;
`;

const DetailsHeader = styled.h2`
 color: ${Colors.primary};
`;

const AddButton = styled.button`
  margin: 10px 0;
  padding: 10px;
  background: ${Colors.primary};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: ${Colors.primaryLight};
  }
`;

const DeleteButton = styled.button`
  margin: 10px 0;
  padding: 5px;
  background: red;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: darkred;
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  max-width: 80%;
  max-height: 80%;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;

  h2 {
    margin-top: 0;
  }

  form {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;

      input {
        margin-top: 5px;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }

    button {
      margin-top: 20px;
      padding: 10px;
      border: none;
      background: ${Colors.primary};
      color: white;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background: ${Colors.primaryLight};
      }
    }
  }
`;

const LoanDetails = ({ loanId, onClose }) => {
  const [loanDetails, setLoanDetails] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const fetchDetails = async () => {
      const data = await viewLoanAccountDetails({ value: loanId });
      setLoanDetails(data.history);
    };
    fetchDetails();
  }, [loanId]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(amount);
  };

  const initialValues = {
    institution_name: '',
    amount: '',
    application_date: '',
    disburse_date: '',
    loan_type: 'undergraduate',
    guarantor_one_first_name: '',
    guarantor_one_last_name: '',
    guarantor_one_phone: '',
    guarantor_one_work_place: '',
    guarantor_one_email: '',
    guarantor_one_nin: '',
    guarantor_two_first_name: '',
    guarantor_two_last_name: '',
    guarantor_two_phone: '',
    guarantor_two_work_place: '',
    guarantor_two_email: '',
    guarantor_two_nin: '',
  };

  const validationSchema = Yup.object({
    institution_name: Yup.string().required('Institution name is required'),
    amount: Yup.number().required('Amount is required'),
    application_date: Yup.date().required('Application date is required'),
    disburse_date: Yup.date().required('Disburse date is required'),
    loan_type: Yup.string().required('Loan type is required'),
    guarantor_one_first_name: Yup.string().required('First name is required'),
    guarantor_one_last_name: Yup.string().required('Last name is required'),
    guarantor_one_phone: Yup.string().length(11, 'Phone number must be 11 digits').required('Phone number is required'),
    guarantor_one_work_place: Yup.string().required('Work place is required'),
    guarantor_one_email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    guarantor_one_nin: Yup.string().length(11, 'NIN must be 11 digits').required('NIN is required'),
    guarantor_two_first_name: Yup.string().required('First name is required'),
    guarantor_two_last_name: Yup.string().required('Last name is required'),
    guarantor_two_phone: Yup.string().length(11, 'Phone number must be 11 digits').required('Phone number is required'),
    guarantor_two_work_place: Yup.string().required('Work place is required'),
    guarantor_two_email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    guarantor_two_nin: Yup.string().length(11, 'NIN must be 11 digits').required('NIN is required'),
  });

  const handleAddLoan = async (values, { resetForm }) => {
    const payload = {
      id: loanId,
      institution_name: values.institution_name,
      amount: values.amount,
      application_date: values.application_date,
      disburse_date: values.disburse_date,
      loan_type: values.loan_type,
      guarantor_one: {
        first_name: values.guarantor_one_first_name,
        last_name: values.guarantor_one_last_name,
        phone: values.guarantor_one_phone,
        work_place: values.guarantor_one_work_place,
        email: values.guarantor_one_email,
        nin: values.guarantor_one_nin,
      },
      guarantor_two: {
        first_name: values.guarantor_two_first_name,
        last_name: values.guarantor_two_last_name,
        phone: values.guarantor_two_phone,
        work_place: values.guarantor_two_work_place,
        email: values.guarantor_two_email,
        nin: values.guarantor_two_nin,
      },
    };

    try {
      await addLoanAccountDetails(payload);
      setLoanDetails([...loanDetails, payload]);
      setShowPopup(false);
      resetForm();
    } catch (error) {
      console.error('Error adding loan record:', error);
    }
  };

  const handleDeleteLoan = async (loanId) => { 
    try {
      await deleteLoanAccountDetails(loanId);
      setLoanDetails(loanDetails.filter(loan => loan._id !== loanId));
      toast.success("Loan account details deleted successfully.");
    } catch (error) {
      console.error("Error deleting loan record:", error);
      toast.error("Error deleting loan account details.");
    }
  };
  
  

  if (!loanDetails) return <p>Loading...</p>;

  return (
    <DetailsContainer>
      <CloseButton onClick={onClose}>×</CloseButton>
      <DetailsHeader>Loan Details</DetailsHeader>
      <AddButton onClick={() => setShowPopup(true)}>Add Record</AddButton>
      <hr />
      {loanDetails.map((cur, index) => (
        <div key={cur.id}>
          <h3>Loan {index + 1}</h3>
          <p>Institution Name: {cur.institution_name}</p>
          <p>Amount: {formatAmount(cur.amount)}</p>
          <p>Application Date: {formatDate(cur.application_date)}</p>
          <p>Disburse Date: {formatDate(cur.disburse_date)}</p>
          <p>Loan Type: {cur.loan_type}</p>
          <h4>Guarantor One</h4>
          <p>Name: {cur.guarantor_one.first_name} {cur.guarantor_one.last_name}</p>
          <p>Phone: {cur.guarantor_one.phone}</p>
          <p>Email: {cur.guarantor_one.email}</p>
          <p>NIN: {cur.guarantor_one.nin}</p>
          <p>Work Place: {cur.guarantor_one.work_place}</p>
          <h4>Guarantor Two</h4>
          <p>Name: {cur.guarantor_two.first_name} {cur.guarantor_two.last_name}</p>
          <p>Phone: {cur.guarantor_two.phone}</p>
          <p>Email: {cur.guarantor_two.email}</p>
          <p>NIN: {cur.guarantor_two.nin}</p>
          <p>Work Place: {cur.guarantor_two.work_place}</p>
          <DeleteButton onClick={() => handleDeleteLoan(cur._id)}>Delete</DeleteButton>
          <hr />

        </div>
      ))}

      {showPopup && (
        <PopupOverlay>
          <PopupContent>
            <CloseButton onClick={() => setShowPopup(false)}>×</CloseButton>
            <h2>Add New Loan Record</h2>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleAddLoan}
            >
              <Form>
                <label>
                  Institution Name
                  <Field type="text" name="institution_name" />
                  <ErrorMessage name="institution_name" component="div" />
                </label>
                <label>
                  Amount
                  <Field type="number" name="amount" />
                  <ErrorMessage name="amount" component="div" />
                </label>
                <label>
                  Application Date
                  <Field type="date" name="application_date" />
                  <ErrorMessage name="application_date" component="div" />
                </label>
                <label>
                  Disburse Date
                  <Field type="date" name="disburse_date" />
                  <ErrorMessage name="disburse_date" component="div" />
                </label>
                <label>
                  Loan Type
                  <Field as="select" name="loan_type">
                    <option value="undergraduate">Undergraduate</option>
                    <option value="postgraduate">Postgraduate</option>
                  </Field>
                  <ErrorMessage name="loan_type" component="div" />
                </label>
                <h3>Guarantor One</h3>
                <label>
                  First Name
                  <Field type="text" name="guarantor_one_first_name" />
                  <ErrorMessage name="guarantor_one_first_name" component="div" />
                </label>
                <label>
                  Last Name
                  <Field type="text" name="guarantor_one_last_name" />
                  <ErrorMessage name="guarantor_one_last_name" component="div" />
                </label>
                <label>
                  Phone
                  <Field type="text" name="guarantor_one_phone" />
                  <ErrorMessage name="guarantor_one_phone" component="div" />
                </label>
                <label>
                  Work Place
                  <Field type="text" name="guarantor_one_work_place" />
                  <ErrorMessage name="guarantor_one_work_place" component="div" />
                </label>
                <label>
                  Email
                  <Field type="email" name="guarantor_one_email" />
                  <ErrorMessage name="guarantor_one_email" component="div" />
                </label>
                <label>
                  NIN
                  <Field type="text" name="guarantor_one_nin" />
                  <ErrorMessage name="guarantor_one_nin" component="div" />
                </label>
                <h3>Guarantor Two</h3>
                <label>
                  First Name
                  <Field type="text" name="guarantor_two_first_name" />
                  <ErrorMessage name="guarantor_two_first_name" component="div" />
                </label>
                <label>
                  Last Name
                  <Field type="text" name="guarantor_two_last_name" />
                  <ErrorMessage name="guarantor_two_last_name" component="div" />
                </label>
                <label>
                  Phone
                  <Field type="text" name="guarantor_two_phone" />
                  <ErrorMessage name="guarantor_two_phone" component="div" />
                </label>
                <label>
                  Work Place
                  <Field type="text" name="guarantor_two_work_place" />
                  <ErrorMessage name="guarantor_two_work_place" component="div" />
                </label>
                <label>
                  Email
                  <Field type="email" name="guarantor_two_email" />
                  <ErrorMessage name="guarantor_two_email" component="div" />
                </label>
                <label>
                  NIN
                  <Field type="text" name="guarantor_two_nin" />
                  <ErrorMessage name="guarantor_two_nin" component="div" />
                </label>
                <button type="submit">Add Loan</button>
              </Form>
            </Formik>
          </PopupContent>
        </PopupOverlay>
      )}
    </DetailsContainer>
  );
};

export default LoanDetails;
