import EditPost from "components/Dashboard/Blog/EditPost"
import MainLayout from "layout/MainLayout"
import React from "react"

function EditBlogPage() {
	return (
		<MainLayout>
			<EditPost />
		</MainLayout>
	)
}

export default EditBlogPage
