import styled from "styled-components/macro"
import Colors from "utils/colors"

export const AddBlogDiv = styled.form`
	width: 70%;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
`

export const CoverImageDiv = styled.div`
	width: 104%;
	height: 20rem;
	margin-top: 2rem;
	/* background: ${Colors.primary}; */
	border-radius: 15px;
`
export const AddPostButton = styled.button`
	width: 150px;
	height: 35px;
	border-radius: 15px;
	border: none;
	background: ${Colors.primary};
	color: ${Colors.white};
	margin-top: 4.5rem;
	margin-bottom: 3rem;
	cursor: pointer;
`
export const CoverImageButton = styled.button`
	width: 104%;
	height: 10rem;
	background: ${Colors.primary};
	border-radius: 15px;
	color: ${Colors.white};
`
