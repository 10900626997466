import AdminProfile from 'components/Dashboard/Profile/AdminProfile'
import MainLayout from 'layout/MainLayout'
import React from 'react'

const ProfilePage = () => {
  return (
    <MainLayout>
      <AdminProfile />
    </MainLayout>
  )
}

export default ProfilePage
