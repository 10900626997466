import { Link } from "react-router-dom"
import { DASHBOARD_LOAN_DETAILS } from "routes"
import CommaNumber from "comma-number"
// import { StatusDiv } from "components/Dashboard/Home/RecentApplications/style"
// import { ByshelbP } from "globalStyles"

export const REJCOLUMNS = [
	{
		Header: "Applicant",
		accessor: (originalRow, rowIndex) => `${originalRow?.applicant?.first_name} ${originalRow?.applicant?.surname}`
	},
	{
		Header: "LGA",
		accessor: (originalRow, rowIndex) => originalRow?.applicant?.lga
	},
	{
		Header: "Institution",
		accessor: (originalRow, rowIndex) => <div style={{ maxWidth: "250px" }}>{originalRow?.institution?.name}</div>
	},
	{
		Header: "Amount",
		accessor: (originalRow, rowIndex) => `₦${CommaNumber(originalRow?.amount)}`
	},
	{
		Header: "Loan Type",
		accessor: "loan_type"
	},
	{
		Header: "Rejected By",
		accessor: (originalRow, rowIndex) => originalRow?.rejectedBy
	},
	// {
	// 	Header: "Status",
	// 	accessor: (originalRow, rowIndex) => (
	// 		<StatusDiv
	// 			bgColor={
	// 				originalRow?.status === "approved"
	// 					? "#5caa77"
	// 					: originalRow?.status === "pending"
	// 					? "#ffae00"
	// 					: originalRow?.status === "rejected"
	// 					? "#e23644"
	// 					: originalRow?.status === "disbursed"
	// 					? "#91f2b1"
	// 					: ""
	// 			}
	// 		>
	// 			<ByshelbP fs="13px" ml="5px" mt="22px">
	// 				{originalRow?.status === "approved"
	// 					? "Approved"
	// 					: originalRow?.status === "pending"
	// 					? "Pending"
	// 					: originalRow?.status === "rejected"
	// 					? "Rejected"
	// 					: originalRow?.status === "disbursed"
	// 					? "Disbursed"
	// 					: "Expired"}
	// 			</ByshelbP>
	// 		</StatusDiv>
	// 	)
	// },
	{
		Header: "View Details",
		accessor: (originalRow, rowIndex) => originalRow?.status && <Link to={`${DASHBOARD_LOAN_DETAILS}/${originalRow.id}`}>View</Link>
	}
]
