import React from "react"
import styled from "styled-components/macro"

const EmptyDocDiv = styled.div`
	width: calc(100% - 20px);
	height: 150px;
	display: flex !mportant;
	justify-content: center !mportant;
	align-items: center !mportant;
	text-align: center;
`

function EmptyData({ text = "No data yet" }) {
	return (
		<EmptyDocDiv>
			<p>{text}</p>
		</EmptyDocDiv>
	)
}

export default EmptyData
