import React, { useState } from "react"
import RejLoansComp from "../Loan/RejectedLoans"
import RefferedLoans from "../Loan/RefferedLoans"
import {TabContent } from "../Loan/style"
import DisLoansComp from "../Loan/DisbursedLoans"
import useAdminData from "hooks/useAdminData"
import TabHeader from "../TabHeader"
// import ApprovedLoans from "components/Dashboard/Loan/ApprovedLoans/index"


const tabs = [
	{label:"Loans",index:1},
	{label:"Disbursed",index:2},
	{label:"Rejected",index:3},
]
export default function LPHTabs() {
    const {userProfile,adminRole} = useAdminData()
	const [toggleState, setToggleState] = useState(1)

	return (
		<div>
			<TabHeader tabs={tabs} currentTab={toggleState} selectTab={setToggleState}/>
			<div className="p-4">
				<TabContent>
					<div className={toggleState === 1 ? "content  active-content" : "content"}>
						<h3 className="text-3xl font-bold my-4 text-[#242424]">Reffered Loans</h3>
						<RefferedLoans admin_role={adminRole} user={userProfile} />
					</div>
					{/* <div className={toggleState === 2 ? "content  active-content" : "content"}>
						<h3 className="text-3xl font-bold my-4 text-[#242424]">Approved Loans</h3>
						<ApprovedLoans />
					</div> */}
					<div className={toggleState === 2 ? "content  active-content" : "content"}>
						<h3 className="text-3xl font-bold my-4 text-[#242424]">Disbursed Loans</h3>
						<DisLoansComp />
					</div>
					<div className={toggleState === 3 ? "content  active-content" : "content"}>
						<h3 className="text-3xl font-bold my-4 text-[#242424]">Rejected Loans</h3>
						<RejLoansComp />
					</div>
				</TabContent>
			</div>
		</div>
	)
}

