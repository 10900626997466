import { useState } from "react";
import ActionModal from "./ActionModal";
import { reviewLoanByLPH } from "services/LoanService";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

export default function LPHAcceptBtn({loan,refetch}){
    const navigate = useNavigate()
    const [loading,setLoading] = useState(false)
    const [open,setOpen] = useState(false);

    const handleLPHAccept = async() => {
        setLoading(true);
        const data = await reviewLoanByLPH(loan.id);
        if(data){
            await refetch();
            closeModal()
            message.success("Loan Accepted successfully");
            navigate(-1)
        }
        setLoading(false);
    }
    const openModal = () => {
        setOpen(true);
    }
    const closeModal = () => {
        setOpen(false);
    }

    return (
        <>
            <ActionModal
                modalOpen={open}
                title={"Accept Loan Application"}
                onClose={closeModal}
                loading={loading}
                onOk={handleLPHAccept}
            >
                <p className="text-center my-3">Are you sure you want to accept this loan this process can not be reversed</p>
            </ActionModal>
            <div className="p-2 text-end">
                <button className="border border-solid font-semibold border-secondary-300 text-secondary-500 bg-secondary-50 px-6 rounded-full py-2" onClick={openModal}>Accept Loan Data</button>
            </div>
        </>
    )
}