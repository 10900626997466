import { makeAutoObservable, observable } from "mobx"
import { extractFieldValues } from "utils"

class CheckboxStore {
	selectedValues = []
	all = []
	constructor() {
		makeAutoObservable(this, {
			selectedValues: observable,
			all: observable
		})
	}

	onChange = (value, isChecked) => {
		if (isChecked) {
			this.selectedValues.push(value)
			this.all = []
		} else {
			const index = this.selectedValues.indexOf(value)
			if (index > -1) {
				this.selectedValues.splice(index, 1)
			}
			this.all = []
		}
	}
	onChangeAll = (value, isChecked, data) => {
		if (isChecked) {
			this.all.push(value)
			this.setAllSelect(data)
		} else {
			const index = this.all.indexOf(value)
			if (index > -1) {
				this.all.splice(index, 1)
			}
			this.selectedValues = []
		}
	}

	setAllSelect = payload => {
		const all = extractFieldValues(payload, "_id")
		this.selectedValues = all
	}
}

const checkboxStore = new CheckboxStore()
export default checkboxStore
