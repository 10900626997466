import { observer } from "mobx-react-lite"
import checkboxStore from "mobx/CheckboxStore"
import Colors from "utils/colors"

const CheckboxAll = observer(({ value, label, data }) => {
	const isChecked = checkboxStore.all.includes(value)

	const handleCheckboxChange = event => {
		checkboxStore.onChangeAll(value, event.target.checked, data)
	}

	return (
		<div style={{ display: "flex" }}>
			<label style={{ display: "flex", gap:"6px",alignItems: "center" }}>
				<input style={{ accentColor: Colors.primaryLight, cursor: "pointer" }} type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
				{label}
			</label>
		</div>
	)
})

export default CheckboxAll
