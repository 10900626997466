import { useState } from 'react';
import { ConfigProvider, message, Modal, Popconfirm, Spin } from 'antd';
import { referLoan } from 'services/LoanService';


const ConfirmAccept = ({actionHandler,refetch,title,description}) => {
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showPopconfirm = () => setOpen(true);
    const handleCancel = () => setOpen(false);

    const handleOk = async() => {
        setConfirmLoading(true);
        const data = await actionHandler();
        if(data){
            await refetch()
            handleCancel()
            message.success("Loan Accepted successfully");
        }
        setConfirmLoading(false)
    };

    return (
        <Popconfirm
            icon={null}
            title={<p className="text-xl">{title || "Confirm Accept"}</p>}
            description={<p className="max-w-[300px]">{description || "Are you sure you want to accept this loan this process can not be reversed"}</p>}
            open={open}
            onConfirm={handleOk}
            okButtonProps={{loading: confirmLoading,}}
            onCancel={handleCancel}
        >
        <button onClick={showPopconfirm} className="h-7 w-7 flex disabled:opacity-35 disabled:cursor-not-allowed items-center justify-center font-semibold rounded-full border border-secondary-300 bg-secondary-50 text-secondary-700">
                    <span><i className="bi bi-check-all text-xl"></i></span>
            </button>
        </Popconfirm>
    );
};

export const ConfirmReferLoan = ({refetch,loan}) => {
    const [open, setOpen] = useState(false);
    const [comment,setComment] = useState("");
    const [amount,setAmount] = useState(loan.amount);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showPopconfirm = () => setOpen(true);
    const handleCancel = () => setOpen(false);

    const handleOk = async() => {
        setConfirmLoading(true);
        const data = await referLoan({
            id:loan.id,
            amount,
            comment
        });
        if(data){
            await refetch()
            handleCancel()
            message.success("Loan Referred successfully");
        }
        setConfirmLoading(false)
    };

    return (
        <>
        <Modal
            open={open}
            footer={null}
            onCancel={handleCancel}
            centered
            maskClosable={false}
        >
            <div>
                <p className="text-3xl text-center font-semibold text-[#242424]">Refer Loan</p>
                    <div className="my-2">
                        <label htmlFor="" className="block font-medium">Amount</label>
                        <input className="border w-full border-solid border-gray-300 rounded-md p-2" type="number" value={amount} onChange={(e)=>setAmount(e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor="" className="block font-medium">Comment</label>
                        <textarea className="border w-full resize-none border-solid border-gray-300 rounded-md p-2" value={comment} onChange={(e)=>setComment(e.target.value)} />
                    </div>
            </div>
            <footer className="flex items-center my-4 justify-between gap-8">
                <button disabled={confirmLoading} onClick={handleCancel} className="rounded-full px-6 py-2 border font-medium border-solid border-red-400 bg-red-50 text-red-600">Cancel</button>
                <button disabled={confirmLoading} onClick={handleOk} className="rounded-full px-6 py-2 border font-medium border-solid border-secondary-400 bg-secondary-50 text-secondary-600">
                    {
                        confirmLoading
                        ?(
                        <ConfigProvider theme={{token:{colorPrimary:"rgb(109,0,98)"}}}>
                            <Spin/>
                        </ConfigProvider>
                        )
                        :(<span>Continue</span>)
                    }
                </button>
            </footer>
        </Modal>
        <button onClick={showPopconfirm} className="px-6 py-1 disabled:opacity-35 disabled:cursor-not-allowed items-center justify-center font-semibold rounded-full border border-secondary-300 bg-secondary-50 text-secondary-700">
                    <span>Refer</span>
        </button>
        </>
        
    );
};

export default ConfirmAccept;
