import BlogsPost from "components/Dashboard/Blog/Posts"
import MainLayout from "layout/MainLayout"
import React from "react"

const BlogPage = () => {
	return (
		<MainLayout>
			<BlogsPost />
		</MainLayout>
	)
}

export default BlogPage
