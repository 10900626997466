import MemoDetailsSingle from "components/Dashboard/Memo/MemoDetailsSingle"
import MainLayout from "layout/MainLayout"
import React from "react"

const MemoDetailsSinglePage = () => {
	return (
		<MainLayout>
			<MemoDetailsSingle />
		</MainLayout>
	)
}

export default MemoDetailsSinglePage
