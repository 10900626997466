import TextInput from "components/TextInput"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useState } from "react"
import { adminInvite } from "services/AdminUserService"
import AdminTable from "./Admin Table/AdminTable"
import RemAdminTable from "./Admin Table/RemAdminTable"
import { ActionHeader, AdminBody, AdminBodyInput, AdminCon, AdminFirstDiv, AdminHead, AdminOverlay, AdminSeconDiv, CloseBtn, InputHold, OverlayDiv, StaffSubBtn } from "./style"
import CustomSelectMain from "components/SelectInput"
import { observer } from "mobx-react-lite"
import authStore from "mobx/AuthStore"

const StaffComp = () => {
	const [addToggle, setAddToggle] = useState(false)
	const [remToggle, setRemToggle] = useState(false)

	const addBtnHandler = () => {
		setAddToggle(!addToggle)
	}
	const remBtnHandler = () => {
		setRemToggle(!remToggle)
	}

	const formik = useFormik({
		enableReinitialize: false,
		initialValues: {
			email: "",
			admin_role: ""
		},
		onSubmit: async (data, helpers) => {
			const res = await adminInvite(data)

			if (!res.error) {
				helpers.resetForm()
				setAddToggle(false)
			}
		},
		validationSchema: Yup.object({
			email: Yup.string().max(250).email().required().label("Email"),
			admin_role: Yup.string().max(50).required().label("Role")
		})
	})
	return (
		<AdminCon>
			<h1>BYSHELB ADMIN STAFF</h1>

			<AdminFirstDiv>
				<div>
					{addToggle && (
						<AdminOverlay>
							<OverlayDiv>
								<AdminHead>
									<ActionHeader>Add Staff</ActionHeader>
									<CloseBtn onClick={() => addBtnHandler()}>Close</CloseBtn>
								</AdminHead>
								<AdminBody>
									<form onSubmit={formik.handleSubmit}>
										<CustomSelectMain
											label="Choose Admin Role:"
											name="admin_role"
											id="admin_role"
											value={formik.values.admin_role}
											onBlur={formik.handleBlur}
											onChange={formik.handleChange}
											error={formik.errors.admin_role}
											visible={formik.touched.admin_role}
											width="250px"
										>
											<option value="">Select role</option>
											<option value="es">Super admin</option>
											<option value="lo">Loan Officer</option>
											<option value="lph">Loan Processing Head</option>
											<option value="la">Auditor</option>
											<option value="acc">Accountant</option>
											<option value="blog">Blogger</option>
											<option value="fdo">Front Desk</option>
										</CustomSelectMain>
										<AdminBodyInput>
											<InputHold>
												<TextInput
													label="Email"
													width="250px"
													type="email"
													name="email"
													id="email"
													value={formik.values.email}
													onBlur={formik.handleBlur}
													onChange={formik.handleChange}
													placeholder="Enter staff email"
													error={formik.errors.email}
													visible={formik.touched.email}
												/>
											</InputHold>
										</AdminBodyInput>
										<StaffSubBtn type="submit">{authStore.isLoading ? "Sending Invite..." : "Send Invitation"}</StaffSubBtn>
									</form>
								</AdminBody>
							</OverlayDiv>
						</AdminOverlay>
					)}

					{/* <ActionBtn onClick={() => remBtnHandler()}>Remove Staff</ActionBtn> */}
					{remToggle && (
						<AdminOverlay>
							<OverlayDiv>
								<AdminHead>
									<ActionHeader>Remove Staff</ActionHeader> <CloseBtn onClick={() => remBtnHandler()}>Close</CloseBtn>
								</AdminHead>
								<RemAdminTable />
							</OverlayDiv>
						</AdminOverlay>
					)}
				</div>
			</AdminFirstDiv>
			<AdminSeconDiv>
				<div>
					{/* <h1>Current Admins</h1> */}
					<AdminTable onAddStaff={() => addBtnHandler()} />
				</div>
			</AdminSeconDiv>
		</AdminCon>
	)
}

export default observer(StaffComp)
