import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components/macro"
import Colors from "utils/colors"

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	background-color: #f4f4f4;
`

const Heading = styled.h1`
	font-size: 8rem;
	font-weight: 800;
	color: ${Colors.primaryLight};
	margin-bottom: 1.5rem;
`

const Text = styled.p`
	font-size: 2rem;
	font-weight: 400;
	color: #333;
	text-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
	/* margin-bottom: 2rem; */
`

const Button = styled(Link)`
	font-size: 2rem;
	font-weight: 600;
	color: #fff;
	text-decoration: none;
	background-color: ${Colors.primaryLight};
	padding: 1rem 2.5rem;
	border-radius: 5px;
	transition: all 0.3s ease-in-out;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);

	&:hover {
		background-color: ${Colors.primary};
		cursor: pointer;
	}
`

const ErrorPage = () => {
	return (
		<Container>
			<Heading>404</Heading>
			{/* <Text>Oops! Page not found</Text> */}
			<Text>We can't seem to find the page you're looking for.</Text>
			<Button to="/">Go Home</Button>
		</Container>
	)
}

export const SuperUserErrorPage = () => {
	return (
		<Container>
			<Heading>404</Heading>
			{/* <Text>Oops! Page not found</Text> */}
			<Text>We can't seem to find the page you're looking for.</Text>
			<Button to="/">Go Home</Button>
		</Container>
	)
}

export default ErrorPage
