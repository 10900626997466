import styled from "styled-components/macro"
import Colors from "utils/colors"

export const MoreDetContyainer = styled.div`
	width: 50%;
	background: #fff;
	height: 80%;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	position: relative;
	overflow-y: auto;
	white-space: nowrap;

	&::-webkit-scrollbar {
		width: 15px;
	}

	&::-webkit-scrollbar-track {
		background-color: #f1f1f1;
		border-radius: 5px;
		width: 15px;
		height: 50px;
		margin-top: 15%;
		margin-bottom: 15%;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${Colors.primary};
		border-radius: 5px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: ${Colors.primary};
	}
`
export const ItemContainer = styled.div`
	width: calc(100% - 30px);
	margin-bottom: -10px;
	display: flex;
	justify-content: space-between;
	padding-left: 15px;
	padding-right: 15px;
`
export const ItemInnerDivContainer = styled.div`
	min-width: 60%;
	max-width: 60%;
`
export const CloseBtnDetails = styled.button`
	border: none;
	outline: none;
	background: transparent;
	position: sticky;
	margin-left: 95%;
	top: 3%;
	align-self: flex-end;
	cursor: pointer;
`
export const ItemImage = styled.div`
	width: 120px;
	height: 140px;
	margin-left: 15px;
	border-radius: 3px;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 3px;
	}
`
