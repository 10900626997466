import React from "react"

import { SMCardBody, SmContent } from "./style"

import { ByshelbP, Div, Img } from "globalStyles"
import Colors from "utils/colors"
import CommaNumber from "comma-number"

function CustomCard({ iconUrl, title, amount, sign = "" }) {
	return (
		<SMCardBody>
			<SmContent>
				<Img width="55px" height="55px" src={iconUrl} alt={title} />
				<Div ml="15px">
					<ByshelbP fw="bold" fs="18px" color={Colors.primary}>
						{sign ? "₦" : ""}
						{CommaNumber(amount)}
					</ByshelbP>
					<ByshelbP fs="13px" mt="-10px" color={Colors.primary}>
						{title}
					</ByshelbP>
				</Div>
			</SmContent>
		</SMCardBody>
	)
}

export default CustomCard
