import React from 'react'
import { Link } from 'react-router-dom'
import { DASHBOARD_RE_APPLICANT_ROUTE } from 'routes'
import styled from 'styled-components'
import Colors from 'utils/colors'
import { ProceedBtn } from './style'

const ReapDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7rem 7rem;
  `
const ReappLink = styled(Link)`
background: ${Colors.primary};
width: 40px;
height: 25px;
padding: 10px;
border-radius: 10px;
color: white;
text-decoration: none;

`
function ReappComplete() {
  return (
    <ReapDiv>
      <div>
        <h1>
          You have successfully added one record for a reapplicant. Click link
          below to add more.
        </h1>
          <ReappLink to={DASHBOARD_RE_APPLICANT_ROUTE}>Proceed</ReappLink>
      </div>
    </ReapDiv>
  )
}

export default ReappComplete
