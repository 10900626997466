import React from "react"
import AddPost from "components/Dashboard/Blog/AddPosts"
import MainLayout from "layout/MainLayout"

function CreateBlogPage() {
	return (
		<MainLayout>
			<AddPost />
		</MainLayout>
	)
}

export default CreateBlogPage
