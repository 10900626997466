import styled from "styled-components/macro"

export const TabErrorContainer = styled.div`
	width: 100%;
	height: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`
export const LoadingContainer = styled.div`
	position: relative;
`
