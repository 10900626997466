export const sumData = arrayOfNumbers => {
	const sum = arrayOfNumbers?.reduce((prevValue, curValue) => {
		return prevValue + curValue
	}, 0)
	return sum
}

export const safeJSONParse = str => {
	try {
		return JSON.parse(str)
	} catch (error) {}
}

export function extractFieldValues(arr, fieldName) {
	if (!arr || arr.length <= 0) return []
	const fieldValues = arr.map(obj => obj[fieldName])

	return fieldValues
}
