/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react"
import SuperUserService from "../SuperUserService"
import { message } from "antd"
import { BASE_URL } from "services"
import http from "services/httpService"
import { useNavigate } from "react-router-dom"
import { parseStringToInt } from "../lib/utils"
/***
 * "S/N":index + 1,
			"NAME": `${loan?.bank_account?.account_name.toUpperCase()}`,
			"PHONE":loan?.applicant?.phone,
			"AGE":loan?.applicant?.age,
			"SEX":loan?.applicant?.gender,
			"LGA":loan?.applicant?.lga,
			"INSTITUTION":loan?.institution?.name ? loan?.institution?.name?.toUpperCase():"",
			"LEVEL": loan?.institution?.current_study_year ? loan?.institution?.current_study_year?.toUpperCase() : "",
			"COURSE":loan?.institution?.course ? loan?.institution?.course?.toUpperCase() : "",
			"AMOUNT":loan?.amount ? loan?.amount.toLocaleString() : "0.00",
			"INSURANCE": `${(0.01 * loan?.amount).toLocaleString()}.00`,
			"NET AMOUNT":(loan?.amount - (0.01 * loan?.amount)).toLocaleString(),
			"BANK":loan?.bank_account?.bank_name ? loan?.bank_account?.bank_name?.toUpperCase() : "",
			"BANK_CODE":loan?.bank_account?.bank_code ? loan?.bank_account?.bank_code.toString() : "",
			"ACCOUNT NUMBER":`'${loan?.bank_account?.account_number}`,
			"FIRST GUARANTOR":`${loan?.guarantor_one?.surname?.toUpperCase()} ${loan?.guarantor_one?.first_name?.toUpperCase()} ${loan?.guarantor_one?.middle_name?.toUpperCase()}`,
			"PHONE (FIRST GUARANTOR)":`'${loan?.guarantor_one?.phone} `,
			"WORK PLACE (FIRST_GUARANTOR)":`${loan?.guarantor_one?.work_place}`,
			"SECOND GUARANTOR":`${loan?.guarantor_two?.surname?.toUpperCase()} ${loan?.guarantor_two?.first_name?.toUpperCase()} ${loan?.guarantor_two?.middle_name?.toUpperCase()}`,
			"PHONE (SECOND GUARANTOR)":`'${loan?.guarantor_two?.phone} `,
			"WORK PLACE (SECOND GUARANTOR)":`${loan?.guarantor_two?.work_place}`,
			"DATE DISBURSED":loan?.disburse_date ? `${dayjs(loan?.disburse_date).format("MMM D, YYYY")}` : `${dayjs(loan?.updated_at).format("MMM D, YYYY")}`,
			"MONTHLY REPAYMENT AMOUNT":`${(loan?.monthly_repayment_amount).toLocaleString()}`,
			"MONTHLY REPAYMENT STARTS": loan?.repayment_period_starts ? `${dayjs(loan?.repayment_period_starts).format("MMM D, YYYY")}` : ""
 * 
 */

export function useBankData(){
    const [loadingBanks,setLoadingBanks] = useState(false)
    const [banks,setBanks] = useState([])

	const sortBanks = (bankData)=>{
		if(!bankData){
			return [{ name: "No banks", code: "", id: "hes" }]
		}
		const transformed =  bankData.sort(function (a, b) {
			const nameA = a.name.toUpperCase()
			const nameB = b.name.toUpperCase()
			if (nameA < nameB) return -1 ;
			if (nameA > nameB) return 1 ;
			return 0
		}).map((data)=>({
            ...data,
            label:data.name,
            value:data.code,
        }))

        console.log(transformed[0])
        return transformed
	}

	useEffect(()=>{
		const url = `${BASE_URL}/misc/banks`

		const fetchData = async (apiURL) => {
			const res = await http.get(apiURL)
			return res?.data?.data
		}

		const getUserData = async()=>{
            setLoadingBanks(true)
			const banks = await fetchData(url)
            setLoadingBanks(false)
            if(!banks) return message.error("Sorry could not get banks")
            setBanks(sortBanks(banks))
		}
		getUserData()
	},[])

    return {
        banks,
        loadingBanks,
    }


}
export default function useAddForm(){
    const navigate = useNavigate()
    const [loanAmount, setLoanAmount] = useState("")
    const [loading,setLoading] = useState(false)
    const [refferedBy,setRefferedBy] = useState("")
    const [loanTenure,setLoanTenure] = useState(12)

    const handleSetLoanAmount = (e)=>{
        setLoanAmount(e.target.value)
    }
    const [applicantData, setApplicantData] = useState({
        name:"",
        first_name:"",
        surname:"",
        lga:"",
        phone:"",
        sex:"",
        age:"",
    })
    const handleSetApplicantData =(e)=>{
        setApplicantData(prev=>({
            ...prev,
            [e.target.name]:e.target.value,
        }))
    }
    const [bankDetails, setBankDetails] = useState({
        bank:"",
        bank_code:"",
        account_number:"",
    })
    const handleSetBankDetails =(e)=>{
        setBankDetails(prev=>({
            ...prev,
            [e.target.name]:e.target.value,
        }))
    }
    const [institutionData, setInstitutionData]= useState({
        name:"",
        course:"",
        current_study_year:"",
        faculty:"",
    })
    const handleSetInstitutiontData =(e)=>{
        setInstitutionData(prev=>({
            ...prev,
            [e.target.name]:e.target.value,
        }))
    }
    const handleSetFirstGuarantorData =(e)=>{
        setFirstGuarantorData(prev=>({
            ...prev,
            [e.target.name]:e.target.value,
        }))
    }
    const [secondGuarantorData, setSecondGuarantorData] = useState({
        name:"",
        work_place:"",
        phone:"",
    })
    const handleSetSecondGuarantorData =(e)=>{
        setSecondGuarantorData(prev=>({
            ...prev,
            [e.target.name]:e.target.value,
        }))
    }
    const [firstGuarantorData, setFirstGuarantorData] = useState({
        name:"",
        work_place:"",
        phone:"",
    })

    const handleSubmit = async(e)=>{
        e.preventDefault()
        setLoading(true)
        const [_,error] = await SuperUserService.addLoan({
            institutionData,
            bankDetails,
            loanAmount,
            loanTenure,
            refferedBy,
            monthly_repayment_amount:parseFloat(loanAmount)/parseStringToInt(loanTenure,12),
            applicantData:{
                ...applicantData,
                name:`${applicantData.first_name} ${applicantData.surname}`
            },
            guarantorDetails:{
                first:firstGuarantorData,
                second:secondGuarantorData,
            }
        })
        setLoading(false)
        if(!error){
            setApplicantData({
                name:"",
                lga:"",
                phone:"",
                sex:"",
                age:"",
            })
            setBankDetails({ bank:"",bank_code:"",account_number:"",})
            setFirstGuarantorData({name:"",work_place:"",phone:"",})
            setInstitutionData({
                name:"",
                course:"",
                current_study_year:"",
                faculty:"",
            })
            setSecondGuarantorData({name:"",work_place:"",phone:"",})
            setLoanAmount("")
            navigate("/superuser")
            return message.success("Loan Added Successfully")
        }
        return message.error(error.message || "Sorry could not add Loan")
    }

    return {
        applicantData,
        handleSetApplicantData,
        institutionData,
        handleSetInstitutiontData,
        bankDetails,
        handleSetBankDetails,
        firstGuarantorData,
        handleSetFirstGuarantorData,
        secondGuarantorData,
        handleSetSecondGuarantorData,
        loanAmount,
        handleSetLoanAmount,
        loading,
        setBankDetails,
        refferedBy,
        setRefferedBy,
        handleSubmit,
        loanTenure,
        setLoanTenure,
    }

}