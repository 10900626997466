import http from "services/httpService";

const { BASE_URL } = require("services");

class SuperuserService{
    url = BASE_URL + "/loans/superuser"
    async getLoans(){
        try {
            const { data } = await http.get(this.url + "/all");
            return [data,null]
        } catch (err) {
            return [null, err]
        }
    }
    async deleteLoan(id){
        try {
            const { data } = await http.delete(`${this.url}/${id}`);
            return [data,null]
        } catch (err) {
            return [null, err]
        }
    }
    async addLoan (payload){
        try {
            const { data } = await http.put(this.url,payload)
            return [data,null]
        } catch (err) {
            return [null, err]
        }
    }
}

export default new SuperuserService()