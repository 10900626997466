import MemoDetails from 'components/Dashboard/Memo/MemoDetails'
import MainLayout from 'layout/MainLayout'
import React from 'react'

const MemoDetailsPage = () => {
  return (
    <MainLayout>
      <MemoDetails />
    </MainLayout>
  )
}

export default MemoDetailsPage
