import { Img } from 'globalStyles'
import React, { useEffect, useState } from 'react'
// import './Modal.css'

import {
  accIcon,
  bvnIcon,
  docIcon,
  insIcon,
  nokIcon,
  stpAvatar,
  stpIcon,
  wordIcon,
} from 'utils/assets'
import {
  AccDiv,
  BvnConDiv,
  BvnLeft,
  BvnRight,
  CardFlex,
  ConFirst,
  ConSec,
  DocHeader,
  InsConDiv,
  InsLeft,
  InsRight,
  LoanDetailsHead,
  LoanDocDiv,
  NokConDiv,
  NokLeft,
  NokRight,
  ProTabBtnDiv,
  ProTabCon,
  ProTabContent,
  ProTabDiv,
  StpConRight,
  StpContent,
  StpContentDiv,
  StpLeft,
  StpRight,
} from './style'
import FileCard from './FileCard'
// import PdfCard from './PdfCard'
import ModalComp from './ModalComp'
import useAdminData from '../../../../hooks/useAdminData'
import { message } from 'antd'
import { updateLoanAmount } from 'services/LoanService'
import GuarantorTab from './GuarantorTab'
import ActionModal from './ActionModal'
import LPHAcceptBtn from './LPHAcceptBtn'

function getStatusData(status) {
  if (status === 'approved') return { color: '#5caa77', text: 'Approved' }
  if (status === 'rejected') return { color: '#e23644', text: 'Rejected' }
  if (status === 'pending') return { color: '#ffae00', text: 'Pending' }
  if (status === 'disbursed') return { color: '#5caa77', text: 'Disbursed' }
  if (status === 'reviewing') return { color: '#9E9E9E', text: 'Reviewing' }
  return { color: '#e23644', text: 'Rejected' }
}

function StatusTag({ statusData }) {
  const style = {
    button: {
      padding: '8px 32px',
      background: statusData.color,
      borderRadius: '8px',
      border: 'none',
    },
    span: {
      textTransform: 'capitalize',
    },
  }
  return (
    <button style={style.button}>
      <span style={style.span}>{statusData.text}</span>
    </button>
  )
}



const LoanProTab = ({ loan,getLoan }) => {
  // console.log({loan})'
  const {adminRole} = useAdminData()
  const loanData = loan?.data[0]
  const [tabState, setTabState] = useState(1)
  const [amount,setAmount] = useState("0")
  const [modalOpen,setModalOpen] = useState(false)
  const [loading,setLoading] = useState(false)
  const statusData = getStatusData(loanData?.status)

  const openModal = ()=>{
    setModalOpen(true)
  }
  const closeModal = ()=>{
    setModalOpen(false)
  }
  const activeTab = (index) => {
    setTabState(index)
  }

  useEffect(() => {
    if(loanData){
      setAmount(loanData.amount.toString())
    }
  },[loanData])

  async function updateAmount(){
    if(amount.trim().length === 0){
      return message.error("Please provide a valid loan amount")
    }
    if(parseFloat(amount) < 1000){
      return message.error("Please provide a valid loan amount")
    }
    setLoading(true)
    const res = await updateLoanAmount({
      amount,
      id:loanData.id
    })
    if(res){
      await getLoan()
      closeModal()
      message.success("Loan amount updated successfully")
    }
    setLoading(false)
  }


  const dob = new Date(loanData?.applicant?.dob)
  return (
    <div className="p-4">
      <ActionModal onOk={updateAmount} onClose={closeModal} loading={loading} modalOpen={modalOpen}>
        <p className="text-2xl text-center font-semibold">Update Loan Amount</p>
        <div className="my-2">
          <input type="number" value={amount} onChange={(e)=>setAmount(e.target.value)} placeholder="Enter New Loan Amount" className="border p-2 w-full border-gray-100 rounded-lg"/>
        </div>
      </ActionModal>


      <ProTabCon>
        <ProTabDiv>
          <LoanDetailsHead className="mb-4">Loan Details</LoanDetailsHead>
          <ProTabBtnDiv>
            <Img src={stpIcon} alt="Student Icon" />
            <button
              className={tabState === 1 ? 'tabs active-tabs' : 'tabs'}
              onClick={() => activeTab(1)}
            >
              Student Profile
            </button>
          </ProTabBtnDiv>
          <ProTabBtnDiv>
            <Img src={insIcon} alt="Institution Icon" />

            <button
              className={tabState === 2 ? 'tabs active-tabs' : 'tabs'}
              onClick={() => activeTab(2)}
            >
              Institution
            </button>
          </ProTabBtnDiv>

          <ProTabBtnDiv>
            <Img src={nokIcon} alt="Next of Kin Icon" />

            <button
              className={tabState === 3 ? 'tabs active-tabs' : 'tabs'}
              onClick={() => activeTab(3)}
            >
              Next of kin
            </button>
          </ProTabBtnDiv>
          <ProTabBtnDiv>
            <Img src={nokIcon} alt="Next of Kin Icon" />

            <button
              className={tabState === 8 ? 'tabs active-tabs' : 'tabs'}
              onClick={() => activeTab(8)}
            >
              Guarantor Details
            </button>
          </ProTabBtnDiv>
          <ProTabBtnDiv>
            <Img src={docIcon} alt="Document Icon" />

            <button
              className={tabState === 4 ? 'tabs active-tabs' : 'tabs'}
              onClick={() => activeTab(4)}
            >
              Documents
            </button>
          </ProTabBtnDiv>

          <ProTabBtnDiv>
            <Img src={accIcon} alt="Account Icon" />

            <button
              className={tabState === 5 ? 'tabs active-tabs' : 'tabs'}
              onClick={() => activeTab(5)}
            >
              Account info
            </button>
          </ProTabBtnDiv>

          <ProTabBtnDiv>
            <Img src={bvnIcon} alt="Bvn Icon" />

            <button
              className={tabState === 6 ? 'tabs active-tabs' : 'tabs'}
              onClick={() => activeTab(6)}
            >
              BVN Data
            </button>
          </ProTabBtnDiv>
          <ProTabBtnDiv>
            <Img src={bvnIcon} alt="Bvn Icon" />

            <button
              className={tabState === 7 ? 'tabs active-tabs' : 'tabs'}
              onClick={() => activeTab(7)}
            >
              Loan Info
            </button>
          </ProTabBtnDiv>

          <ModalComp loan={loan} />
        </ProTabDiv>
        <ProTabContent>
          {(adminRole === "lph" || adminRole === "la") && loanData && loanData.lph_status !== "accepted" && <LPHAcceptBtn loan={loanData} refetch={getLoan}/>}
          <div
            className={tabState === 1 ? 'content  active-content' : 'content'}
          >
            <StpContent>
              <Img
                src={
                  loan?.data[0]?.applicant?.avatar
                    ? loan?.data[0]?.applicant?.avatar
                    : `${stpAvatar}`
                }
                alt="student avatar"
              />
              <StpConRight>
                <ConFirst>
                  <div>
                    <label>Firstname</label>
                    <h4>
                      {loan?.data[0]?.applicant?.first_name
                        ? loan?.data[0]?.applicant?.first_name
                        : 'Not Provided'}
                    </h4>
                  </div>
                  <div>
                    <label>MiddleName</label>
                    <h4>
                      {loan?.data[0]?.applicant?.middle_name
                        ? loan?.data[0]?.applicant?.middle_name
                        : 'Not Provided'}
                    </h4>
                  </div>
                  <div>
                    <label>Surname</label>
                    <h4>
                      {loan?.data[0]?.applicant?.surname
                        ? loan?.data[0]?.applicant?.surname
                        : 'Not Provided'}
                    </h4>
                  </div>
                </ConFirst>
                <ConSec>
                  <div>
                    <label>Address:</label>
                    <span>
                      {loan?.data[0]?.applicant?.address
                        ? loan?.data[0]?.applicant?.address
                        : 'Not Provided'}
                    </span>
                  </div>
                  <div>
                    <label> Email:</label>
                    <span>
                      {loan?.data[0]?.applicant?.email
                        ? loan?.data[0]?.applicant?.email
                        : 'Not Provided'}
                    </span>
                  </div>
                  <div>
                    <label>Phone:</label>
                    <span>
                      {loan?.data[0]?.applicant?.phone
                        ? loan?.data[0]?.applicant?.phone
                        : 'Not Provided'}
                    </span>
                  </div>
                </ConSec>
              </StpConRight>
            </StpContent>
            <StpContentDiv>
              <StpLeft>
                <div>
                  <label>LGA</label>
                  <h4>
                    {loan?.data[0]?.applicant?.lga
                      ? loan?.data[0]?.applicant?.lga
                      : 'Not Provided'}
                  </h4>
                </div>
                <div>
                  <label>Gender</label>
                  <h4>
                    {loan?.data[0]?.applicant?.gender
                      ? loan?.data[0]?.applicant?.gender
                      : 'Not Provided'}
                  </h4>
                </div>
                <div>
                  <label>Employment Status</label>
                  <h4>
                    {loan?.data[0]?.applicant?.employment_status
                      ? loan?.data[0]?.applicant?.employment_status
                      : 'Not Provided'}
                  </h4>
                </div>
              </StpLeft>
              <StpRight>
                <div>
                  <label>Hometown</label>
                  <h4>
                    {loan?.data[0]?.applicant?.hometown
                      ? loan?.data[0]?.applicant?.hometown
                      : 'Not Provided'}
                  </h4>
                </div>
                <div>
                  <label>Date Of Birth</label>
                  <h4>
                    {loan?.data[0]?.applicant?.dob
                      ? `${dob.getDate()}/${
                          dob.getMonth() + 1
                        }/${dob.getFullYear()}`
                      : 'Not Provided'}
                  </h4>
                </div>
              </StpRight>
            </StpContentDiv>
          </div>
          <div
            className={tabState === 2 ? 'content  active-content' : 'content'}
          >
            <InsConDiv>
              <InsLeft>
                <div>
                  <label>Name Of Institution</label>
                  <h4>
                    {loan?.data[0]?.institution?.name
                      ? loan?.data[0]?.institution?.name
                      : 'Not Provided'}
                  </h4>
                </div>
                <div>
                  <label>Faculty</label>
                  <h4>
                    {loan?.data[0]?.institution?.faculty
                      ? loan?.data[0]?.institution?.faculty
                      : 'Not Provided'}
                  </h4>
                </div>
                <div>
                  <label>Year Of Study</label>
                  <h4>
                    {loan?.data[0]?.institution?.current_study_year
                      ? loan?.data[0]?.institution?.current_study_year
                      : 'Not Provided'}
                  </h4>
                </div>
                <div>
                  <label>Certificate in View</label>
                  <h4>
                    {loan?.data[0]?.certificate
                      ? loan?.data[0]?.certificate
                      : 'Not Provided'}
                  </h4>
                </div>
              </InsLeft>
              <InsRight>
                <div>
                  <label>Course Of Study</label>
                  <h4>
                    {loan?.data[0]?.institution?.course
                      ? loan?.data[0]?.institution?.course
                      : 'Not Provided'}
                  </h4>
                </div>
                <div>
                  <label>Matric No.</label>
                  <h4>
                    {loan?.data[0]?.institution?.matric_number
                      ? loan?.data[0]?.institution?.matric_number
                      : 'Not Provided'}
                  </h4>
                </div>
                <div>
                  <label>Year Of Graduation</label>
                  <h4>
                    {loan?.data[0]?.institution?.graduation_year
                      ? loan?.data[0]?.institution?.graduation_year
                      : 'Not Provided'}
                  </h4>
                </div>
                <div>
                  <label>Loan Category</label>
                  <h4>
                    {loan?.data[0]?.loan_type
                      ? loan?.data[0]?.loan_type
                      : 'Not Provided'}
                  </h4>
                </div>
              </InsRight>
            </InsConDiv>
          </div>
          <div
            className={tabState === 3 ? 'content  active-content' : 'content'}
          >
            <NokConDiv>
              <NokLeft>
                <div>
                  <label>Surname</label>
                  <h4>
                    {loan?.data[0]?.applicant?.next_of_kin?.surname
                      ? loan?.data[0]?.applicant?.next_of_kin?.surname
                      : 'Not Provided'}
                  </h4>
                </div>
                <div>
                  <label>Middle Name</label>
                  <h4>
                    {loan?.data[0]?.applicant?.next_of_kin?.middle_name
                      ? loan?.data[0]?.applicant?.next_of_kin?.middle_name
                      : 'Not Provided'}
                  </h4>
                </div>
                <div>
                  <label>Address</label>
                  <h4>
                    {loan?.data[0]?.applicant?.next_of_kin?.address
                      ? loan?.data[0]?.applicant?.next_of_kin?.address
                      : 'Not Provided'}
                  </h4>
                </div>
              </NokLeft>
              <NokRight>
                <div>
                  <label>Firstname</label>
                  <h4>
                    {loan?.data[0]?.applicant?.next_of_kin?.first_name
                      ? loan?.data[0]?.applicant?.next_of_kin?.first_name
                      : 'Not Provided'}
                  </h4>
                </div>
                <div>
                  <label>Phone</label>
                  <h4>
                    {loan?.data[0]?.applicant?.next_of_kin?.phone
                      ? loan?.data[0]?.applicant?.next_of_kin?.phone
                      : 'Not Provided'}
                  </h4>
                </div>
                <div>
                  <label>Relationship with Applicant</label>
                  <h4>
                    {loan?.data[0]?.applicant?.next_of_kin?.relationship
                      ? loan?.data[0]?.applicant?.next_of_kin?.relationship
                      : 'Not Provided'}
                  </h4>
                </div>
              </NokRight>
            </NokConDiv>
          </div>
          <div
            className={tabState === 4 ? 'content  active-content' : 'content'}
          >
            <LoanDocDiv>
              <DocHeader>Loan Requirement Documents Page</DocHeader>
              <CardFlex>
                <FileCard
                  fileTxt="Admission Letter"
                  imgUrl={
                    loan?.data[0]?.admission_letter
                      ? loan?.data[0]?.admission_letter
                      : `${wordIcon}`
                  }
                  imgDesc="document"
                />
                <FileCard
                  fileTxt="Signed Letter from School"
                  imgUrl={
                    loan?.data[0]?.signed_letter_id
                      ? loan?.data[0]?.signed_letter_id
                      : `${wordIcon}`
                  }
                  imgDesc="document"
                />

                {loan?.data[0]?.institution.school_identity_card && (
                  <FileCard
                    fileTxt="School Identity Card"
                    imgUrl={
                      loan?.data[0]?.institution.school_identity_card
                        ? loan?.data[0]?.institution.school_identity_card
                        : `${wordIcon}`
                    }
                    imgDesc="document"
                  />
                )}
                
                {loan?.data[0]?.school_fee_receipt && (
                  <FileCard
                    fileTxt="Proof of Previous Fee"
                    imgUrl={
                      loan?.data[0]?.school_fee_receipt
                        ? loan?.data[0]?.school_fee_receipt
                        : 'Do not apply'
                    }
                    imgDesc="document"
                  />
                )}

                {/* {loan?.data[0]?.applicant?.school_fee_receipt && (
                <FileCard
                  fileTxt="Proof of Previous Fee"
                  imgUrl={
                    loan?.data[0]?.school_fee_receipt
                      ? loan?.data[0]?.school_fee_receipt
                      : `${wordIcon}`
                  }
                  imgDesc="document"
                />
              )} */}

                {loan?.data[0]?.previous_certificate && (
                  <FileCard
                    fileTxt="Previous Certificate"
                    imgUrl={
                      loan?.data[0]?.previous_certificate
                        ? loan?.data[0]?.previous_certificate
                        : `${wordIcon}`
                    }
                    imgDesc="document"
                  />
                )}

                {/* {loan?.data[0]?.applicant?.othercertificate && (
                <FileCard
                  fileTxt="Previous Certificate"
                  imgUrl={
                    loan?.data[0]?.othercertificate
                      ? loan?.data[0]?.othercertificate
                      : `${wordIcon}`
                  }
                  imgDesc="document"
                />
              )} */}
              </CardFlex>

              <DocHeader>Personal Documents</DocHeader>
              <CardFlex>
                <FileCard
                  fileTxt="Means of Identification"
                  imgUrl={
                    loan?.data[0]?.applicant?.identification
                      ? loan?.data[0]?.applicant?.identification
                      : `${wordIcon}`
                  }
                  imgDesc="document"
                />
                <FileCard
                  fileTxt="LGA Certificate"
                  imgUrl={
                    loan?.data[0]?.applicant?.lga_certificate
                      ? loan?.data[0]?.applicant?.lga_certificate
                      : `${wordIcon}`
                  }
                  imgDesc="document"
                />
                <FileCard
                  fileTxt="Birth Certificate"
                  imgUrl={
                    loan?.data[0]?.applicant?.birth_certificate
                      ? loan?.data[0]?.applicant?.birth_certificate
                      : `${wordIcon}`
                  }
                  imgDesc="document"
                />
              </CardFlex>
            </LoanDocDiv>
          </div>
          <div
            className={tabState === 5 ? 'content  active-content' : 'content'}
          >
            <AccDiv>
              <div>
                <label>Account Name</label>
                <h4>
                  {loan?.data[0]?.applicant?.bank_account?.account_name
                    ? loan?.data[0]?.applicant?.bank_account?.account_name
                    : 'Not Provided'}{' '}
                </h4>
              </div>
              <div>
                <label>Account Number</label>
                <h4>
                  {loan?.data[0]?.applicant?.bank_account?.account_number
                    ? loan?.data[0]?.applicant?.bank_account?.account_number
                    : 'Not Provided'}{' '}
                </h4>
              </div>
              <div>
                <label>Bank Name</label>
                <h4>
                  {loan?.data[0]?.applicant?.bank_account?.bank_name
                    ? loan?.data[0]?.applicant?.bank_account?.bank_name
                    : 'Not Provided'}{' '}
                </h4>
              </div>
              <div>
                <label>Bank Code</label>
                <h4>
                  {loan?.data[0]?.applicant?.bank_account?.bank_code
                    ? loan?.data[0]?.applicant?.bank_account?.bank_code
                    : 'Not Provided'}{' '}
                </h4>
              </div>
            </AccDiv>
          </div>
          <div
            className={tabState === 6 ? 'content  active-content' : 'content'}
          >
            <BvnConDiv>
              <BvnLeft>
                <div>
                  <label>Bank Verification Number (BVN)</label>
                  <h4>
                    {loan?.data[0]?.applicant?.bvn
                      ? loan?.data[0]?.applicant?.bvn
                      : 'Not Provided'}
                  </h4>
                </div>
              </BvnLeft>
              <BvnRight></BvnRight>
            </BvnConDiv>
          </div>
          <div
            className={tabState === 7 ? 'content  active-content' : 'content'}
          >
            <BvnConDiv>
              <BvnLeft>
                <div>
                  <div>
                    <label>Loan Comments</label>
                    <h4>{loan?.data[0]?.comment || 'No Message available'}</h4>
                  </div>
                  <div style={{ marginTop: '8px' }}>
                    <label>Status</label>
                    <StatusTag statusData={getStatusData(loanData.status)} />
                  </div>
                  {loanData.reffered ? (
                    <div style={{ marginTop: '8px' }}>
                      <label>Reffered By</label>
                      <h4>
                        {loanData.reffered_by_name} -{' '}
                        <b>{loanData.refferedBy}</b>
                      </h4>
                    </div>
                  ) : (
                    (statusData.text = 'rejected' ? (
                      <div style={{ marginTop: '8px' }}>
                        <p>Rejected By</p>
                        <h4>
                          {loanData.rejectedBy} -{' '}
                          <b>{loanData.rejected_by_email}</b>
                        </h4>
                      </div>
                    ) : null)
                  )}
                  <div style={{ marginTop: '8px' }}>
                    <label>Application Date</label>
                    <h4>{new Date(loanData.created_at).toUTCString()}</h4>
                  </div>
                  <div style={{ marginTop: '8px' }}>
                    <label>Loan Amount</label>
                    <h4>₦{loanData.amount.toLocaleString()}</h4>
                    {
                      (loanData.status === "pending" || loanData.status === "reviewing") &&
                      (adminRole === "lph" || adminRole === "es") && (
                        <button onClick={openModal} className="bg-primary-100 text-primary-800 border px-4 border-primary-400 rounded-md py-2 text-xs">Update Amount</button>
                      )
                    }
                  </div>

                  <div style={{ margin: '8px auto' }}>
                    <label>Loan Tenor </label>
                    <h4>
                      {loanData?.loan_tenure
                        ? `${loanData?.loan_tenure} months`
                        : 'Not Provided'}
                    </h4>
                  </div>
                  <div style={{ margin: '8px auto' }}>
                    <label>Monthly Repayment Amount </label>
                    <h4>
                      {loanData?.loan_tenure
                        ? `₦${loanData?.monthly_repayment_amount.toLocaleString()}`
                        : 'Not Provided'}
                    </h4>
                  </div>
                  {/* <p style={{textTransform:"capitalize",padding:"16px",fontWeight:"bold"}}>{loan?.data[0]?.status || "N/A"}</p> */}
                </div>
              </BvnLeft>
              <BvnRight></BvnRight>
            </BvnConDiv>
          </div>
          <div className={tabState === 8 ? 'content  active-content' : 'content'}>
              <GuarantorTab loan={loanData}/>
          </div>
        </ProTabContent>
      </ProTabCon>
    </div>
  )
}

export default LoanProTab
