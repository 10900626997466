import {Flex, Table} from 'antd';
import { APPROVED_COLUMNS } from './appColumns';

export default function ApprovedLoanTable({dataSource}){
        
        return (
            <Flex gap="middle" vertical>
                <Table
                columns={APPROVED_COLUMNS}
                dataSource={dataSource}
                />
            </Flex>
        );
};