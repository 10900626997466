const { Select } = require("antd")

const LoanFilter = ({filter,handleFilter,options})=>{

	return (
		<div className="flex items-center ml-auto gap-2">
			<div><p className="font-medium">Filter By</p></div>
			<div className="flex-1">
				<Select
					onChange={handleFilter}
					style={{width: "150px"}}
					value={filter}
					defaultValue={""}
					options={options}
				/>
			</div>
		</div>
	)
}

export default LoanFilter