import { useEffect, useState } from "react"
import { getAllLoans } from "services/LoanService"

export default function useLoan(count){
    const [loading,setLoading] = useState(true)
    const [loans, setLoans] = useState([])

    const fetchAllLoans = async () => {
        setLoading(true)
		const result = await getAllLoans()
        setLoading(false)

		setLoans(result?.data)
		return result?.data
	}
	useEffect(() => {
		fetchAllLoans()
	}, [count])
    return {loans,loading}
}