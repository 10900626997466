import FullScreenLoader from "components/Dashboard/FullScreenLoader"
import React from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { getLoan } from "services/LoanService"

import LoanProTab from "./LoanProTab"

const LoanDetailsComp = () => {
	const params = useParams()
	const { data: Loan, isLoading,refetch } = useQuery(["loan", params?.id], () => getLoan(params?.id))

	// console.log("Our params", Loan)
	if (isLoading) {
		return <FullScreenLoader />
	}
	return (
		<div>
			<LoanProTab loan={Loan} getLoan={refetch} />
		</div>
	)
}

export default LoanDetailsComp
