import React, {useState } from "react"
import useLoan from "hooks/useLoans"
import DisLoansComp from "../Loan/DisbursedLoans"
import useAdminData from "hooks/useAdminData"
import AcceptedLoans from "../Loan/AcctAcceptedLoans"
import TabHeader from "../TabHeader"
// import { withContext } from "context/AccountantContext"


const tabs = [
	{label:"Approved",index:1},
	{label:"Disbursed",index:2},
 ]

export default function AccountantTabs() {
    const {userProfile,adminRole} = useAdminData()
	const [toggleState, setToggleState] = useState(1)
	const {loans} = useLoan(toggleState)
	const refferedLoans = loans.filter(loan => loan.reffered)

	console.log({
		refferedLoans,
	})

	return (
		<section className="bg-gray-100">
			<TabHeader
				currentTab={toggleState}
				selectTab={setToggleState}
				tabs={tabs}
			/>
			<div className="p-6">
				{toggleState === 1
				?(
					<div className={toggleState === 1 ? "content  active-content" : "content"}>
						<h1 className="text-4xl my-8 mb-12 font-semibold text-[#333333]">Approved Loans</h1>
						<AcceptedLoans admin_role={adminRole} user={userProfile} />
						{/* <LoanTable allLoans={loans} /> */}
					</div>
				)
				:(
					<div className={toggleState === 2 ? "content  active-content" : "content"}>
						<h1 className="text-4xl my-8 mb-12 font-semibold text-[#333333]">Disbursed Loans</h1>
						<DisLoansComp />
						{/* <LoanTable allLoans={loans} /> */}
					</div>
			    )}
			</div>
		</section>
	)
}
