import CustomSelectMain from "components/SelectInput"
import TextAreaComp from "components/TextArea/TextAreaComp"
import { useFormik } from "formik"
import * as Yup from "yup"
import React, { useMemo, useState } from "react"
import { createMemo, memoServiceEs, memoServiceOthers, useAdminData } from "services/AdminUserService"
import authStore from "mobx/AuthStore"
import { ModalDiv } from "./LoanDetails/style"
import { Div } from "globalStyles"
import { BASE_URL } from "services"
import http from "services/httpService"
import { useQuery } from "react-query"
import { SendMemoBtn, SendMemoBtnTop } from "./style"
import FullScreenLoader from "../FullScreenLoader"
import { useParams } from "react-router-dom"
import { toast } from "react-hot-toast"
import { observer } from "mobx-react-lite"

function SendMemoComp({ user, disabled, title = "Send Memo" }) {
	const [sendMemo, setSendMemo] = useState(false)
	const params = useParams()
	const url = `${BASE_URL}/admin/who_i_am`
	const fetcher = async () => {
		const res = await http.get(url)
		return res?.data?.data
	}

	// console.log("Paeramssnn||||", params)
	const { data: userProfile } = useQuery("profile", fetcher)
	const onSuccess = () => {
		// console.log("admin data fetched successfully", adminData?.data?.data)
	}
	const onError = () => {
		// console.log("There is an error here", error)
	}

	const { isLoading, data: adminData } = useAdminData(onSuccess, onError)

	const toggleSendMemo = () => {
		if (disabled) return toast.error("Loan (s) under this table has been disbursed, you cannot send memo anymore")
		setSendMemo(!sendMemo)
	}

	const data = useMemo(() => {
		const allAdmin = adminData?.data?.data ? adminData?.data?.data : []
		const adminFilter = allAdmin?.filter(admin => admin.first_name && (admin.admin_role === "es" || admin.admin_role === "la"))
		const adminFilterEs = allAdmin?.filter(admin => admin.first_name && (admin.admin_role === "acc" || admin.admin_role === "lph"))
		const adminFilterLa = allAdmin?.filter(admin => admin.first_name && admin.admin_role === "lph")
		return { adminFilter, adminFilterLa, adminFilterEs }
	}, [adminData?.data?.data])

	const formik = useFormik({
		enableReinitialize: false,
		initialValues: {
			status: "",
			to_admin: "",
			note: ""
		},
		onSubmit: async (data, helpers) => {
			
			// console.log("Hell", userProfile?.admin_role, data)
			if (userProfile?.admin_role === "es") {
				const payload = { ...data }
				console.log("The Payload", payload)
				payload.id = params?.id ? params.id : ""
				payload.memoId = params?.threadId ? params.threadId : ""
				const res = await memoServiceEs(payload)

				if (!res.error) {
					helpers.resetForm()
					toggleSendMemo()
				}
			}

			if (userProfile?.admin_role === "lph") {
				// console.log(userProfile)
				const payload = { ...data }
				payload.id = params?.id ? params.id : ""
				payload.memoId = params?.threadId ? params.threadId : ""
				const res = await createMemo(payload)

				if (!res.error) {
					helpers.resetForm()
					toggleSendMemo()
				}
			}

			if (userProfile?.admin_role === "la") {
				const payload = { ...data }
				payload.id = params?.id ? params.id : ""
				payload.memoId = params?.threadId ? params.threadId : ""
				const res = await memoServiceOthers(payload)
				if (!res.error) {
					helpers.resetForm()
					toggleSendMemo()
				}
			}
		},
		validationSchema: Yup.object({
			note: Yup.string().required().label("Messaage"),
			to_admin: Yup.string().required().label("Send To:"),
			status: Yup.string().required().label("Status")
		})
	})

	const toAdmin = userProfile?.admin_role === "es" ? data.adminFilterEs : userProfile?.admin_role === "la" ? data.adminFilterLa : userProfile?.admin_role === "lph" ? data?.adminFilter : []
	if (isLoading) return <FullScreenLoader show={true} />
	return (
		<div>
			{/* <Div width="100%" display="flex" justify="flex-end"> */}
			<SendMemoBtnTop onClick={() => toggleSendMemo()}>{title}</SendMemoBtnTop>
			{/* </Div> */}

			{sendMemo && (
				<ModalDiv>
					<div className="modal">
						<div>
							<form className="modal-content" onSubmit={formik.handleSubmit}>
								<Div width="100%" display="flex" justify="flex-end">
									<button type="button" className="close-modal" onClick={() => toggleSendMemo()}>
										CLOSE
									</button>
								</Div>

								<CustomSelectMain
									label="Send To:"
									name="to_admin"
									id="to_admin"
									value={formik.values.to_admin}
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									error={formik.errors.to_admin}
									visible={formik.touched.to_admin}
									width="250px"
								>
									<option value="">Select receiver</option>
									{toAdmin?.map(adminuser => (
										<option value={adminuser._id}>
											{adminuser?.surname} &nbsp;{adminuser?.first_name} --- &nbsp;{" "}
											{adminuser.admin_role === "es"
												? "Executive Secretary"
												: adminuser?.admin_role === "lo"
												? "Loan Officer"
												: adminuser?.admin_role === "lph"
												? "Loan Processing Head"
												: adminuser?.admin_role === "la"
												? "Auditor"
												: adminuser?.admin_role === "fdo"
												? "Front Desk Officer"
												: adminuser?.admin_role === "blog"
												? "Blogger"
												: adminuser?.admin_role === "acc"
												? "Accountant"
												: "NIL"}
										</option>
									))}
								</CustomSelectMain>

								<CustomSelectMain
									label="Status"
									name="status"
									id="status"
									value={formik.values.status}
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									error={formik.errors.status}
									visible={formik.touched.status}
									width="250px"
								>
									<option value="">Select status</option>
									{userProfile?.admin_role === "es" && <option value="disbursed">Disburse</option>}
									{userProfile?.admin_role !== "es" && <option value="approved">Approve</option>}
									<option value="rejected">Reject</option>
								</CustomSelectMain>

								<TextAreaComp name="note" id="note" value={formik.values.note} onBlur={formik.handleBlur} onChange={formik.handleChange} error={formik.errors.note} visible={formik.touched.note} />
								<SendMemoBtn disabled={authStore.isLoading} type="submit">
									{authStore.isLoading ? "Sending memo..." : "Proceed"}
								</SendMemoBtn>
							</form>
						</div>
					</div>
				</ModalDiv>
			)}
		</div>
	)
}

export default observer(SendMemoComp)
