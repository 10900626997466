import React from 'react'
import Tabs from './Tabs'
import LoanOfficerTabs from '../LoanOfficer/LoanOfficerTab'
import useAdminData from 'hooks/useAdminData'
import LPHTabs from '../LoanProcessingHead/LPHTabs'
import FullScreenLoader from '../FullScreenLoader'
import AccountantTabs from '../Accountant/AccountantTab'

const LoanComp = () => {
  const {adminRole,isLoading} = useAdminData()
  if(isLoading) return <FullScreenLoader show={isLoading}/>
  if(adminRole === "lo") return <LoanOfficerTabs/>
  if(adminRole === "lph" || adminRole === "la") return <LPHTabs/>
  if(adminRole === "acc") return <AccountantTabs/>
  return (
    <div >
    <Tabs/>
    </div>
  )
}

export default LoanComp