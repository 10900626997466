import LoanDetailsComp from 'components/Dashboard/Loan/LoanDetails'
import MainLayout from 'layout/MainLayout'
import React from 'react'

const LoanDetailsPage = () => {
  return (
    <MainLayout>
      <LoanDetailsComp />
    </MainLayout>
  )
}

export default LoanDetailsPage
