// import { Link } from "react-router-dom";
// import { DASHBOARD_LOAN_DETAILS } from "routes";

import dayjs from "dayjs"
import { Link } from "react-router-dom"
import { DASHBOARD_MEMO_DETAILS_ROUTE } from "routes"

export const READ_COLUMNS = [
	{
		Header: "ID",
		accessor: (originalRow, rowIndex) => rowIndex + 1
	},
	{
		Header: "From",
		accessor: (originalRow, rowIndex) => `${originalRow?.admin?.surname} ${originalRow?.admin?.first_name}`
	},
	{
		Header: "Date",
		accessor: (originalRow, rowIndex) => dayjs(originalRow?.createdAt).format("MMM D, YYYY HH:MM A")
	},
	{
		Header: "Email",
		accessor: (originalRow, rowIndex) => `${originalRow?.admin?.email}`
	},
	{
		Header: "Role",
		accessor: (originalRow, rowIndex) =>
			originalRow?.admin?.admin_role === "es"
				? "Super Admin"
				: originalRow?.admin?.admin_role === "lo"
				? "Loan Officer"
				: originalRow?.admin?.admin_role === "lph"
				? "Loan Processing Head"
				: originalRow?.admin?.admin_role === "la"
				? "Auditor"
				: originalRow?.admin?.admin_role === "fdo"
				? "Front Desk Officer"
				: originalRow?.admin?.admin_role === "blog"
				? "Blogger"
				: originalRow?.admin?.admin_role === "acc"
				? "Accountant"
				: "NIL"
	},
	{
		Header: "Memo Status",
		accessor: (originalRow, rowIndex) => `${originalRow?.status}`
	},

	{
		Header: "View",
		accessor: (originalRow, rowIndex) =>
			originalRow?.memo_id ? (
				<Link to={`${DASHBOARD_MEMO_DETAILS_ROUTE}/${originalRow.id}/${originalRow.memo_id}`}>View</Link>
			) : (
				<Link to={`${DASHBOARD_MEMO_DETAILS_ROUTE}/${originalRow.id}/${originalRow.id}`}>View</Link>
			)
	}
]
