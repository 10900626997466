import StaffComp from 'components/Dashboard/Staff'
import MainLayout from 'layout/MainLayout'
import React from 'react'

const StaffPage = () => {
  return (
    <MainLayout>
      <StaffComp />
    </MainLayout>
  )
}

export default StaffPage
