import styled, { keyframes } from "styled-components"


export const SlideInPopUp = keyframes`
from {
    opacity: 0
}
to{
    opacity: 1
}
`
export const FullScreenLoaderContainer = styled.div`
	height: 100%;
	top: 0%;
	left: 0%;
	right: 0%;
	bottom: 0%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${({ show }) => (show ? "rgba(0, 0, 0, 0.7)" : "#920583")};
	position: fixed;
	transition: all 0.5s ease-out;
	animation-name: ${SlideInPopUp};
	animation-duration: 0.5s;
	z-index: 800;
`
