import React from "react"
import { observer } from "mobx-react-lite"
import checkboxStore from "mobx/CheckboxStore"
import Colors from "utils/colors"

const Checkbox = observer(({ value, label }) => {
	const isChecked = checkboxStore.selectedValues.includes(value)

	const handleCheckboxChange = event => {
		checkboxStore.onChange(value, event.target.checked)
	}

	return (
		<div>
			<input style={{ accentColor: Colors.primaryLight, cursor: "pointer" }} type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
		</div>
	)
})
export const CheckBox = ({ checked,handleCheck }) => {
	return (
		<div>
			<input style={{ accentColor: Colors.primaryLight, cursor: "pointer" }} type="checkbox" checked={checked} onChange={handleCheck} />
		</div>
	)
}

export default Checkbox
