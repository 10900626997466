import React from "react"
import { ErrorMsgSpan, InputText, TextInputContainer } from "./style"

function TextInput({ name, type = "text", id, onChange, label, mt = "", labelColor="", width = "100%", error, visible, ErrorMt, ...rest }) {
	return (
		<TextInputContainer mt={mt} width={width} labelColor={labelColor}>
			<label htmlFor={name}>{label}</label>
			<InputText type={type} name={name} id={id} onChange={onChange} {...rest} />
			{visible && error ? <ErrorMsgSpan ErrorMt={`${mt}`}>{error}</ErrorMsgSpan> : null}
		</TextInputContainer>
	)
}

export default TextInput
