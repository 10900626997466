import React, { useMemo, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import { viewLoanAccount } from 'services/ReappLoanService';
import LoanDetails from './LoanDetails';
import { TableContainer, TableDiv } from 'components/Dashboard/Loan/style';
import SectionLoader from 'components/SectionLoader';
import EmptyData from 'components/Emptydata';
import ReappFilterComp from './ReappFilterComp';
import { COLUMNS } from './columns';
import styled from 'styled-components';
import Colors from 'utils/colors';



const StyledButton = styled.button`
  background-color: ${Colors.primary};
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  
  &:hover {
	  background-color: ${Colors.primaryLight};
  }
`;


const ReappUpload = () => {
  const [selectedLoanId, setSelectedLoanId] = useState(null);

  const { data: loanData, isLoading, error } = useQuery("loan-account", () => viewLoanAccount(''));

  useEffect(() => {
  }, [loanData]);

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => loanData?.loans || [], [loanData?.loans]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    state,
    setGlobalFilter,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }
    },
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, pageSize, globalFilter } = state;

  if (isLoading) {
    return <SectionLoader />;
  }

  if (error) {
    return <div>Error loading data</div>;
  }


  return (
    <>
      <TableContainer>
        <ReappFilterComp filter={globalFilter} setFilter={setGlobalFilter} />
        <TableDiv {...getTableProps()}>
          <thead>
            {headerGroups?.map(headerGroup => (
              <tr {...headerGroup?.getHeaderGroupProps()}>
                {headerGroup.headers?.map(column => (
                  <th {...column?.getHeaderProps()}> {column.render("Header")} </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page?.map(row => {
              prepareRow(row);
              return (
                <tr {...row?.getRowProps()}>
                  {row?.cells?.map(cell => (
                    <td {...cell?.getCellProps()}> {cell?.render("Cell")} </td>
                  ))}
                  <td>
                    <StyledButton onClick={() => setSelectedLoanId(row.original.id)}>View Details</StyledButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </TableDiv>
        {data.length === 0 && <EmptyData text="No loan record yet" />}

        {data.length > 0 && (
          <div>
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <span>
              | Go to page:{" "}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(pageNumber);
                }}
                style={{ width: "50px" }}
              />
            </span>
            <select value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
              {[10, 25, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{"<<"}</button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</button>
            <button onClick={() => nextPage()} disabled={!canNextPage}>Next</button>
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{">>"}</button>
          </div>
        )}
      </TableContainer>
      {selectedLoanId && <LoanDetails loanId={selectedLoanId} onClose={() => setSelectedLoanId(null)} />}
	  
    </>
  );
};

export default ReappUpload;
