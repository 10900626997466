export const HOME_ROUTE = "/"
export const LOGIN_ROUTE = "/login"
export const SIGNUP_ROUTE = "/signup"
export const ABOUT_ROUTE = "/about"
export const CONTACT_ROUTE = "/contact"
export const FAQ_ROUTE = "/faqs"
export const GET_LOAN_ROUTE = "/get-loan"
export const BLOG_ROUTE = "/blog"
export const FORGOT_PASSWORD_ROUTE = "/forgot-password"
export const RESET_PASSWORD_ROUTE = "/reset-password"
export const DASHBOARD_ROUTE = "/"
export const DASHBOARD_ROUTE_SUPERUSER = "/superuser"
export const DASHBOARD_PROFILEROUTE = "/profile"
export const DASHBOARD_PAYMENT_DETAILS_ROUTE = "/payment-details"
export const DASHBOARD_LOAN_ROUTE = "/loan"
export const DASHBOARD_LOAN_DETAILS = "/loan/loan-details"
export const DASHBOARD_MEMO_ROUTE = "/memo"
export const DASHBOARD_DRAFTS = "/drafts"
export const DASHBOARD_MEMO_DETAILS_ROUTE = "/memo/memo-details"
export const DASHBOARD_STAFF_ROUTE = "/staff"
export const DASHBOARD_STAFF_DETAILS_ROUTE = "/staff/staff-details"
export const DASHBOARD_ENQUIRY_ROUTE = "/enquiry"
export const DASHBOARD_ENQUIRY_DETAILS_ROUTE = "/enquiry/enquiry-details"
export const DASHBOARD_SETTINGS_ROUTE = "/settings"
export const DASHBOARD_BLOG_ROUTE = "/blog"
export const DASHBOARD_NEWSLETTER_ROUTE = "/newsletter"
export const DASHBOARD_ADD_BLOG_ROUTE = "/blog/create-post"
export const DASHBOARD_EDIT_BLOG_ROUTE = "/blog/edit-post"
export const DASHBOARD_RE_APPLICANT_ROUTE = "/re-applicant"
export const DASHBOARD_APPLY_ROUTE = "/dashboard/apply"
export const DASHBOARD_APPLY_UNDERGRAD_ROUTE = "/dashboard/apply/undergraduate"
export const DASHBOARD_APPLY_POSTGRAD_ROUTE = "/dashboard/apply/postgraduate"
export const DASHBOARD_APPLY_PROFESSIONAL_ROUTE = "/dashboard/apply/professional"
export const DASHBOARD_LOGOUT_ROUTE = "/logout"
