const certData = [
	{
		id: "hdtysghj",
		label: "National Diploma",
		value: "National Diploma"
	},
	{
		id: "djjhey",
		label: "Higher National Diploma",
		value: "Higher National Diploma"
	},
	{
		id: "weettys",
		label: "Degree",
		value: "Degree"
	},
	{
		id: "trasascd",
		label: "Others",
		value: "Others"
	}
]

export default certData

export const levelData = [
	{
		id: "dfggftrethjgdfg",
		label: "100 Level",
		value: "100 Level"
	},
	{
		id: "djjhfghjkrettehghjey",
		label: "200 Level",
		value: "200 Level"
	},
	{
		id: "rjyiki",
		label: "300 Level",
		value: "300 Level"
	},
	{
		id: "fdgjhdfhgjhjgh",
		label: "400 Level",
		value: "400 Level"
	},
	{
		id: "fdgjhdfhdwehbjkmslalssdgjhjgh",
		label: "500 Level",
		value: "500 Level"
	},
	{
		id: "fdgjhdfhdsfdsdgjhjgh",
		label: "600 Level",
		value: "600 Level"
	},
	{
		id: "fdgjhdfhdsfdsdrejghdkklgjhjgh",
		label: "700 Level",
		value: "700 Level"
	}
]
export const levelDataND = [
	{
		id: "dfggfhbhsbhsdbhtrethjgdfg",
		label: "ND1",
		value: "ND1"
	},
	{
		id: "djjhfghjkrekjdkhkfjghddgyfdsftsftsttehghjey",
		label: "ND2",
		value: "ND2"
	}
]
