import { Link } from "react-router-dom"
import styled, { createGlobalStyle } from "styled-components/macro"
import { MemoArrow } from "utils/assets"
export const GlobalStyle = createGlobalStyle`

* {
  border: 0;
  padding: 0;
  margin: 0;
  outline: 0;
  /* list-style: none; */
  box-sizing: border-box;
  text-decoration: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Tahoma', 'Montserrat', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}
.Collapsible {
	background-color: #0C0C33 !important ;
	
}
.Collapsible__contentInner {
	border: 1px solid #ebebeb;
	border-top: 0;
	border-radius: 5px;
}
.Collapsible__contentInner p {
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 20px;
	background: #07047F;
}
.Collapsible__contentInner p:last-child {
	/* margin-bottom: 0; */
}
.Collapsible__trigger {
	display: block !important;
	font-weight: 400;
	text-decoration: none;
	
	position: relative;
	/* margin-bottom:4px; */
	padding: 20px 10px;
	background: #07047F;
	border-radius: 5px;
	color: #000000;
	cursor: pointer;
	
}
.Collapsible__trigger:after {
    /* font-family: "FontAwesome"; */
	content:url(${MemoArrow});
	position: absolute;
	right: 30px;
	top: 21.5px;
	display: block;
	transition: transform 300ms;
	z-index:2000;
}
.Collapsible__trigger.is-open:after {
	transform: rotateZ(90deg);
}
.Collapsible__trigger.is-disabled {
	opacity: 0.5;
	background-color: grey;
}
.CustomTriggerCSS {
	background-color: lightcoral;
	transition: background-color 200ms ease;
	border-radius: 5px;
}
.CustomTriggerCSS--open {
	background-color: darkslateblue;
}
.Collapsible__custom-sibling {
	padding: 5px;
	font-size: 12px;
	background-color: #cbb700;
	color: black;
	border-radius: 5px;
}
[class^="number-slide"],
[class*=" number-slide"] {
	background: grey;
	display: flex;
	align-items: center;
	color: #fff;
	height: 300px;
	max-height: 100vh;
}
h1, h2, h3, h4, h5 {

	font-family: 'Tahoma';
  	font-style: normal;
}

p{
	font-family: "Montserrat";
		font-style: normal;
}

`
export const Img = styled.img`
	width: ${({ w }) => (w ? w : null)};
	height: ${({ h }) => (h ? h : null)};
	margin: ${({ m }) => (m ? m : null)};
	margin-left: ${({ ml }) => (ml ? ml : null)};
	padding: ${({ p }) => (p ? p : null)};
	border-radius: ${({ br }) => (br ? br : null)};
	align-self: ${({ align }) => (align ? align : null)};
`
export const MainContainer = styled.main`
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	min-height: calc(100vh - 75px);
	margin-top: 75px;
`

export const ByshelbP = styled.p`
	font-weight: ${({ fw }) => (fw ? fw : null)};
	color: ${({ color }) => (color ? color : null)};
	background: ${({ bg }) => (bg ? bg : null)};
	display: ${({ display }) => display};
	margin: ${({ m }) => (m ? m : null)};
	margin-left: ${({ ml }) => (ml ? ml : null)};
	margin-right: ${({ mr }) => (mr ? mr : null)};
	margin-top: ${({ mt }) => (mt ? mt : null)};
	margin-bottom: ${({ mb }) => (mb ? mb : null)};
	text-transform: ${({ tr }) => (tr ? tr : null)};
	line-height: ${({ lh }) => (lh ? lh : null)};
	font-size: ${({ fs }) => (fs ? fs : null)};
	padding: ${({ p }) => (p ? p : null)};
	text-align: ${({ tAlign }) => (tAlign ? tAlign : null)};
	text-transform: ${({ tf }) => (tf ? tf : null)};
	cursor: ${({ cursor }) => (cursor ? cursor : null)};
	opacity: ${({ op }) => (op ? op : null)};
`

export const AppLink = styled(Link)`
	text-decoration: none;
	cursor: pointer;
	color: ${({ color }) => (color ? color : "")};
`
export const Div = styled.div`
	width: ${({ width }) => (width ? width : null)};
	height: ${({ height }) => (height ? height : null)};
	margin: ${({ margin }) => (margin ? margin : null)};
	padding: ${({ padding }) => (padding ? padding : null)};
	display: ${({ display }) => display};
	align-items: ${({ alignI }) => alignI};
	flex: ${({ fg }) => (fg ? fg : null)};
	align-self: ${({ align }) => (align ? align : null)};
	margin: ${({ margin }) => (margin ? margin : null)};
	margin-top: ${({ mt }) => (mt ? mt : null)};
	margin-left: ${({ ml }) => (ml ? ml : null)};
	margin-right: ${({ ml }) => (ml ? ml : null)};
	margin-bottom: ${({ mb }) => (mb ? mb : null)};
	display: ${({ display }) => display || null};
	flex-direction: ${({ fd }) => fd || null};
	flex-wrap: ${({ wrap }) => wrap || null};
	justify-content: ${({ justify }) => (justify ? justify : null)};
	background-color: ${({ bc }) => (bc ? bc : null)};
	background-image: ${({ image }) => (image ? `url(${image})` : null)};
	box-shadow: ${({ boxShadow }) => (boxShadow ? boxShadow : null)};
	box-sizing: ${({ boxSizing }) => (boxSizing ? boxSizing : null)};
	background-size: cover;
	cursor: ${({ cursor }) => (cursor ? cursor : null)};
	background-repeat: no-repeat;
	border: ${({ border }) => (border ? border : null)};
	border-radius: ${({ br }) => (br ? br : null)};
	top: ${({ top }) => (top ? top : null)};
	color: ${({ color }) => (color ? color : null)};
	position: ${({ position }) => (position ? position : "")};
`
