import React, { useState } from "react"
import { useQuery } from "react-query"
import { Link, useParams } from "react-router-dom"
import { DASHBOARD_MEMO_DETAILS_ROUTE, DASHBOARD_MEMO_ROUTE } from "routes"
import Collapsible from "react-collapsible"
import { getSingleMemoSent } from "services/MemoService"
import { MemoContainer, MemoDetailsDiv, MsgBody, MsgBtn, MsgDiv } from "./style"
import LoanAppTable from "pages/Memo/LoanTable"
import { safeJSONParse } from "utils"
import FullScreenLoader from "../FullScreenLoader"
import { BASE_URL } from "services"
import http from "services/httpService"

const MemoDetailsSingle = () => {
	const [toggleMsg, setToggleMsg] = useState(false)
	const params = useParams()

	const toggleHandler = () => {
		setToggleMsg(!toggleMsg)
	}
	const url = `${BASE_URL}/admin/who_i_am`
	const fetcher = async () => {
		const res = await http.get(url)
		return res?.data?.data
	}

	const { data: userProfile } = useQuery("profile", fetcher)
	const { data: singleMemo, isLoading } = useQuery(["view-memo-single", params?.id], () => getSingleMemoSent(params.id), { keepPreviousData: true })
	const memoLoans = singleMemo?.data?.applications ? safeJSONParse(singleMemo?.data.applications) : []
	return (
		<>
			{isLoading && <FullScreenLoader show={true} />}
			{!isLoading && (
				<MemoDetailsDiv>
					<MsgDiv>
						<h4>
							{params?.threadId ? "MEMO WITH THREAD" : "MEMO"} &nbsp;&nbsp; &rarr; &nbsp;&nbsp;
							{singleMemo?.data?.admin?.admin_role === "es"
								? "Executive Secretary"
								: singleMemo?.data?.admin?.admin_role === "lo"
								? "Loan Officer"
								: singleMemo?.data?.admin?.admin_role === "lph"
								? "Loan Processing Head"
								: singleMemo?.data?.admin?.admin_role === "la"
								? "Auditor"
								: singleMemo?.data?.admin?.admin_role === "fdo"
								? "Front Desk Officer"
								: singleMemo?.data?.admin?.admin_role === "blog"
								? "Blogger"
								: singleMemo?.data?.admin?.admin_role === "acc"
								? "Accountant"
								: "NIL"}
						</h4>
						<MsgBtn onClick={() => toggleHandler()}>Open</MsgBtn>
					</MsgDiv>
					<Link to={DASHBOARD_MEMO_ROUTE}>Back</Link>
					<br />
					{toggleMsg && (
						<MsgBody>
							<Collapsible
								trigger={
									<MemoContainer>
										<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M9 6H15C15.2833 6 15.5207 5.90433 15.712 5.713C15.904 5.521 16 5.28333 16 5C16 4.71667 15.904 4.479 15.712 4.287C15.5207 4.09567 15.2833 4 15 4H9C8.71667 4 8.47933 4.09567 8.288 4.287C8.096 4.479 8 4.71667 8 5C8 5.28333 8.096 5.521 8.288 5.713C8.47933 5.90433 8.71667 6 9 6ZM9 9H15C15.2833 9 15.5207 8.904 15.712 8.712C15.904 8.52067 16 8.28333 16 8C16 7.71667 15.904 7.479 15.712 7.287C15.5207 7.09567 15.2833 7 15 7H9C8.71667 7 8.47933 7.09567 8.288 7.287C8.096 7.479 8 7.71667 8 8C8 8.28333 8.096 8.52067 8.288 8.712C8.47933 8.904 8.71667 9 9 9ZM9 12H12C12.2833 12 12.521 11.904 12.713 11.712C12.9043 11.5207 13 11.2833 13 11C13 10.7167 12.9043 10.479 12.713 10.287C12.521 10.0957 12.2833 10 12 10H9C8.71667 10 8.47933 10.0957 8.288 10.287C8.096 10.479 8 10.7167 8 11C8 11.2833 8.096 11.5207 8.288 11.712C8.47933 11.904 8.71667 12 9 12ZM5 6C5.28333 6 5.521 5.90433 5.713 5.713C5.90433 5.521 6 5.28333 6 5C6 4.71667 5.90433 4.479 5.713 4.287C5.521 4.09567 5.28333 4 5 4C4.71667 4 4.479 4.09567 4.287 4.287C4.09567 4.479 4 4.71667 4 5C4 5.28333 4.09567 5.521 4.287 5.713C4.479 5.90433 4.71667 6 5 6ZM5 9C5.28333 9 5.521 8.904 5.713 8.712C5.90433 8.52067 6 8.28333 6 8C6 7.71667 5.90433 7.479 5.713 7.287C5.521 7.09567 5.28333 7 5 7C4.71667 7 4.479 7.09567 4.287 7.287C4.09567 7.479 4 7.71667 4 8C4 8.28333 4.09567 8.52067 4.287 8.712C4.479 8.904 4.71667 9 5 9ZM5 12C5.28333 12 5.521 11.904 5.713 11.712C5.90433 11.5207 6 11.2833 6 11C6 10.7167 5.90433 10.479 5.713 10.287C5.521 10.0957 5.28333 10 5 10C4.71667 10 4.479 10.0957 4.287 10.287C4.09567 10.479 4 10.7167 4 11C4 11.2833 4.09567 11.5207 4.287 11.712C4.479 11.904 4.71667 12 5 12ZM0 17.575V2C0 1.45 0.196 0.979 0.588 0.587C0.979333 0.195667 1.45 0 2 0H18C18.55 0 19.021 0.195667 19.413 0.587C19.8043 0.979 20 1.45 20 2V14C20 14.55 19.8043 15.021 19.413 15.413C19.021 15.8043 18.55 16 18 16H4L1.7 18.3C1.38333 18.6167 1.02067 18.6873 0.612 18.512C0.204 18.3373 0 18.025 0 17.575ZM2 15.175L3.175 14H18V2H2V15.175Z"
												fill="white"
											/>
										</svg>
										&nbsp;&nbsp;
										{singleMemo?.data?.status === "approved" ? (
											<p>
												{" "}
												Recommended for approval &nbsp;&nbsp; &rarr; &nbsp;&nbsp;
												{singleMemo?.data?.admin?.admin_role === "es"
													? "Executive Secretary"
													: singleMemo?.data?.admin?.admin_role === "lo"
													? "Loan Officer"
													: singleMemo?.data?.admin?.admin_role === "lph"
													? "Loan Processing Head"
													: singleMemo?.data?.admin?.admin_role === "la"
													? "Auditor"
													: singleMemo?.data?.admin?.admin_role === "fdo"
													? "Front Desk Officer"
													: singleMemo?.data?.admin?.admin_role === "blog"
													? "Blogger"
													: singleMemo?.data?.admin?.admin_role === "acc"
													? "Accountant"
													: "NIL"}
												&nbsp;&nbsp; &rarr; &nbsp;&nbsp;
												{singleMemo?.data?.to_admin?.admin_role === "es"
													? "Executive Secretary"
													: singleMemo?.data?.to_admin?.admin_role === "lo"
													? "Loan Officer"
													: singleMemo?.data?.to_admin?.admin_role === "lph"
													? "Loan Processing Head"
													: singleMemo?.data?.to_admin?.admin_role === "la"
													? "Auditor"
													: singleMemo?.data?.to_admin?.admin_role === "fdo"
													? "Front Desk Officer"
													: singleMemo?.data?.to_admin?.admin_role === "blog"
													? "Blogger"
													: singleMemo?.data?.to_admin?.admin_role === "acc"
													? "Accountant"
													: "NIL"}
											</p>
										) : (
											""
										)}
									</MemoContainer>
								}
							>
								<div>{singleMemo?.data?.note}</div>
							</Collapsible>
							<div>
								{singleMemo?.data?.others && singleMemo?.data?.others.length > 0 && <h3>RESPONSES FROM MEMO THREAD</h3>}
								<div>
									{singleMemo?.data?.others?.map(thr => (
										<div key={thr?._id}>
											<Collapsible
												trigger={
													<MemoContainer>
														<svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path
																d="M9 6H15C15.2833 6 15.5207 5.90433 15.712 5.713C15.904 5.521 16 5.28333 16 5C16 4.71667 15.904 4.479 15.712 4.287C15.5207 4.09567 15.2833 4 15 4H9C8.71667 4 8.47933 4.09567 8.288 4.287C8.096 4.479 8 4.71667 8 5C8 5.28333 8.096 5.521 8.288 5.713C8.47933 5.90433 8.71667 6 9 6ZM9 9H15C15.2833 9 15.5207 8.904 15.712 8.712C15.904 8.52067 16 8.28333 16 8C16 7.71667 15.904 7.479 15.712 7.287C15.5207 7.09567 15.2833 7 15 7H9C8.71667 7 8.47933 7.09567 8.288 7.287C8.096 7.479 8 7.71667 8 8C8 8.28333 8.096 8.52067 8.288 8.712C8.47933 8.904 8.71667 9 9 9ZM9 12H12C12.2833 12 12.521 11.904 12.713 11.712C12.9043 11.5207 13 11.2833 13 11C13 10.7167 12.9043 10.479 12.713 10.287C12.521 10.0957 12.2833 10 12 10H9C8.71667 10 8.47933 10.0957 8.288 10.287C8.096 10.479 8 10.7167 8 11C8 11.2833 8.096 11.5207 8.288 11.712C8.47933 11.904 8.71667 12 9 12ZM5 6C5.28333 6 5.521 5.90433 5.713 5.713C5.90433 5.521 6 5.28333 6 5C6 4.71667 5.90433 4.479 5.713 4.287C5.521 4.09567 5.28333 4 5 4C4.71667 4 4.479 4.09567 4.287 4.287C4.09567 4.479 4 4.71667 4 5C4 5.28333 4.09567 5.521 4.287 5.713C4.479 5.90433 4.71667 6 5 6ZM5 9C5.28333 9 5.521 8.904 5.713 8.712C5.90433 8.52067 6 8.28333 6 8C6 7.71667 5.90433 7.479 5.713 7.287C5.521 7.09567 5.28333 7 5 7C4.71667 7 4.479 7.09567 4.287 7.287C4.09567 7.479 4 7.71667 4 8C4 8.28333 4.09567 8.52067 4.287 8.712C4.479 8.904 4.71667 9 5 9ZM5 12C5.28333 12 5.521 11.904 5.713 11.712C5.90433 11.5207 6 11.2833 6 11C6 10.7167 5.90433 10.479 5.713 10.287C5.521 10.0957 5.28333 10 5 10C4.71667 10 4.479 10.0957 4.287 10.287C4.09567 10.479 4 10.7167 4 11C4 11.2833 4.09567 11.5207 4.287 11.712C4.479 11.904 4.71667 12 5 12ZM0 17.575V2C0 1.45 0.196 0.979 0.588 0.587C0.979333 0.195667 1.45 0 2 0H18C18.55 0 19.021 0.195667 19.413 0.587C19.8043 0.979 20 1.45 20 2V14C20 14.55 19.8043 15.021 19.413 15.413C19.021 15.8043 18.55 16 18 16H4L1.7 18.3C1.38333 18.6167 1.02067 18.6873 0.612 18.512C0.204 18.3373 0 18.025 0 17.575ZM2 15.175L3.175 14H18V2H2V15.175Z"
																fill="white"
															/>
														</svg>
														&nbsp;&nbsp;
														{singleMemo?.data?.status === "approved" ? (
															<p>
																{" "}
																Recommended for approval &nbsp;&nbsp; &rarr; &nbsp;&nbsp;
																{thr?.admin?.admin_role === "es"
																	? "Executive Secretary"
																	: thr?.admin?.admin_role === "lo"
																	? "Loan Officer"
																	: thr?.admin?.admin_role === "lph"
																	? "Loan Processing Head"
																	: thr?.admin?.admin_role === "la"
																	? "Auditor"
																	: thr?.admin?.admin_role === "fdo"
																	? "Front Desk Officer"
																	: thr?.admin?.admin_role === "blog"
																	? "Blogger"
																	: thr?.admin?.admin_role === "acc"
																	? "Accountant"
																	: "NIL"}
																&nbsp;&nbsp; &rarr; &nbsp;&nbsp;
																{thr?.to_admin?.admin_role === "es"
																	? "Executive Secretary"
																	: thr?.to_admin?.admin_role === "lo"
																	? "Loan Officer"
																	: thr?.to_admin?.admin_role === "lph"
																	? "Loan Processing Head"
																	: thr?.to_admin?.admin_role === "la"
																	? "Auditor"
																	: thr?.to_admin?.admin_role === "fdo"
																	? "Front Desk Officer"
																	: thr?.to_admin?.admin_role === "blog"
																	? "Blogger"
																	: thr?.to_admin?.admin_role === "acc"
																	? "Accountant"
																	: "NIL"}
															</p>
														) : (
															""
														)}
													</MemoContainer>
												}
											>
												<div>{singleMemo?.data?.note}</div>
											</Collapsible>
										</div>
									))}
								</div>

								<LoanAppTable thread={params?.threadId} title="LOAN APPLICATIONS SENT VIA MEMO" tableData={memoLoans} role={userProfile?.admin_role} />
								{/* <button onClick={() => toggleHandler()}>Close</button> */}
							</div>
						</MsgBody>
					)}
				</MemoDetailsDiv>
			)}
		</>
	)
}

export default MemoDetailsSingle
