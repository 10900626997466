import CreateAccComp from 'components/Auth/Login/CreateAccount/CreateAccComp'
import React from 'react'

function CreateAccount() {

    

  return (
    <div>
        <CreateAccComp />
    </div>
  )
}

export default CreateAccount