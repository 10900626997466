// import { SlideInPopUp } from "components/MobileMenu/style"
import styled from "styled-components"
import Colors from "utils/colors"
import media from "utils/media"

export const AuthOverlay = styled.div`
	display: flex;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	position: fixed;
	z-index: 700;
	background: ${Colors.whiteGradient};
	display: ${({ open }) => (open ? "flex" : "none")};
	transition: all 0.5s ease-out;

	animation-duration: 0.5s;
	${media.tablet`
    display:flex;
    `}
	${media.mobile`
    display:flex;
    
    `}
`
export const BigMenuDiv = styled.div`
	width: 230px;
	height: 100%;
	background: ${Colors.primaryLight};
	display: flex;
	flex-direction: column;
	align-items: center;
	${media.mobile`
	width: 70%;    
    `}
`

export const SideImageDiv = styled.div`
	width: 100px;
	height: 100px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 3rem;
	img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}
`

export const LogoutDiv = styled.div`
	width: 100%;
	padding-left: 20px;
	margin-top: 10%;
	${media.mobile`
	margin-top: 30%;
	`}
`
export const UserDpName = styled.p`
	color: ${Colors.white};
	margin-top: 10px;
	text-transform: capitalize;
`
export const Main = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	overflow-y: hidden;
	/* background: red; */
`

export const DashboardContainer = styled.div`
	width: 100%;
	/* height: (100% - 75px); */
	display: flex;
	/* background: #32e3; */
	overflow-y: hidden;
	/* z-index: 900; */
`
